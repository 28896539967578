import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserRole, WeldManagerUser } from '../models';
import { BaseApiService } from '../shared/base-api.service';

@Injectable({
  providedIn: 'root'
})
export class WeldManagerUserApiService extends BaseApiService<any> {
  //#region 'Angular Life Cycle'
  constructor(protected _http: HttpClient) {
    super(_http, 'weldmanageruser');
  }
  //#endregion

  //#region 'Get'
  public GetUserAsync(): Observable<WeldManagerUser[]> {
    return this.getAll('/GetUserAsync');
  }

  public GetUserAsyncByUserId(userId: string): Observable<WeldManagerUser> {
    const options = { userId };
    return this.getById(options, '/GetUserAsync');
  }

  public GetActiveUsersByRefineryAsync(refineryId: number): Observable<WeldManagerUser[]> {
    const options = { refineryId };
    return this.getAllById(options, '/GetUsersByRefineryAsync');
  }

  public GetAllUsersByRefineryAsync(refineryId: number, active: boolean): Observable<WeldManagerUser[]> {
    const options = { refineryId, active };
    return this.getAllById(options, '/GetUsersByRefineryAsync');
  }

  public GetUserRolesByUserAsync(userId: string): Observable<UserRole> {
    const options = { userId };
    return this.getById(options, '/GetUserRolesByUserAsync');
  }

  public GetActiveUserRolesByRefineryAsync(refineryId: number): Observable<UserRole[]> {
    const options = { refineryId };
    return this.getById(options, '/GetUserRolesByRefineryAsync');
  }

  public GetAllUserRolesByRefineryAsync(refineryId: number, active: boolean): Observable<UserRole[]> {
    const options = { refineryId, active };
    return this.getById(options, '/GetUserRolesByRefineryAsync');
  }

  public GetUser(userId: string): Observable<any> {
    return this._http.get<any>(`${this._apiEndpoint}/GetUserAsync`, { params: { userId } });
  }

  public GetAvailableUserRolesByUserAsync(userId: string, refineryId: number): Observable<UserRole[]> {
    return this._http.get<UserRole[]>(`${this._apiEndpoint}/GetAvailableUserRolesByUserAsync`, {
      params: { userId, refineryId }
    });
  }

  public GetAllUsers(): Observable<any[]> {
    return this._http.get<any[]>(`${this._apiEndpoint}/GetUserAsync`);
  }
  //#endregion

  //#region 'Create'
  public AddUserAsync(dto: any): Observable<boolean> {
    return this.createAny(dto, '/AddUserAsync');
  }
  //#endregion

  //#region 'Update'
  public UpdateUserAsync(dto: any): Observable<boolean> {
    return this.createAny(dto, '/UpdateUserAsync');
  }
  //#endregion

  //#region 'Delete'
  //#endregion

  //#region 'Other'
  //#endregion
}
