<!-- #region Contract Welder photo upload -->
<div class="container">
  <!-- #region 'File Input' -->
  <div class="row" style="margin-top: 5%">
    <div class="text-left col-md-12">
      <input
        #myFileInput
        id="myFileInput"
        type="file"
        accept="image/png, image/jpeg"
        (change)="fileChangeEvent($event)"
      />
      <p-button
        *ngIf="this.croppedImage && this.croppedImage !== ''"
        class="red-btn ml-2"
        icon="pi pi-trash"
        pTooltip="Remove Image"
        tooltipPosition="top"
        (onClick)="removeUploadImage()"
      ></p-button>
    </div>
  </div>
  <!-- #endregion 'File Input' -->

  <!-- #region 'Image Cropper' -->
  <div class="row" style="margin-top: 5%">
    <div class="text-left col-md-8">
      <h5>Crop Image</h5>
      <image-cropper
        [imageChangedEvent]="imageChangedEvent"
        [maintainAspectRatio]="true"
        [aspectRatio]="1 / 1"
        [resizeToWidth]="600"
        [resizeToHeight]="600"
        [onlyScaleDown]="true"
        output="base64"
        format="jpeg"
        (imageCropped)="imageCropped($event)"
      ></image-cropper>
    </div>
  </div>
  <!-- #endregion 'Image Cropper' -->

  <!-- #region 'Preview Cropped Image' -->
  <div class="row" style="margin-top: 5%">
    <div class="text-left col-md-8">
      <h5>Preview</h5>
      <img [src]="croppedImage" />
    </div>
  </div>
  <!-- #endregion 'Preview Cropped Image' -->

  <!-- #region Action Buttons -->
  <div class="row">
    <div class="alignRight">
      <!-- #region Cancel -->
      <p-button (onClick)="cancel()" class="mr-2 mt-4 red-btn action-btn"
        ><em class="pi pi-times-circle mr-2"></em> Cancel
      </p-button>
      <!-- #endregion Cancel -->

      <!-- #region Save -->
      <p-button class="mr-2 mt-4 action-btn" type="submit" [disabled]="!myFileInput.value" (onClick)="savePhoto()"
        >Save Photo
      </p-button>
      {{ myFileInput.value }}
      <!-- #endregion Save-->
    </div>
  </div>
  <!-- #endregion Action Buttons-->
</div>
<!-- #endregion Contract Welder photo upload Upload -->
