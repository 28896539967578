import { Injectable } from '@angular/core';

@Injectable()
export class LoadingIndicatorService {
  //#region 'Variables'
  private counter = 0;
  //#endregion 'Variables'

  //#region 'General Methods'
  public show(): void {
    this.counter++;
  }

  public hide(): void {
    this.counter = 0;
  }

  //takes in a number of async functions that need to execute before hiding..
  public asyncShow(count: number): void {
    this.counter = count;
  }

  //use in each async func to decrease the count as each is resolved
  public asyncHide(): void {
    this.counter--;
  }

  public isVisible(): boolean {
    return this.counter > 0;
  }
  //#endregion 'General Methods'
}
