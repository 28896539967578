import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { WeldLogView } from '../models';
import { BaseApiService } from '../shared/base-api.service';

@Injectable({
  providedIn: 'root'
})
export class WeldLogViewApiService extends BaseApiService<WeldLogView> {
  //#region 'Angular Life Cycle'
  constructor(protected _http: HttpClient) {
    super(_http, 'weldlogview');
  }
  //#endregion

  //#region 'Get'
  public GetWeldLogViewsAsync(): Observable<WeldLogView[]> {
    return this.getAll('/GetWeldLogViewsAsync');
  }

  public GetWeldLogViewsByUserIdAsync(userid: string): Observable<WeldLogView> {
    const options = { userid };
    return this.getById(options, '/GetWeldLogViewsAsync');
  }
  //#endregion

  //#region 'Create'
  //#endregion

  //#region 'Update'
  public WeldLogUpdate(dto: WeldLogView): Observable<WeldLogView> {
    return this.create(dto, '/UpdateWeldLogViewsAsync');
  }
  //#endregion

  //#region 'Delete'
  //#endregion

  //#region 'Other'
  //#endregion
}
