import { Injectable } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Injectable()
export class KeypressValidationService {

  public numeric(event: any) {
    const INP = String.fromCharCode(event.keyCode);
    if (/[0-9]/.test(INP)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  public alphanumeric(event: any) {
    const INP = String.fromCharCode(event.keyCode);
    if (/[a-zA-Z0-9]/.test(INP)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  public numericCommaSeparated(event: any) {
    const INP = String.fromCharCode(event.keyCode);
    if (/[0-9,]/.test(INP)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  public alphanumericCommaSeparated(event: any) {
    const INP = String.fromCharCode(event.keyCode);
    if (/[a-zA-Z0-9,]/.test(INP)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  public alphanumericCommaSpaceHyphen(event: any) {
    const INP = String.fromCharCode(event.keyCode);
    if (/[a-zA-Z0-9, -]/.test(INP)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  public alphanumericHyphen(event: any) {
    const INP = String.fromCharCode(event.keyCode);
    if (/[a-zA-Z0-9-/]/.test(INP)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  public numericDecimal(event: any) {
    const INP = String.fromCharCode(event.keyCode);
    const value = event.target.value;
    if (/[0-9.]/.test(INP)) {
      if (value.indexOf('.') >= 0 && INP === '.') {
        event.preventDefault();
        return false;
      } else {
        return true;
      }
    } else {
      event.preventDefault();
      return false;
    }
  }

  public alphaUpperCaseNoSpace(event: any) {
    const INP = String.fromCharCode(event.keyCode);
    if (/^[a-zA-Z0-9]+$/.test(INP)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  //#region 'FormBuilder Control Validators'
  public numericOnlyValidator(control: UntypedFormControl) {
    const numberRegEx = /^\d+$/;
    //validates input is numeric only.. only checks if value is entered
    if (control.value && !numberRegEx.test(control.value)) {
      return { numericOnlyValidator: true };
    } else {
      return null;
    }
  }
  //#endregion 'FormBuilder Control Validators'
}
