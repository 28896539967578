import { Base } from './Base.model';

export class Size extends Base {
  constructor() {
    super();
  }

  public sizeId: number;
  public sizeName: string;
}
