import { Injectable } from '@angular/core';
import { ContractorIdentificationApiService } from 'src/app/core/api';
import { ContractorIdentification } from 'src/app/core/models/ContractorIdentification.model';
import { UserInfoService } from './user-info.service';

@Injectable({
  providedIn: 'root'
})
export class ContractorIdentificationService {
  constructor(
    private _srvContractorIdentification: ContractorIdentificationApiService,
    private _srvUserInfo: UserInfoService
  ) {}

  public GetContractorIdentificationById(welderId: string): Promise<ContractorIdentification> {
    return new Promise<ContractorIdentification>((resolve, reject) => {
      try {
        this._srvContractorIdentification.GetContractorIdentificationById(welderId).subscribe(res => {
          resolve(res);
        });
      } catch (err) {
        reject(Error(err));
      }
    });
  }
  public GetContractorIdentificationByRefineryAsync(
    refineryId: number,
    active?: boolean
  ): Promise<ContractorIdentification[]> {
    return new Promise<ContractorIdentification[]>((resolve, reject) => {
      try {
        // Common function to process results
        const processResults = (res: ContractorIdentification[]) => {
          res.sort((a, b) => (a.firstName.toLocaleLowerCase() < b.firstName.toLocaleLowerCase() ? -1 : 1));
          res.forEach(contractorIdentification => {
            contractorIdentification[
              'fullName'
            ] = `${contractorIdentification.lastName}, ${contractorIdentification.firstName}`;
            contractorIdentification['status'] = contractorIdentification.active ? 'Active' : 'Inactive';
          });
          resolve(res);
        };

        // Choose the service call based on 'active' parameter
        const serviceCall = active
          ? this._srvContractorIdentification.GetActiveContractorIdentificationByRefineryAsync(refineryId)
          : this._srvContractorIdentification.GetAllContractorIdentificationByRefineryAsync(refineryId, active);

        // Subscribe to the service call and process results
        serviceCall.subscribe(processResults);
      } catch (err) {
        reject(Error(err));
      }
    });
  }

  public UpdateContractorPhotoByIdAsync(dto: any): Promise<string> {
    dto.updatedBy = this._srvUserInfo.userName;
    dto.updatedDateTime = new Date().toJSON();
    return new Promise<string>((resolve, reject) => {
      try {
        this._srvContractorIdentification.UpdateContractorPhotoByIdAsync(dto).subscribe(res => {
          resolve(res.toString());
        });
      } catch (err) {
        reject(Error(err));
      }
    });
  }
}
