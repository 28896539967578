import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'app-contract-welder-photo',
  templateUrl: './contract-welder-photo.component.html',
  styleUrls: ['./contract-welder-photo.component.scss']
})
export class ContractWelderPhotoComponent {
  @Input() public displayPhotoDialog: boolean;
  @Input() public photoString: string;
  @Output() public onSavePhoto: EventEmitter<string> = new EventEmitter<string>();
  @Output() public onCancel: EventEmitter<any> = new EventEmitter<any>();
  @Output() public onClose: EventEmitter<any> = new EventEmitter<any>();

  public imageChangedEvent: any = '';
  public croppedImage: any = '';

  // Manage Contract Welder Photo

  public savePhoto(): void {
    this.onSavePhoto.emit(this.photoString);
  }

  public cancel(): void {
    this.onCancel.emit();
  }

  public fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  public imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.photoString = event.base64.toString().split('base64,')[1];
  }

  public removeUploadImage() {
    this.croppedImage = '';
    this.imageChangedEvent = '';
    this.photoString = '';
    this.clearFileInput();
  }

  public clearFileInput() {
    if (document.getElementById('myFileInput') && !this.photoString) {
      (document.getElementById('myFileInput') as HTMLInputElement).value = null;
    }
  }
  // #endregion 'Contract Welder Photo'
}
