import { NgModule } from '@angular/core';
import { AuthService } from '../core/auth/auth.service-api';
import {
  DropdownService,
  ExportToExcelService,
  KeypressValidationService,
  LoadingIndicatorService,
  UserInfoService,
  WeldCertificationService,
  WeldManagerUserService
} from './services';
@NgModule({
  declarations: [],
  imports: [],
  providers: [
    AuthService,
    DropdownService,
    ExportToExcelService,
    KeypressValidationService,
    LoadingIndicatorService,
    UserInfoService,
    WeldCertificationService,
    WeldManagerUserService
  ],
  bootstrap: [],
  exports: []
})
export class DataModule {}
