import { Injectable } from '@angular/core';
import { QaTypeApiService } from 'src/app/core/api';
import { QAType } from 'src/app/core/models';

@Injectable({
  providedIn: 'root'
})
export class QaTypeService {

  constructor(private _srvQAType: QaTypeApiService) { }

  public GetQAType(): Promise<QAType[]> {
    return new Promise((resolve, reject) => {
      try {
        this._srvQAType.GetQATypeAsync().subscribe(res => {
          resolve(res);
        });
      } catch (err) {
        reject(Error(err));
      }
    });
  }
}
