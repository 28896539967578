import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { WallThickness } from '../models';
import { BaseApiService } from '../shared/base-api.service';

@Injectable({
  providedIn: 'root'
})
export class WallThicknessApiService extends BaseApiService<WallThickness> {
  //#region 'Angular Life Cycle'
  constructor(protected _http: HttpClient) {
    super(_http, 'wallThickness');
  }
  //#endregion

  //#region 'Get'
  public GetWallThicknessAsync(SizeId: number, ScheduleId: number): Observable<WallThickness> {
    const options = { SizeId, ScheduleId };
    return this.getById(options, '/GetWallThicknessAsync');
  }
  //#endregion

  //#region 'Create'
  //#endregion

  //#region 'Update'
  //#endregion

  //#region 'Delete'
  //#endregion

  //#region 'Other'
  //#endregion
}
