import { Injectable } from '@angular/core';
import moment from 'moment';
import { catchError, of } from 'rxjs';
import { RTPercentageApiService } from 'src/app/core/api';
import { RTPercentage } from 'src/app/core/models';
import { UserInfoService } from '.';

@Injectable({
  providedIn: 'root'
})
export class RTPercentageService {
  constructor(private _srvRTPercentage: RTPercentageApiService, private _srvUserInfo: UserInfoService) {}

  public GetRTPercentageAsync(): Promise<RTPercentage[]> {
    return new Promise<RTPercentage[]>((resolve, reject) => {
      try {
        this._srvRTPercentage.GetRTPercentageAsync().subscribe(res => {
          res.sort((a, b) => (a.rtpercentageId < b.rtpercentageId ? -1 : 1));
          resolve(res);
        });
      } catch (err) {
        reject(Error(err));
      }
    });
  }
  public GetRTPercentageByRefineryAsync(refineryId: number, active?: boolean): Promise<RTPercentage[]> {
    return new Promise<RTPercentage[]>((resolve, reject) => {
      try {
        // Define a common function to process the results
        const processResults = (res: RTPercentage[]) => {
          res.sort((a, b) => (a.rtPercentage < b.rtPercentage ? -1 : 1));
          res.forEach(rtpercentage => {
            rtpercentage['status'] = rtpercentage.active ? 'Active' : 'Inactive';
            rtpercentage.createdDateTime = `${moment(rtpercentage.createdDateTime).format('MMMM DD, YYYY')}`;
            rtpercentage.updatedDateTime = rtpercentage.updatedDateTime
              ? `${moment(rtpercentage.updatedDateTime).format('MMMM DD, YYYY')}`
              : null;
          });
          resolve(res);
        };

        // Use the appropriate service call based on the 'active' parameter
        const serviceCall = active
          ? this._srvRTPercentage.GetRTPercentageByRefineryAsync(refineryId)
          : this._srvRTPercentage.GetRTPercentageByRefineryAsync(refineryId, active);

        // Subscribe to the service call and process results
        serviceCall.subscribe(processResults);
      } catch (err) {
        reject(Error(err));
      }
    });
  }

  public AddorUpdateRTPercentageAsync(rtpercentage: RTPercentage, existing: boolean): Promise<string> {
    if (existing) {
      rtpercentage.updatedBy = this._srvUserInfo.userName;
      rtpercentage.updatedDateTime = new Date().toJSON();
    } else {
      rtpercentage.active = true;
      rtpercentage.refineryId = Number(this._srvUserInfo.refineryId);
      rtpercentage.createdBy = this._srvUserInfo.userName;
      rtpercentage.createdDateTime = new Date().toJSON();
    }
    return new Promise<string>((resolve, reject) => {
      try {
        const serviceMethod = existing
          ? this._srvRTPercentage.UpdateRTPercentageAsync(rtpercentage)
          : this._srvRTPercentage.AddRTPercentageAsync(rtpercentage);

        serviceMethod
          .pipe(
            catchError(err => {
              return of(err.error.Message);
            })
          )
          .subscribe(res => {
            resolve(res.toString());
          });
      } catch (err) {
        reject(Error(err));
      }
    });
  }
}
