import { TableHeaders } from 'src/app/core/models';
import { Injectable } from '@angular/core';
import { TableHeadersApiService } from 'src/app/core/api/table-headers-api.service';

@Injectable({
  providedIn: 'root'
})
export class TableHeadersService {
  constructor(private _srvTableHeaders: TableHeadersApiService) {}

  public GetTableHeadersAsync(): Promise<TableHeaders[]> {
    return new Promise((resolve, reject) => {
      try {
        this._srvTableHeaders.GetTableHeadersAsync().subscribe(res => {
          resolve(res);
        });
      } catch (err) {
        reject(Error(err));
      }
    });
  }
}
