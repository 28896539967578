import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Refinery } from '../models';
import { BaseApiService } from '../shared/base-api.service';

@Injectable({
  providedIn: 'root'
})
export class RefineryApiService extends BaseApiService<Refinery> {
  //#region 'Angular Life Cycle'
  constructor(protected _http: HttpClient) {
    super(_http, 'refinery');
  }
  //#endregion

  //#region 'Get'
  public GetRefineriesAsync(): Observable<Refinery[]> {
    return this.getAll('/GetRefineriesAsync');
  }

  public GetRefineriesAsyncById(refineryid: number): Observable<Refinery> {
    const options = { refineryid };
    return this.getById(options, '/GetRefineriesAsync');
  }
  //#endregion

  //#region 'Create'
  //#endregion

  //#region 'Update'
  public UpdateRefineryEmailSettingsAsync(refinery: Refinery): Observable<boolean> {
    return this.createAny(refinery, '/UpdateRefineryEmailSettingsAsync');
  }
  //#endregion

  //#region 'Delete'
  //#endregion

  //#region 'Other'
  //#endregion
}
