import { Base } from './Base.model';

export class Material extends Base {
  constructor() {
    super();
  }

  public materialId: number;
  public materialName: string;
}
