import { Injectable } from '@angular/core';
import { RTPercentageApiService } from 'src/app/core/api';
import { RTPercentage } from 'src/app/core/models';
import { UserInfoService } from '.';

@Injectable({
  providedIn: 'root'
})
export class RtPercentageService {

  constructor(private srvRTpercentage: RTPercentageApiService, private _srvUserInfo: UserInfoService) { }

  public GetRTPercentageByRefineryAsync(): Promise<RTPercentage[]> {
    return new Promise((resolve, reject) => {
      try {
        this.srvRTpercentage.GetRTPercentageByRefineryAsync(Number(this._srvUserInfo.refineryId)).subscribe(res => {
          resolve(res);
        });
      } catch (err) {
        reject(Error(err));
      }
    });
  }
}
