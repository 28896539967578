import { Injectable } from '@angular/core';
import moment from 'moment';
import { catchError, of } from 'rxjs';
import { FillerMaterialApiService } from 'src/app/core/api';
import { FillerMaterial } from 'src/app/core/models';
import { UserInfoService } from '.';

@Injectable({
  providedIn: 'root'
})
export class FillerMaterialService {
  constructor(private _srvFillerMat: FillerMaterialApiService, private _srvUserInfo: UserInfoService) {}
  public GetFillerMaterialsAsync(active?: boolean): Promise<FillerMaterial[]> {
    return new Promise<FillerMaterial[]>((resolve, reject) => {
      try {
        // Common function for processing the results
        const processResults = (res: FillerMaterial[]) => {
          res.sort((a, b) =>
            a.fillerMaterialName.toLocaleLowerCase() < b.fillerMaterialName.toLocaleLowerCase() ? -1 : 1
          );
          res.forEach(fillerMat => {
            fillerMat['status'] = fillerMat.active ? 'Active' : 'Inactive';
            fillerMat.createdDateTime = `${moment(fillerMat.createdDateTime).format('MMMM DD, YYYY')}`;
            fillerMat.updatedDateTime = fillerMat.updatedDateTime
              ? `${moment(fillerMat.updatedDateTime).format('MMMM DD, YYYY')}`
              : null;
          });
          resolve(res);
        };

        // Choose the appropriate service call based on the 'active' parameter
        const serviceCall = active
          ? this._srvFillerMat.GetActiveFillerMaterialsAsync()
          : this._srvFillerMat.GetAllFillerMaterialsAsync(active);

        // Subscribe to the service call and process results
        serviceCall.subscribe(processResults);
      } catch (err) {
        reject(Error(err));
      }
    });
  }

  public AddorUpdateFillerMaterialAsync(fillerMat: FillerMaterial, existing: boolean): Promise<string> {
    if (existing) {
      fillerMat.updatedBy = this._srvUserInfo.userName;
      fillerMat.updatedDateTime = new Date().toJSON();
    } else {
      fillerMat.active = true;
      fillerMat.createdBy = this._srvUserInfo.userName;
      fillerMat.createdDateTime = new Date().toJSON();
    }

    return new Promise<string>((resolve, reject) => {
      try {
        // Select the appropriate service method based on 'existing'
        const serviceMethod = existing
          ? this._srvFillerMat.UpdateFillerMaterialAsync(fillerMat)
          : this._srvFillerMat.AddFillerMaterialAsync(fillerMat);

        // Common error handling and subscription logic
        serviceMethod
          .pipe(
            catchError(err => {
              return of(err.error.Message);
            })
          )
          .subscribe(res => {
            resolve(res.toString());
          });
      } catch (err) {
        reject(Error(err));
      }
    });
  }
}
