<!-- #region Form Content -->
<form [formGroup]="logForm" id="logForm">
  <!-- #region Engineering Title -->
  <div class="row">
    <!-- #region Section 1 Title -->
    <div class="col-3 divCenter">
      <h5 class="mt-4">Engineering / QC Information</h5>
    </div>
    <!-- #endregion -->

    <div class="col-6"></div>

    <!-- #region Action Buttons -->
    <div class="col-3 divCenter" style="display: table">
      <!-- #region Cancel -->
      <p-button (onClick)="cancel()" class="red-btn mt-4 action-btn"><em class="pi pi-times-circle mr-2"></em> Cancel</p-button>
      <!-- #endregion -->

      <!-- #region Submit -->
      <p-button
        class="mt-4 mr-2 action-btn"
        (onClick)="submitNewLog(false)"
        [disabled]="formDisabled"
        [hidden]="hideActionBtns"
        *ngIf="userPermissions && (userPermissions.permissions.includes('system admin') || userPermissions.permissions.includes('update weld log'))"
        >{{ dialogSubmit }}</p-button
      >
      <!-- #endregion -->

      <!-- #region Save As Draft-->
      <p-button
        class="mt-4 mr-2 action-btn"
        (onClick)="submitNewLog(true)"
        [hidden]="hideDraftBtn || hideActionBtns"
        *ngIf="userPermissions && (userPermissions.permissions.includes('system admin') || userPermissions.permissions.includes('update weld log'))"
        >{{ draftBtn }}</p-button
      >
      <!-- #endregion -->
    </div>
    <!-- #endregion -->
  </div>
  <!-- #endregion Engineering Title -->

  <!-- #region WeldType, Weld No, Weld Modifier,Weld Repair, Weld Date, QM Tracking, Drawing  -->
  <div class="row">
    <!-- #region Record Type -->
    <div class="col-2">
      <label for="weldType">Record Type </label>
      <p-dropdown
        name="weldType"
        formControlName="weldType"
        optionValue="id"
        optionLabel="name"
        placeholder="Select a Record Type"
        [options]="_srvDropDown.weldType.items"
        (onChange)="weldTypeChange()"
      ></p-dropdown>
    </div>
    <!-- #region Weld Id -->
    <div class="col-2" [hidden]="hideForContinuity || showForMCBU">
      <label for="weldId">Weld Id <span style="color: red">*</span></label>
      <input pInputText class="formInput" type="text" name="weldId" formControlName="weldId" placeholder="Enter Weld Id" maxlength="10" />
    </div>
    <!-- #endregion -->
    <!-- #region Weld No -->
    <div class="col-2" [hidden]="hideForContinuity">
      <label for="weldNo">Weld No</label>
      <input pInputText class="formInput" name="weldNo" formControlName="weldNo" placeholder="Enter Weld No" (keypress)="keyValidation.numeric($event)" [hidden]="hideForContinuity" maxlength="10" />
    </div>
    <!-- #endregion -->
    <!-- #region Weld Modifier -->
    <div class="col-2" [hidden]="hideForContinuity">
      <label for="weldModifier">Weld Modifier</label>
      <input
        pInputText
        class="formInput"
        name="weldModifier"
        formControlName="weldModifier"
        placeholder="Enter Weld Mod"
        (keypress)="keyValidation.alphanumeric($event)"
        [ngModel]="this.logForm.value.weldModifier"
        (ngModelChange)="this.logForm.value.weldModifier ? (this.logForm.value.weldModifier = $event.toUpperCase()) : null; checkWeldModifier($event)"
        [hidden]="hideForContinuity"
        maxlength="50"
      />
    </div>
    <!-- #endregion Weld Modifier -->

    <!-- #region 'Weld Repair' -->
    <div class="col-1" [hidden]="hideForContinuity">
      <label for="weldRepair">Repair Weld</label>
      <p-toggleButton
        name="weldRepair"
        formControlName="weldRepair"
        [onLabel]="'Repair'"
        [offLabel]="'Non-Repair'"
        [style]="{ width: '100%' }"
        [(ngModel)]="this.logForm.value.weldRepair"
      ></p-toggleButton>
    </div>
    <!-- #endregion 'Weld Repair' -->

    <!-- #region Weld Date -->
    <div class="col-1 calendarDiv">
      <label for="dateWelded">Weld Date <span style="color: red">*</span></label>
      <p-calendar
        class="formCalendar"
        name="dateWelded"
        formControlName="dateWelded"
        dataType="string"
        dateFormat="yy-mm-dd"
        placeholder="Enter Date"
        appendTo="body"
        [readonlyInput]="true"
        [showButtonBar]="true"
      ></p-calendar>
    </div>
    <!-- #endregion Weld Date-->

    <!-- #region QM Tracking -->
    <div class="col-1" [hidden]="hideForContinuity">
      <label for="qmtrackingNumber">QM Tracking</label>
      <input pInputText class="formInput" name="qmtrackingNumber" formControlName="qmtrackingNumber" placeholder="Enter QM #" (keypress)="keyValidation.numeric($event)" maxlength="10" />
    </div>
    <!-- #endregion QM Tracking -->

    <!-- #region Drawing -->
    <div class="col-3" [hidden]="hideForContinuity">
      <label for="drawing">Drawing <span style="color: red" [hidden]="hideForMCBU">*</span></label>
      <input pInputText class="formInput" type="text" name="drawing" formControlName="drawing" placeholder="Enter Drawing" maxlength="50" />
    </div>
    <!-- #endregion -->

    <!-- #region Weld Type Identifier -->
    <div class="col-2" [hidden]="hideForContinuity">
      <label for="weldTypeIdentifierId">Weld Type <span style="color: red" [hidden]="showForMCBU">*</span></label>
      <p-dropdown
        name="weldTypeIdentifierId"
        formControlName="weldTypeIdentifierId"
        optionValue="id"
        optionLabel="name"
        placeholder="Select a Weld Type"
        [options]="_srvDropDown.weldTypeIdentifier.items"
        [filter]="true"
      ></p-dropdown>
    </div>
    <!-- #endregion -->
  </div>
  <!-- #endregion WeldType, Weld No, Weld Modifier,Weld Repair, Weld Date, QM Tracking, Drawing, Weld Type Identifier -->

  <!-- #region  Divison, Unit, Group, EWO, Project/PO-->
  <div class="row" [hidden]="hideForContinuity">
    <!-- #region Division -->
    <div class="col-2" [hidden]="hideForContinuity">
      <label for="divisionId">Division <span style="color: red">*</span></label>
      <p-dropdown
        name="divisionId"
        formControlName="divisionId"
        optionValue="id"
        optionLabel="name"
        placeholder="Select a Division"
        [options]="_srvDropDown.divisions.items"
        (onChange)="getUnits()"
        [filter]="true"
      ></p-dropdown>
    </div>
    <!-- #endregion -->

    <!-- #region Unit -->
    <div class="col-3" [hidden]="hideForContinuity">
      <label for="unitId">Unit <span style="color: red">*</span></label>
      <p-dropdown name="unitId" formControlName="unitId" optionValue="id" optionLabel="name" placeholder="Select a Unit" [options]="_srvDropDown.units.items" [filter]="true"></p-dropdown>
    </div>
    <!-- #endregion -->

    <!-- #region Group -->
    <div class="col-2" [hidden]="hideForContinuity">
      <label for="weldGroupId">Group <span style="color: red">*</span></label>
      <p-dropdown
        optionValue="id"
        optionLabel="name"
        name="weldGroupId"
        formControlName="weldGroupId"
        placeholder="Select a Group"
        [options]="_srvDropDown.WeldGroups.items"
        [filter]="true"
        (onChange)="getWeldNo(3)"
      ></p-dropdown>
    </div>
    <!-- #endregion Group -->

    <!-- #region EWO -->
    <div class="col-2" [hidden]="hideForContinuity">
      <label for="ewonumber">EWO <span style="color: red">*</span></label>
      <input
        pInputText
        type="text"
        class="formInput"
        name="ewonumber"
        formControlName="ewonumber"
        placeholder="Enter EWO"
        maxlength="25"
        oninput="if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
        (keypress)="keyValidation.alphanumericHyphen($event)"
        (blur)="getWeldNo(2)"
      />
    </div>
    <!-- #endregion -->

    <!-- #region Project/PO # -->
    <div class="col-3" [hidden]="hideForContinuity">
      <label for="projectOrPo">Project/PO #</label>
      <input pInputText type="text" class="formInput" name="projectOrPo" formControlName="projectOrPo" placeholder="Enter Project/PO #" maxlength="25" />
    </div>
    <!-- #endregion -->
  </div>
  <!-- #endregion Divison, Unit, Group, EWO, Project/PO-->

  <!-- #region Pipesec, Size, Schedule,WPS, RT Percentage, JointType-->
  <div class="row">
    <!-- #region Pipe Spec -->
    <div class="col-2" [hidden]="hideForContinuity">
      <label for="spec">Pipe Spec <span style="color: red">*</span></label>
      <input
        pInputText
        class="formInput"
        type="text"
        name="spec"
        formControlName="spec"
        [ngModel]="this.logForm.value.spec"
        (ngModelChange)="this.logForm.value.spec ? (this.logForm.value.spec = $event.toUpperCase()) : null"
        placeholder="Enter Spec"
        maxlength="25"
        (keypress)="keyValidation.alphanumeric($event)"
      />
    </div>
    <!-- #endregion Pipe Spec -->

    <!-- #region Size -->
    <div class="col-2" [hidden]="hideForContinuity">
      <label for="sizeId">Size</label>
      <p-dropdown
        optionValue="id"
        optionLabel="name"
        name="sizeId"
        formControlName="sizeId"
        placeholder="Size"
        [options]="_srvDropDown.size.items"
        [filter]="true"
        [showClear]="true"
        (onChange)="getWallThickness()"
      ></p-dropdown>
    </div>
    <!-- #endregion Size -->

    <!-- #region Schedule -->
    <div class="col-2" [hidden]="hideForContinuity">
      <label for="scheduleId">Schedule</label>
      <p-dropdown
        optionValue="id"
        optionLabel="name"
        name="scheduleId"
        formControlName="scheduleId"
        placeholder="Select Schedule"
        [options]="_srvDropDown.schedule.items"
        [filter]="true"
        [showClear]="true"
        (onChange)="getWallThickness()"
      ></p-dropdown>
    </div>
    <!-- #endregion Schedule -->

    <!-- #region Wall Thickness -->
    <div class="col-2" [hidden]="hideForContinuity">
      <label for="wallThickness">Wall Thickness</label>
      <input
        pInputText
        class="formInput"
        type="text"
        name="wallThickness"
        formControlName="wallThickness"
        placeholder="Select Size and Schedule"
        (keypress)="keyValidation.numericDecimal($event)"
        maxlength="5"
      />
    </div>
    <!-- #endregion Wall Thickness -->

    <!-- #region WPS -->
    <div class="col-2" [hidden]="hideForContinuity">
      <label for="wpsid">WPS</label>
      <p-dropdown
        name="wpsid"
        formControlName="wpsid"
        optionValue="id"
        optionLabel="name"
        placeholder="Select a WPS"
        [options]="_srvDropDown.wps.items"
        [filter]="true"
        [showClear]="true"
      ></p-dropdown>
    </div>
    <!-- #endregion WPS -->

    <!-- #region RT Percentage -->
    <div class="col-2" [hidden]="hideForContinuity">
      <label for="rtpercentageId">RT Percentage <span style="color: red">*</span></label>
      <p-dropdown
        name="rtpercentageId"
        formControlName="rtpercentageId"
        optionValue="id"
        optionLabel="name"
        placeholder="Select a RT Percent"
        [options]="_srvDropDown.rtPercent.items"
        [filter]="true"
      ></p-dropdown>
    </div>
    <!-- #endregion RT Percentage -->

    <!-- #region Joint Type -->
    <div class="col-2" [hidden]="hideForContinuity">
      <label for="jointTypeId">Joint Type <span style="color: red">*</span></label>
      <p-dropdown
        optionValue="id"
        optionLabel="name"
        name="jointTypeId"
        formControlName="jointTypeId"
        placeholder="Select a Joint Type"
        [options]="_srvDropDown.jointTypes.items"
        [filter]="true"
      ></p-dropdown>
    </div>
    <!-- #endregion -->
  </div>
  <!-- #endregion Pipesec, Size, Schedule,WPS, RT Percentage, JointType-->

  <!-- #region Material, Filler Material, Filler Material Date Issused -->
  <div class="row">
    <!-- #region Material From -->
    <div class="col-2" [hidden]="hideForContinuity">
      <label for="materialFromId">Material</label>
      <p-dropdown
        optionValue="id"
        optionLabel="name"
        name="materialFromId"
        formControlName="materialFromId"
        placeholder="Select From"
        [options]="_srvDropDown.material.items"
        [filter]="true"
        [showClear]="true"
      ></p-dropdown>
    </div>
    <!--#endregion Material From -->

    <!-- #region Material To -->
    <div class="col-2" [hidden]="hideForContinuity">
      <label for="materialToId"></label>
      <p-dropdown
        optionValue="id"
        optionLabel="name"
        name="materialToId"
        formControlName="materialToId"
        placeholder="Select To"
        [options]="_srvDropDown.material.items"
        [filter]="true"
        [showClear]="true"
      ></p-dropdown>
    </div>
    <!-- #endregion Material To -->

    <!-- #region Filler Material Needed -->
    <div class="col-2">
      <label for="fillerMatNeeded">Filler Material Needed <span *ngIf="hideForContinuity" style="color: red">*</span></label>
      <p-multiSelect
        optionLabel="name"
        optionValue="id"
        name="fillerMatNeeded"
        formControlName="fillerMatNeeded"
        placeholder="Select Filler Material"
        [options]="_srvDropDown.fillerMat.items"
      ></p-multiSelect>
    </div>
    <!-- #endregion -->

    <!-- #region Filler Material Date Issued -->
    <div class="col-2 calendarDiv">
      <label for="fillerMatDate">Filler Material Date Issued <span *ngIf="hideForContinuity" style="color: red">*</span></label>
      <p-calendar
        class="formCalendar"
        name="fillerMatDate"
        formControlName="fillerMatDate"
        dataType="string"
        dateFormat="yy-mm-dd"
        placeholder="Enter Date"
        appendTo="body"
        [readonlyInput]="true"
        [showButtonBar]="true"
      ></p-calendar>
    </div>
    <!-- #endregion -->
  </div>
  <!-- #endregion  Material, Filler Material, Filler Material Date Issused -->

  <!-- #region  Processes -->
  <div formArrayName="processes">
    <div *ngFor="let process of getProcesses().controls; index as i">
      <div [formGroupName]="i" class="row">
        <!-- #region Id(s) -->
        <div class="col-3">
          <label *ngIf="i == 0">Id(s) <span style="color: red">*</span></label>
          <p-dropdown
            optionValue="id"
            optionLabel="name"
            formControlName="stampid"
            placeholder="Select a Welder"
            [options]="_srvDropDown.stampAllocation.items"
            appendTo="body"
            (onChange)="enableDisableWelders()"
            [filter]="true"
          ></p-dropdown>
        </div>
        <!-- #endregion -->

        <!-- #region Processes -->
        <div class="col-6">
          <label *ngIf="i == 0">Processes <span style="color: red">*</span></label>
          <p-multiSelect optionValue="id" optionLabel="name" formControlName="processids" placeholder="Select a Process" [options]="_srvDropDown.WeldProcesses.items"></p-multiSelect>
        </div>
        <!-- #endregion -->

        <!-- #region Accept/Reject -->
        <div class="col-2" [hidden]="hideForContinuity">
          <label *ngIf="i == 0">Accept/Reject </label>
          <p-dropdown
            name="rtacceptedRejected"
            optionValue="value"
            optionLabel="label"
            formControlName="rtacceptedRejected"
            placeholder="Select Accept/Reject"
            [options]="acceptedRejectedDTO"
            appendTo="body"
            [showClear]="true"
          ></p-dropdown>
        </div>
        <!-- #endregion -->

        <!-- #region Add, Delete Buttons -->
        <div class="col-1 bottom">
          <!-- #region Add -->
          <p-button class="green-btn" icon="pi pi-plus" pTooltip="Add new row" tooltipPosition="right" [disabled]="formDisabled" (onClick)="addAllocationRow()"></p-button>
          <!-- #endregion -->

          <!-- #region Delete -->
          <p-button
            *ngIf="this.getProcesses().getRawValue().length > 1"
            class="red-btn ml-2"
            icon="pi pi-trash"
            pTooltip="Delete row"
            tooltipPosition="right"
            [disabled]="formDisabled"
            (onClick)="removeAllocationRow(i)"
          ></p-button>
          <!-- #endregion -->
        </div>
        <!-- #endregion Add, Delete Buttons -->
      </div>
    </div>
  </div>
  <!-- #endregion Processes -->

  <!-- #region Additional NDE -->
  <div class="row" [hidden]="hideForContinuity">
    <div class="col-5" *ngIf="this.action == 2 && this.currentLog.original.additionalNde != null">
      <label for="additionalNde">Additional NDE</label>
      <textarea
        pInputText
        type="text"
        class="formInput"
        name="additionalNde"
        rows="1"
        formControlName="additionalNde"
        maxlength="100"
        oninput="if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
      >
      </textarea>
    </div>
  </div>
  <!-- #endregion Additional NDE -->

  <app-qa-type *ngIf="!hideForContinuity" [action]="action" [currentLog]="currentLog"></app-qa-type>

  <!-- #region Comment -->
  <div class="row" [hidden]="hideForContinuity">
    <div class="col-5">
      <label for="comments">Comment</label>
      <textarea
        pInputText
        type="text"
        class="formInput"
        name="comments"
        rows="1"
        formControlName="comments"
        maxlength="500"
        oninput="if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
      >
      </textarea>
    </div>
  </div>
  <!-- #endregion Comment -->

  <!-- #region Created By/Updated By -->
  <div *ngIf="action === 1 || action === 2">
    <div class="row">
      <div class="col-4"><b>Created By: </b>{{ this.currentLog.createdBy }}</div>
      <div class="col-4"><b>Created Date and Time: </b> {{ this.currentLog.createdDateTime | date : 'yyyy-MM-dd HH:mm' }}</div>
    </div>
    <div class="row">
      <div class="col-4"><b>Last Updated By: </b>{{ this.currentLog.updatedBy }}</div>
      <div class="col-4">
        <b>Last Updated Date and Time: </b>
        {{ this.currentLog.updatedDateTime ? (this.currentLog.updatedDateTime | date : 'yyyy-MM-dd HH:mm' : '') : '' }}
      </div>
    </div>
  </div>
  <!-- #endregion Created By/Updated By -->
</form>
<!-- #endregion Form Content -->

<!-- #region message box -->
<p-toast [style]="{ marginTop: '40px !important' }" key="inconsistentLogPrompt" [baseZIndex]="6000" position="center">
  <ng-template let-message pTemplate="message">
    <div class="flex flex-column" style="flex: 1">
      <div class="text-center">
        <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
        <h4>{{ message.summary }}</h4>
        <p>{{ message.detail }}</p>
      </div>
      <div class="grid p-fluid">
        <div class="col-6">
          <button type="button" pButton (click)="onConfirm()" label="Yes" class="p-button-success"></button>
        </div>
        <div class="col-6">
          <button type="button" pButton (click)="onReject()" label="No" class="p-button-secondary"></button>
        </div>
      </div>
    </div>
  </ng-template>
</p-toast>

<p-toast [style]="{ marginTop: '40px !important' }" key="ndeLogPrompt" [baseZIndex]="6000" position="center">
  <ng-template let-message pTemplate="message">
    <div class="flex flex-column" style="flex: 1">
      <div class="text-center">
        <h4>{{ message.summary }}</h4>
        <p>{{ message.detail }}</p>
      </div>
      <div class="grid p-fluid">
        <div class="col-6">
          <button type="button" pButton (click)="onConfirm()" label="Continue" class="p-button-lg p-button-raised p-button-success"></button>
        </div>
      </div>
    </div>
  </ng-template>
</p-toast>
<!-- #endregion message box -->
