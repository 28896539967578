import { Injectable } from '@angular/core';
import { WeldLog } from '../../core/models';
import * as moment from 'moment';
@Injectable()
export class WeldLogTable extends WeldLog {
  constructor(log: WeldLog) {
    super();
    this.original = log;

    // Copy over all the WeldLog properties
    Object.assign(this, log);

    this.dateWelded = this.extractDate(log.dateWelded);
    this.materialFromTo =
      log.materialFrom !== null && log.materialTo !== null ? `${log.materialFrom} to ${log.materialTo}` : '';

    this.createdBy = log.createdBy === 'dbo' ? 'System' : this.getUserName(log.createdBy);
    this.updatedBy = log.updatedBy === 'dbo' ? 'System' : this.getUserName(log.updatedBy);
    this.createdDateTime = log.createdDateTime ? `${this.formatDate(log.createdDateTime, 'yyyy-MM-DD HH:mm')}` : '';
    this.updatedDateTime = log.updatedDateTime ? this.formatDate(log.updatedDateTime, 'YYYY-MM-DD HH:mm') : '';

    //if true its not a draft so we flip it here (this one changes it for the table display)
    this.logEntryStatus = !log.logEntryStatus;
    this.entryStatus = this.logEntryStatus ? 'Draft' : 'Log';

    this.weldTypeDesc = this.mapWeldType(this.weldType);
    this.acceptReject = this.getAcceptRejectStatus(log.weldLogStampProcesses);

    this.weldLogQAs = log.weldLogQAs;
    this.weldLogFillerMaterials = log.weldLogFillerMaterials;

    this.rtcompletionDate = this.extractDate(log.rtcompletionDate);
    this.repcompletionDate = this.extractDate(log.repcompletionDate);
    this.pwhtcompletionDate = this.extractDate(log.pwhtcompletionDate);
    this.bhtcompletionDate = this.extractDate(log.bhtcompletionDate);
    this.vtcompletionDate = this.extractDate(log.vtcompletionDate);
    this.pmicompletionDate = this.extractDate(log.pmicompletionDate);
    this.pmivcompletionDate = this.extractDate(log.pmivcompletionDate);

    this.fillerMatDate = this.extractDate(this.weldLogFillerMaterials[0]?.fillerMaterialIssuedDate);

    // populate fillerMatString with all the mats
    this.fillerMatString = this.weldLogFillerMaterials.map(f => f.fillerMaterialName).join(', ');

    // Ids and processes needs to be a distinct set since there can be multiple processes per welder.
    this.processString = Array.from(new Set(this.weldLogStampProcesses.map(w => w.weldProcessName)))
      .sort((a, b) => a.localeCompare(b))
      .join(', ');

    this.idsString = Array.from(new Set(this.weldLogStampProcesses.map(w => w.stampNumber)))
      .sort((a, b) => Number(a) - Number(b))
      .join(', ');

    this.compileQaInformation(this.weldLogQAs);
  }

  private compileQaInformation(qaEntries: any[]) {
    const qaTypeNames = Array.from(new Set(qaEntries.map(q => q.qaTypeName)));

    qaTypeNames.forEach(qaTypeName => {
      const tempQas = qaEntries.filter(qaEntry => qaEntry.qaTypeName === qaTypeName);
      const qaEntriesForQaType = tempQas.sort((a, b) => (a.fieldSequence < b.fieldSequence ? -1 : 1)); // these are sorted for the popup label generation

      // compile the completed/total information
      const count = qaEntriesForQaType.length;
      const nonNullCount = qaEntriesForQaType.filter(qaEntry => qaEntry.qaDate !== null).length;
      this[`QAType_${qaTypeName}`] = `${nonNullCount} / ${count}`;

      // Get an array of the label names for this qaTypeName
      const labelNames = qaEntriesForQaType[0].labelNames.split(', ');

      const labelEntries = qaEntriesForQaType.map(e => {
        // create a label from the label name and qaDate
        return `${labelNames[e.fieldSequence - 1]}: ${this.extractDate(e.qaDate) ?? '-'}`;
      });

      this[`QADate_${qaTypeName}`] = labelEntries.join('\n\n');
    });
  }

  private mapWeldType(value: number) {
    switch (value) {
      case 1:
        return 'Production';
      case 2:
        return 'Continuity';
      case 3:
        return 'Qualification';
    }
    return '';
  }

  // Create a function to map the rtacceptedRejected value to strings
  private mapRtacceptedRejected(value: boolean | null): string {
    if (value === null) {
      return '-';
    }
    return value ? 'Accepted' : 'Rejected';
  }

  // Reject is lexically the greatest value and if any rejects exist, the value is Reject.
  // If there's no rejects, then any Accept will be the highest value.
  private getAcceptRejectStatus(objects: Array<any>): string {
    //predefined order: Rejected > Accepted > -
    const statusOrder = ['Rejected', 'Accepted', '-'];

    //The sort function places elements in this order
    const values = objects.map(ob => this.mapRtacceptedRejected(ob.rtacceptedRejected));

    return values.sort((a, b) => statusOrder.indexOf(b) - statusOrder.indexOf(a))[0];
  }

  private formatDate(date: string, dateFormat: string): string {
    return moment(date).format(dateFormat);
  }

  // return the yyyy-mm-dd portion of the date string or '' if null/undefined.
  private extractDate(value: string): string {
    if (value === null || value === undefined || value === '') {
      return null;
    }
    return value.split('T')[0];
  }

  private getUserName(userId: string): string {
    // Check if the user is null or undefined.
    if (userId === null || userId === undefined) {
      return '';
    }

    const DTO: any[] = JSON.parse(localStorage.getItem('AllUsers'));
    const user = DTO.find(u => u.userId === userId);
    const firstName = user?.firstName || '';
    const lastName = user?.lastName || '';
    const fullName = (firstName + ' ' + lastName).trim();

    return fullName;
  }

  public fillerMatString = '';
  public fillerMatDate = '';
  public processString = '';
  public idsString = '';
  public ewopostring = '';
  public materialFromTo = '';
  public rtPercentage: number;
}
