import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CalAngularModule } from '@cvx/cal-angular';
import { AuthService } from './auth/auth.service-api';
import { TokenInterceptor } from './interceptors/token-interceptor';

@NgModule({
  imports: [CommonModule, RouterModule, HttpClientModule, CalAngularModule.forRoot(AuthService.calConfig)],
  exports: [RouterModule, HttpClientModule],
  declarations: [],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true }]
})
export class CoreModule {}
