import { Component, OnInit } from '@angular/core';
import packageInfo from '../../../../../package.json';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  //#region 'Variables'
  public currentYear = '';
  public version: string = packageInfo.version;
  //#endregion 'Variables'

  //#region 'Angular Life Cycle'
  ngOnInit() {
    this.currentYear = new Date().getFullYear().toString();
  }
  //#endregion 'Angular Life Cycle'
}
