import { Base } from './Base.model';

export class Division extends Base {
  constructor() {
    super();
  }

  public divisionId: number;
  public divisionCode: string;
  public divisionDescription: string;
  public refineryId: number;
}
