import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Role } from '../models';
import { BaseApiService } from '../shared/base-api.service';

@Injectable({
  providedIn: 'root'
})
export class RoleApiService extends BaseApiService<Role> {
  //#region 'Angular Life Cycle'
  constructor(protected _http: HttpClient) {
    super(_http, 'role');
  }
  //#endregion

  //#region 'Get'
  public GetRolesAsync(): Observable<Role[]> {
    return this.getAll('/GetRolesAsync');
  }

  public GetRolesAsyncById(roleid: number): Observable<Role> {
    const options = { roleid };
    return this.getById(options, '/GetRolesAsync');
  }

  public GetRolePermissionByRoleAsync(roleid: number): Observable<Role> {
    const options = { roleid };
    return this.getById(options, '/GetRolePermissionByRoleAsync');
  }

  public GetRolesPermissionsByRefineryAsync(refineryid: number): Observable<Role[]> {
    const options = { refineryid };
    return this.getAllById(options, '/GetRolesPermissionsByRefineryAsync');
  }
  //#endregion

  //#region 'Create'
  public UpdateNewRoleAndPermissionsAsync(role: Role, permissions: number[]): Observable<boolean> {
    return this.createAnyWithQueryParams(role, '/UpdateNewRoleAndPermissionsAsync', permissions);
  }
  //#endregion

  //#region 'Update'
  public AddNewRoleAndPermissionsAsync(role: Role, permissions: number[]): Observable<boolean> {
    return this.createAnyWithQueryParams(role, '/AddNewRoleAndPermissionsAsync', permissions);
  }
  //#endregion

  //#region 'Delete'
  //#endregion

  //#region 'Other'
  //#endregion
}
