import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { WeldLog, WeldNoParams } from '../../../../core/models';
import {
  DropdownService,
  KeypressValidationService,
  LoadingIndicatorService,
  QaTypeService,
  RtPercentageService,
  WeldLogService,
  UserInfoService,
  WallThicknessService
} from '../../../../data/services';
import { NotificationToast, NotificationService } from '../../../shared/notification';
import { MessageService } from 'primeng/api';
import { QaTypeComponent } from 'src/app/layout/common/qa-type/qa-type.component';

@Component({
  selector: 'app-add-edit-view-log',
  templateUrl: './add-edit-view-log.component.html',
  styleUrls: ['./add-edit-view-log.component.scss']
})
export class AddEditViewLogComponent implements AfterViewInit {
  //#region 'Variables'
  @Input() public action: number; // 0 = New, 1 = Edit, 2 = View
  @Input() public draftBtn: string;
  @Input() public dialogHeader: string;
  @Input() public dialogSubmit: string;
  @Input() public userPermissions: any;
  @Input() public isViewEdit: boolean;
  @Input() public hideDraftBtn: boolean;
  @Input() public hideActionBtns: boolean;
  @Input() public hideForContinuity: boolean;
  @Input() public currentLog: WeldLog = new WeldLog();
  @Input() public requiredFieldsLog: number; // 0 = defaultFields, 1 = MCBU Fields
  @Output() public onCancel: EventEmitter<any> = new EventEmitter<any>();
  @Output() public onSuccess: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild(QaTypeComponent) qaComponent: QaTypeComponent;

  public formDisabled = false;
  public weldRepairButtonEnable = false;
  private bypassWarning = false;
  private inconsistentLogPromptMessage = '';
  public weldModifierRegex = /R\d+$/;
  public hideForMCBU = false;
  public showForMCBU = true;

  public logForm = new UntypedFormGroup({
    weldNo: new UntypedFormControl(),
    weldModifier: new UntypedFormControl(),
    weldType: new UntypedFormControl(),
    ewonumber: new UntypedFormControl(),
    projectOrPo: new UntypedFormControl(),
    divisionId: new UntypedFormControl(),
    weldTypeIdentifierId: new UntypedFormControl(),
    unitId: new UntypedFormControl(),
    weldGroupId: new UntypedFormControl(),
    wpsid: new UntypedFormControl(),
    qmtrackingNumber: new UntypedFormControl(),
    spec: new UntypedFormControl(),
    rtpercentageId: new UntypedFormControl(),
    continuityWeld: new UntypedFormControl(),
    processes: new UntypedFormArray([]),
    qaTypesAndLabels: new UntypedFormArray([]),
    fillerMatNeeded: new UntypedFormControl(),
    fillerMatDate: new UntypedFormControl(),
    dateWelded: new UntypedFormControl(),
    drawing: new UntypedFormControl(),
    jointTypeId: new UntypedFormControl(),
    rtcompletionDate: new UntypedFormControl(),
    repcompletionDate: new UntypedFormControl(),
    pwhtcompletionDate: new UntypedFormControl(),
    bhtcompletionDate: new UntypedFormControl(),
    vtcompletionDate: new UntypedFormControl(),
    pmicompletionDate: new UntypedFormControl(),
    pmivcompletionDate: new UntypedFormControl(),
    comments: new UntypedFormControl(),
    additionalNde: new UntypedFormControl(),
    materialFromId: new UntypedFormControl(),
    materialToId: new UntypedFormControl(),
    sizeId: new UntypedFormControl(),
    scheduleId: new UntypedFormControl(),
    wallThickness: new UntypedFormControl(),
    weldRepair: new UntypedFormControl(false),
    weldId: new UntypedFormControl()
  });

  private notContinuityFields = [
    'weldNo',
    'weldModifier',
    'ewonumber',
    'projectOrPo',
    'divisionId',
    'weldTypeIdentifierId',
    'unitId',
    'weldGroupId',
    'wpsid',
    'qmtrackingNumber',
    'spec',
    'rtpercentageId',
    'drawing',
    'jointTypeId',
    'rtcompletionDate',
    'repcompletionDate',
    'pwhtcompletionDate',
    'bhtcompletionDate',
    'vtcompletionDate',
    'pmicompletionDate',
    'pmivcompletionDate',
    'comments',
    'additionalNde',
    'weldRepair',
    'weldId'
  ];

  private productionRequiredFields = [
    'ewonumber',
    'divisionId',
    'unitId',
    'weldGroupId',
    'spec',
    'rtpercentageId',
    'drawing',
    'jointTypeId',
    'dateWelded'
  ];

  private mcbuProductionRequiredFields = [
    'ewonumber',
    'divisionId',
    'weldTypeIdentifierId',
    'unitId',
    'weldGroupId',
    'spec',
    'rtpercentageId',
    'jointTypeId',
    'dateWelded'
  ];

  private continuityRequiredFields = ['dateWelded', 'fillerMatNeeded', 'fillerMatDate'];

  private drafRequiredFields = ['ewonumber', 'weldGroupId', 'divisionId', 'unitId'];

  public acceptedRejectedDTO = [
    { label: 'Accepted', value: true },
    { label: 'Rejected', value: false }
  ];

  public displayEditMultipleQaTypes = false;
  private qaTypeCheckNames = ['RT', 'UTSW', 'PAUT']; //qa types used to check against rt percentage
  private qaTypeCheckIds: number[]; //qaTypeIds for the above types... using a lookup in case the db changes
  private rtPercentageZeroId: number; //id rt percentage = 0%... using a lookup in case the db changes
  private qaTypeNonQMDB = ['Ferrite Testing', 'PWHT', 'VT', 'PMIV', 'Copper Sulfate', 'Hydrotest']; //qaType not passing to QMDB
  private qaTypeNonQMDBIds: number[];
  //#endregion

  //#region 'Angular Life Cycle'
  constructor(
    private cdRef: ChangeDetectorRef,
    public _srvDropDown: DropdownService,
    private _srvWeldLog: WeldLogService,
    private _srvNotify: NotificationService,
    private _loader: LoadingIndicatorService,
    public keyValidation: KeypressValidationService,
    private _notify: MessageService,
    private _srvQAType: QaTypeService,
    private srvRTpercentage: RtPercentageService,
    private _srvWallThickness: WallThicknessService
  ) {}

  ngAfterViewInit(): void {
    this.setUpMode();
    this.getQATypeCheckIds();
    this.getQATypeQMDBCheckIds();
    this.getRtPercentageZeroId();
    this.cdRef.detectChanges();
    this.hideRequiredMarker();
  }
  //#endregion

  //#region 'Load'
  private setUpMode(): void {
    if (this.action === 0) {
      this.newLog();
      return;
    }
    if (this.action === 1) {
      this.editLog();
      return;
    }
    if (this.action === 2) {
      this.viewLog();
    }
  }

  public getUnits() {
    this.currentLog.unitId = null;
    this._srvDropDown.GetUnitsByDivisionAsync(this.logForm.value.divisionId);
    this.getWeldNo(1);
  }

  public hideRequiredMarker() {
    //hide the required * icon based on user's refinery
    if (this.requiredFieldsLog === 1) {
      this.hideForMCBU = true;
      this.showForMCBU = false;
    }
  }
  //#endregion

  //#region 'CRUD Weld Log'
  public newLog() {
    this.addAllocationRow();
    this.enableAllProcesses();
    this.enableForm();
    this.logForm.controls['weldType'].setValue('1');
    this.logForm.controls['fillerMatDate'].setValue(null);
    this.logForm.controls['fillerMatNeeded'].setValue([]);

    if (this.userPermissions.refineryId === 2) {
      this.logForm.controls['divisionId'].setValue(5);
      this.logForm.controls['unitId'].setValue(100);
    }

    if (this.userPermissions.refineryId === 1) {
      this.logForm.controls['weldGroupId'].setValue(1);
    }

    if (this.userPermissions.refineryId === 3) {
      this.logForm.controls['weldGroupId'].setValue(8);
    }
  }

  public editLog() {
    this.fillForm();
    this.setupFillerMats();
    this.setupProcesses();
    this.enableDisableWelders();
    this.enableForm();
    this.logForm.controls['weldType'].disable();
  }

  public viewLog() {
    this.fillForm();
    this.setupFillerMats();
    this.setupProcesses();
    this.enableDisableWelders();
    this.disableForm();
  }

  public weldTypeChange() {
    if (this.logForm.controls['weldType'].value === '2') {
      //continuity is checked, only let certain items be enabled
      this.hideDraftBtn = true;
      this.hideForContinuity = true;
      this.clearAR();
      this.logForm.disable();
      this.logForm.controls['weldType'].enable();
      this.logForm.controls['dateWelded'].enable();
      this.logForm.controls['fillerMatDate'].enable();
      this.logForm.controls['fillerMatNeeded'].enable();

      for (let control of this.logForm.controls['processes']['controls']) {
        control.enable();
      }

      //reset the fields that continuity doesnt use
      for (let field of this.notContinuityFields) {
        this.logForm.controls[field].reset();
      }
    } else {
      //else, enable the whole form
      this.logForm.enable();
      this.hideDraftBtn = false;
      this.hideForContinuity = false;
    }

    if (this.isViewEdit) {
      //if we are in view/edit, make sure that continuity is disabled
      this.logForm.controls['weldType'].disable();
    }
  }

  private disableForm() {
    this.formDisabled = true;
    this.logForm.disable();
    for (let control of this.logForm.controls['processes']['controls']) {
      control.disable();
    }

    for (let control of this.logForm.controls['qaTypesAndLabels']['controls']) {
      control.disable();
    }
  }

  private enableForm() {
    this.formDisabled = false;
    this.logForm.enable();
    for (let control of this.logForm.controls['processes']['controls']) {
      control.enable();
    }

    for (let control of this.logForm.controls['qaTypesAndLabels']['controls']) {
      control.enable();
    }
  }

  private fillForm() {
    this.logForm.patchValue({
      weldNo: this.currentLog.weldNo,
      weldModifier: this.currentLog.weldModifier,
      weldType: this.currentLog.original?.weldType ? this.currentLog.original.weldType.toString() : '',
      ewonumber: this.currentLog.ewonumber,
      projectOrPo: this.currentLog.projectOrPo,
      divisionId: this.currentLog.original.divisionId,
      weldTypeIdentifierId: this.currentLog.weldTypeIdentifierId,
      unitId: this.currentLog.original.unitId,
      weldGroupId: this.currentLog.original.weldGroupId,
      wpsid: this.currentLog.original.wpsid,
      qmtrackingNumber: this.currentLog.qmtrackingNumber,
      spec: this.currentLog.spec,
      rtpercentageId: this.currentLog.original.rtpercentageId,
      drawing: this.currentLog.drawing,
      jointTypeId: this.currentLog.original.jointTypeId,
      dateWelded: this.currentLog.dateWelded,
      comments: this.currentLog.original.comments,
      materialFromId: this.currentLog.original.materialFromId,
      materialToId: this.currentLog.original.materialToId,
      sizeId: this.currentLog.original.sizeId,
      scheduleId: this.currentLog.original.scheduleId,
      wallThickness: this.currentLog.original.wallThickness,
      additionalNde: this.currentLog.original.additionalNde,
      weldRepair: this.currentLog.original.weldRepair,
      weldId: this.currentLog.original.weldId
    });
  }

  public async getWeldNo(comeFrom: number) {
    const refineryId = Number(this.userPermissions.refineryId);
    const isDivisionChange = comeFrom === 1 && refineryId === 1;
    const isEWOInputBlur = comeFrom === 2 && (refineryId === 2 || refineryId === 3);
    const isGroupChange = comeFrom === 3 && refineryId === 2;

    if ((isDivisionChange || isEWOInputBlur || isGroupChange) && this.action === 0) {
      const DTO = new WeldNoParams();
      DTO.RefineryId = refineryId;
      DTO.DivisionId = isDivisionChange && this.logForm.value.divisionId > 0 ? this.logForm.value.divisionId : null;
      DTO.EWONumber =
        (isEWOInputBlur || isGroupChange) && this.logForm.value.ewonumber?.length > 0
          ? this.logForm.value.ewonumber
          : null;
      DTO.WeldGroupId =
        (isEWOInputBlur || isGroupChange) && this.logForm.value.weldGroupId > 0 ? this.logForm.value.weldGroupId : null;

      const weldNo = await this._srvWeldLog.GetNextWeldNumberAsync(DTO);
      this.logForm.controls['weldNo'].setValue(weldNo);
    }
  }

  public async getWallThickness() {
    //method is called only when both size and schedule is selected
    if (this.logForm.value.sizeId > 0 && this.logForm.value.scheduleId > 0) {
      let SizeId = this.logForm.value.sizeId;
      let ScheduleId = this.logForm.value.scheduleId;

      const wallThickness = await this._srvWallThickness.GetWallThicknessAsync(SizeId, ScheduleId);
      this.logForm.controls['wallThickness'].setValue(wallThickness[0].thickness);
    }
  }
  //#endregion

  //#region 'Log CRUD'
  public async submitNewLog(draft: boolean) {
    this._loader.show();
    const PCS = this.getProcesses().value;
    const QATypePCS = this.getQATypeAndLabelRows().getRawValue();
    this.trimFormValues();

    if (draft) {
      this.handleDraftLog(PCS, QATypePCS);
      return;
    }

    if (this.logForm.controls['weldType'].value === '2') {
      this.handleContinuityLog(PCS, QATypePCS);
      return;
    }

    this.handleRegularLog(PCS, QATypePCS);
  }

  private trimFormValues() {
    const fieldsToTrim = ['drawing', 'comments', 'projectOrPo'];
    fieldsToTrim.forEach(field => {
      if (this.logForm.value[field] != null) {
        this.logForm.controls[field].setValue(this.logForm.value[field].trim());
      }
    });
    Object.assign(this.currentLog, this.logForm.value);
  }

  private handleContinuityLog(PCS: any, QATypePCS: any) {
    if (this.verifyContinuityLog()) {
      this.addOrUpdateWeldLog(false, PCS, QATypePCS);
      return;
    }

    this._loader.hide();
  }

  private handleRegularLog(PCS: any, QATypePCS: any) {
    if (!this.verifyValidLog()) {
      this._loader.hide();
      return;
    }
    if ((this.weldModifierInconsistent() || this.rtPercentageQATypeInconsistent()) && !this.bypassWarning) {
      this._loader.hide();
      this.showConfirm();
      return;
    }
    if (this.checkNoOfWeldersperLog() && !this.bypassWarning) {
      this._loader.hide();
      this.showNDEConfirm();
      return;
    }
    this.addOrUpdateWeldLog(false, PCS, QATypePCS);
  }

  private handleDraftLog(PCS: any, QATypePCS: any) {
    if (!this.verifyValidDraftLog()) {
      this._loader.hide();
      return;
    }

    this.addOrUpdateWeldLog(true, PCS, QATypePCS);
  }

  private addOrUpdateWeldLog(isDraft: boolean, PCS: any, QATypePCS: any) {
    this._srvWeldLog
      .AddorUpdateWeldLog(
        this.isViewEdit,
        isDraft,
        this.currentLog,
        this.logForm.value.fillerMatDate,
        this.logForm.value.fillerMatNeeded,
        PCS,
        QATypePCS
      )
      .then(res => {
        this.logSuccessOrFail(res);
      });
  }

  //#endregion

  //#region 'Processes & QA Types'
  public getProcesses(): UntypedFormArray {
    return this.logForm.get('processes') as UntypedFormArray;
  }

  public getQATypeAndLabelRows(): UntypedFormArray {
    // pointing the lofForm qaType contols to the qaType controls in the QaTypeComponent
    // QA Component wont exist for continuity... skip the assign for that case
    if (this.logForm.controls['weldType'].value !== '2') {
      Object.assign(this.logForm.get('qaTypesAndLabels'), this.qaComponent.qaForm.get('qaTypesAndLabels'));
    }
    return this.logForm.get('qaTypesAndLabels') as UntypedFormArray;
  }

  public getQATypeAndLabelRowByIndex(index: number): UntypedFormGroup {
    return this.getQATypeAndLabelRows().at(index) as UntypedFormGroup;
  }

  public getQADatesByIndex(index: number): UntypedFormArray {
    return this.getQATypeAndLabelRowByIndex(index).controls['qaDate'] as UntypedFormArray;
  }

  public enableDisableWelders() {
    // dynamically enable or disable welders based on if they are already selected (so we can't have duplicates)
    for (const dt of this._srvDropDown.stampAllocation.items) {
      for (let j = 0; j < this.getProcesses().length; j++) {
        if (dt.id === this.getProcesses().at(j).value['stampid']) {
          dt.disabled = true;
          break;
        }
        dt.disabled = false;
      }
    }
  }

  public enableAllProcesses() {
    for (let item of this._srvDropDown.stampAllocation.items) {
      item.disabled = false;
    }
  }

  private async setupFillerMats() {
    const { controls } = this.logForm;
    let fillerMaterialNeeded = [];

    this.currentLog.weldLogFillerMaterials.forEach(dt => {
      fillerMaterialNeeded.push(dt.fillerMaterialId);
      if (!controls['fillerMatDate'].value && dt.fillerMaterialIssuedDate) {
        controls['fillerMatDate'].setValue(dt.fillerMaterialIssuedDate.split('T')[0]);
      }
    });
    controls['fillerMatNeeded'].setValue(fillerMaterialNeeded);
  }

  private async setupProcesses() {
    const processes = this.getProcesses();
    processes.clear();

    this.currentLog.weldLogStampProcesses.forEach(dt => {
      const index = processes
        .getRawValue()
        .map(e => e.stampid)
        .indexOf(dt.stampAllocationId);
      if (index !== -1) {
        processes.controls[index].value.processids.push(dt.weldProcessId);
      } else {
        const PCS = new UntypedFormGroup({
          stampid: new UntypedFormControl(dt.stampAllocationId),
          processids: new UntypedFormControl([dt.weldProcessId]),
          rtacceptedRejected: new UntypedFormControl(dt.rtacceptedRejected)
        });
        processes.push(PCS);
      }
    });

    if (!this.currentLog.weldLogStampProcesses.length) {
      this.addAllocationRow();
    }
  }

  public addAllocationRow() {
    if (this.getProcesses().getRawValue().length < this._srvDropDown.stampAllocation.items.length) {
      let temp = new UntypedFormGroup({
        stampid: new UntypedFormControl(),
        processids: new UntypedFormControl([]),
        rtacceptedRejected: new UntypedFormControl()
      });
      this.getProcesses().push(temp);
    }
  }

  public removeAllocationRow(index: number) {
    this.getProcesses().removeAt(index);
    this.enableDisableWelders();
  }

  private clearAR(): void {
    for (let i = 0; i < this.getProcesses().getRawValue().length; i++) {
      const PRCS = this.getProcesses().controls[i] as UntypedFormGroup;
      PRCS.controls['rtacceptedRejected'].setValue(null);
    }
  }
  //#endregion

  //#region 'Validations'

  public rtPercentageQATypeInconsistent() {
    //do the qa types exist on the form
    let qaCheck =
      this.logForm.value.qaTypesAndLabels.filter(qaType => this.qaTypeCheckIds.includes(qaType.qaTypeID)).length > 0;
    //is the rt percentage zero on the form
    let rtPercentageCheck = this.logForm.value.rtpercentageId === this.rtPercentageZeroId;

    if (rtPercentageCheck && qaCheck) {
      // when rt = 0, QA Types ("RT", "UTSW" nor "PAUT") should not exist
      this.inconsistentLogPromptMessage =
        'RT Percentage is equal to 0% and either "RT", "UTSW" or "PAUT" is selected. Would you like to continue?';
      return true;
    }

    if (!rtPercentageCheck && !qaCheck) {
      // when rt != 0, QA Types ("RT", "UTSW" nor "PAUT") should exist
      this.inconsistentLogPromptMessage =
        'RT Percentage is NOT equal to 0% and NEITHER "RT", "UTSW" nor "PAUT" is selected. Would you like to continue?';
      return true;
    }

    return false;
  }

  //get qaTypeIds for 'RT', 'UTSW' & 'PAUT'
  private async getQATypeCheckIds() {
    let qaTypes = await this._srvQAType.GetQAType();
    this.qaTypeCheckIds = qaTypes
      .filter(qaType => this.qaTypeCheckNames.includes(qaType.qaTypeName))
      .map(qaType => qaType.qaTypeID);
  }

  //get rtPercentageId for 0%
  private async getRtPercentageZeroId() {
    let rtPercentage = await this.srvRTpercentage.GetRTPercentageByRefineryAsync();
    this.rtPercentageZeroId = rtPercentage.filter(percentage => percentage.rtPercentage === 0)[0].rtpercentageId;
  }

  // Check elegible QA type for NDE creating in QMDB with multiple welders
  public checkNoOfWeldersperLog() {
    const qaQMDBCheck =
      this.logForm.value.qaTypesAndLabels.filter(qaType => !this.qaTypeNonQMDBIds.includes(qaType.qaTypeID)).length > 0;
    const welderCount = this.logForm.value.processes.length;

    if (qaQMDBCheck && welderCount > 1) {
      this.inconsistentLogPromptMessage =
        'This weld log has multiple welders associated with it, only one welder will be assigned to the NDE and re-assignment can be done in QMDB';
      return true;
    }

    return false;
  }

  private async getQATypeQMDBCheckIds() {
    const qaTypes = await this._srvQAType.GetQAType();
    this.qaTypeNonQMDBIds = qaTypes
      .filter(qaType => this.qaTypeNonQMDB.includes(qaType.qaTypeName))
      .map(qaType => qaType.qaTypeID);
  }

  public weldModifierInconsistent() {
    let errorType = this.verifyWeldModiferAndRepair();
    if (errorType === 1) {
      this.inconsistentLogPromptMessage =
        'The Weld Modifier field suggests its a repair weld while the Repair Weld button is not selected. Do you still wish to continue?';
      return true;
    }
    if (errorType === 2) {
      this.inconsistentLogPromptMessage =
        'The Weld Modifier field suggests its a NOT repair weld while the Repair Weld button is selected. Do you still wish to continue?';
      return true;
    }
    return false;
  }

  private showConfirm() {
    this._notify.clear();
    this._notify.add({
      key: 'inconsistentLogPrompt',
      sticky: true,
      severity: 'warn',
      summary: `Uh oh! There's an inconsistency in the form.`,
      detail: `${this.inconsistentLogPromptMessage}`
    });
  }

  private showNDEConfirm() {
    this._notify.clear();
    this._notify.add({
      key: 'ndeLogPrompt',
      sticky: true,
      severity: 'info',
      summary: `QMDB NDE creation warning.`,
      detail: `${this.inconsistentLogPromptMessage}`
    });
  }

  public onConfirm() {
    this._notify.clear();
    this.bypassWarning = true; //meaning they saw the warning and still want to submit
    this.submitNewLog(false);
  }

  public onReject() {
    this._notify.clear();
  }

  checkWeldModifier(value: any) {
    this.logForm.value.weldRepair = this.weldModifierRegex.test(value);
  }

  private verifyWeldModiferAndRepair(): number {
    if (this.weldModifierRegex.test(this.logForm.value.weldModifier) && !this.logForm.value.weldRepair) {
      return 1;
    }
    if (!this.weldModifierRegex.test(this.logForm.value.weldModifier) && this.logForm.value.weldRepair) {
      return 2;
    }

    return 0;
  }

  private verifyValidLog(): boolean {
    let flag = true;
    this.clearErrors();

    if (this.requiredFieldsLog === 1) {
      flag = this.verifyMCBUFields(flag);
    } else {
      flag = this.verifyFields(flag);
    }

    flag = this.verifyValidProcesses(flag);
    flag = this.verifyQATypes(flag);

    if (!flag) {
      this.commonErrorNotification('Production Log is missing some fields, please check the form.');
      return flag;
    }

    flag = this.verifyFillerMats(flag);
    if (!flag) {
      this.commonErrorNotification(
        'Please select "Filler Material Needed" or clear the "Filler Material Date Issued".'
      );
    }

    return flag;
  }

  private verifyMCBUFields(existing: boolean): boolean {
    let flag = existing;
    for (const dt of this.mcbuProductionRequiredFields) {
      if (this.logForm.value[dt] == null || this.logForm.value[dt] == '') {
        this.logForm.controls[dt].setErrors({ incorrect: true });
        this.logForm.controls[dt].markAsDirty();
        flag = false;
      }
    }
    return flag;
  }

  private verifyFields(existing: boolean): boolean {
    let flag = existing;
    for (const dt of this.productionRequiredFields) {
      if (this.logForm.value[dt] == null || this.logForm.value[dt] == '') {
        this.logForm.controls[dt].setErrors({ incorrect: true });
        this.logForm.controls[dt].markAsDirty();
        flag = false;
      }
    }
    return flag;
  }

  private verifyValidProcesses(existing: boolean): boolean {
    let flag = existing;
    let index = 0;
    for (let stampProcess of this.getProcesses().getRawValue()) {
      const PRCS = this.getProcesses().controls[index] as UntypedFormGroup;
      if (stampProcess.stampid === null) {
        PRCS.controls['stampid'].setErrors({ incorrect: true });
        PRCS.controls['stampid'].markAsDirty();
        flag = false;
      }

      if (stampProcess.processids.length === 0) {
        PRCS.controls['processids'].setErrors({ incorrect: true });
        PRCS.controls['processids'].markAsDirty();
        flag = false;
      }

      index++;
    }
    return flag;
  }

  private markAsError(control: any) {
    control.setErrors({ incorrect: true });
    control.markAsDirty();
  }

  private verifyQATypes(existing: boolean): boolean {
    let flag = existing;
    let index = 0;

    for (let qaProcess of this.getQATypeAndLabelRows().getRawValue()) {
      const PRCS = this.getQATypeAndLabelRows().controls[index] as UntypedFormGroup;
      if (qaProcess.qaTypeID === null) {
        this.markAsError(PRCS.controls['qaTypeID']);
        flag = false;
      }

      if (qaProcess.qaTypeLabelID === null) {
        this.markAsError(PRCS.controls['qaTypeLabelID']);
        flag = false;
      }
      index++;
    }
    return flag;
  }

  private verifyFillerMats(existing: boolean): boolean {
    let flag = existing;
    let value = this.logForm.controls['fillerMatDate'].value;
    if (value !== null && (value === null || value.length === 0)) {
      this.markAsError(this.logForm.controls['fillerMatNeeded']);
      return false;
    }
    return flag;
  }

  private verifyValidDraftLog(): boolean {
    let flag = true;
    this.clearErrors();

    for (const dt of this.drafRequiredFields) {
      if (this.logForm.value[dt] == null || this.logForm.value[dt] == '') {
        this.markAsError(this.logForm.controls[dt]);
        flag = false;
      }
    }

    if (!flag) {
      this.commonErrorNotification('Missing one or more required fields to save as Draft, please check the form.');
      return flag;
    }

    flag = this.verifyFillerMats(flag);
    if (!flag) {
      this.commonErrorNotification(
        'Please select "Filler Material Needed" or clear the "Filler Material Date Issued".'
      );
    }

    return flag;
  }

  private verifyContinuityLog(): boolean {
    let flag = true;
    this.clearErrors();

    for (const dt of this.continuityRequiredFields) {
      if (this.logForm.value[dt] == null || this.logForm.value[dt].length === 0) {
        this.logForm.controls[dt].setErrors({ incorrect: true });
        this.logForm.controls[dt].markAsDirty();
        flag = false;
      }
    }

    flag = this.verifyValidProcesses(flag);

    if (!flag) {
      this.commonErrorNotification('Continuity Log is missing one or more required fields, please check the form.');
    }

    return flag;
  }

  private clearControl(control: any) {
    control.setErrors({ incorrect: null });
    control.updateValueAndValidity();
  }
  private clearErrors(): void {
    Object.keys(this.logForm.controls).forEach(key => {
      this.clearControl(this.logForm.controls[key]);
    });

    for (let i = 0; i < this.getProcesses().getRawValue().length; i++) {
      const PRCS = this.getProcesses().controls[i] as UntypedFormGroup;
      this.clearControl(PRCS.controls['stampid']);
      this.clearControl(PRCS.controls['processids']);
      this.clearControl(PRCS.controls['rtacceptedRejected']);
    }
  }
  //#endregion

  //#region 'Notification'
  private showNotification(msg: NotificationToast) {
    this._srvNotify.sendNotification(msg);
  }

  private commonErrorNotification(msg: string) {
    this.showNotification(new NotificationToast('error', msg, '', 'error', 4000));
  }

  //#endregion 'Notification'

  //#region 'Events'
  public cancel(): void {
    this.onCancel.emit();
  }

  public logSuccessOrFail(success: boolean): void {
    this.onSuccess.emit(success);
  }
  //#endregion
}
