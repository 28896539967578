export class WelderStats {
  public welder: string;
  public description: string;
  public totalWelds: number;
  public totalBWs: number;
  public bwsInspected: number;
  public bwsRejected: number;
  public rejectRate: number;
  public totalRTCoverage: number;
  public bwRTCoverage: number;
}

export class WelderStatsParams {
  public refineryid: number;
  public fullName: string;
  public fromdate: string;
  public todate: string;
}
