import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RTPercentage } from '../models';
import { BaseApiService } from '../shared/base-api.service';

@Injectable({
  providedIn: 'root'
})
export class RTPercentageApiService extends BaseApiService<RTPercentage> {
  //#region 'Angular Life Cycle'
  constructor(protected _http: HttpClient) {
    super(_http, 'rtpercentage');
  }
  //#endregion

  //#region 'Get'
  public GetRTPercentageAsync(): Observable<RTPercentage[]> {
    return this.getAll('/GetRTPercentageAsync');
  }

  public GetRTPercentageAsyncById(rtpercentageid: number): Observable<RTPercentage> {
    const options = { rtpercentageid };
    return this.getById(options, '/GetRTPercentageAsync');
  }

  public GetRTPercentageByRefineryAsync(refineryid: number, active?: boolean): Observable<RTPercentage[]> {
    let options = active !== undefined ? { refineryid, active } : { refineryid };
    return this.getAllById(options, '/GetRTPercentageByRefineryAsync');
  }
  //#endregion

  //#region 'Create'
  public UpdateRTPercentageAsync(rtPercentage: RTPercentage): Observable<boolean> {
    return this.createAny(rtPercentage, '/UpdateRTPercentageAsync');
  }
  //#endregion

  //#region 'Update'
  public AddRTPercentageAsync(rtPercentage: RTPercentage): Observable<boolean> {
    return this.createAny(rtPercentage, '/AddRTPercentageAsync');
  }
  //#endregion

  //#region 'Delete'
  //#endregion

  //#region 'Other'
  //#endregion
}
