import { Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { NotificationToast } from '.';

@Injectable()
export class NotificationService {
  private notif = new Subject<NotificationToast>();

  sendNotification(notification: NotificationToast) {
    this.notif.next(notification);
  }

  getNotification(): Observable<NotificationToast> {
    return this.notif.asObservable();
  }
}
