import { Base } from './Base.model';

export class Unit extends Base {
  constructor() {
    super();
  }

  public unitId: number;
  public unitCode: string;
  public unitDescription: string;
  public divisionId: number;
}
