import { Component, OnDestroy, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { AuthService } from './core/auth/auth.service-api';
import { WeldManagerUserService } from './data/services/weld-manager-user.service';
import { NotificationToast } from './layout/shared/notification/notification-toast.model';
import { NotificationService } from './layout/shared/notification/notification.service';
import { DropdownService } from './data/services/dropdown.service';
import { TableHeadersService } from './data/services/table-headers.service';
import { ApplicationInsightsService } from './data/services/applicationInsights.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  private SUBS$: Subscription = new Subscription();

  constructor(
    private _srvAuth: AuthService,
    private _srvNotif: NotificationService,
    private _notify: MessageService,
    private _srvUsers: WeldManagerUserService,
    private _srvDropDown: DropdownService,
    private _srvTableHeaders: TableHeadersService,
    private authService: AuthService,
    private appInsightService: ApplicationInsightsService
  ) {
    const user = this.authService.getCurrentUser();
    if (user) {
      this.appInsightService.setUser(user.username);
    }
  }

  ngOnInit(): void {
    const PERMISSIONS = JSON.parse(localStorage.getItem('user'));
    if (!PERMISSIONS) {
      this._srvAuth.login();
    }

    this._srvUsers.getAllUsers();
    // this._srvDropDown.getAllQATypes();

    this.SUBS$.add(
      this._srvNotif.getNotification().subscribe({
        next: (msg: NotificationToast) => this.addNotification(msg)
      })
    );

    // call the _srvTableHeaders & save it to the local storage
    this._srvTableHeaders.GetTableHeadersAsync().then(res => {
      localStorage.setItem('tableHeaders', JSON.stringify(res));
    });
  }

  ngOnDestroy(): void {
    this.SUBS$.unsubscribe();
  }

  private addNotification(msg: NotificationToast) {
    this._notify.add({
      sticky: false,
      closable: false,
      key: msg.key,
      severity: msg.severity,
      summary: msg.summary,
      detail: msg.detail,
      life: msg.life
    });
  }
}
