// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  ['apiEndpoint']: 'https://wldmgr-api-test.azure.chevron.com',
  ['apiEndpointQMDB']: 'https://qmdb-api-test.azure.chevron.com',
  // app insights
  appInsights: {
    connectionString:
      'InstrumentationKey=42d4d36d-9436-4df1-9eed-20ef38c35cee;IngestionEndpoint=https://southcentralus-3.in.applicationinsights.azure.com/;LiveEndpoint=https://southcentralus.livediagnostics.monitor.azure.com/;ApplicationId=f35567a3-51ee-4951-b906-cc2845934928'
  },
  host: {
    localhost: 'localhost',
    dev: 'wldmgr-dev.azure.chevron.com',
    test: 'wldmgr-test.azure.chevron.com'
  },
  supportLink:
    'https://chevron.service-now.com/itserviceportal?id=sc_cat_item_guide&sys_id=9c08a21edb126450182e1dca48961981&sysparm_u_appid=5054022'
};
