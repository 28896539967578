<!-- #region Title -->
<div class="row">
  <div class="col-12 d-flex justify-content-center">
    <h2>Welder Certification</h2>
  </div>
</div>
<!-- #endregion -->

<!-- #region Search Bar -->
<div class="row">
  <!-- #region Refinery -->
  <div class="col-md-4">
    <div><label>Refinery: </label>{{ refineryname }}</div>
    <div style="margin-top: 4px"></div>
    <br />
    <div>
      <!-- #region Add Qualification Log & Export to Excel-->
      <p-button
        class="green-btn mr-2"
        styleClass="p-button-m mt-4 mr-3"
        (onClick)="newLogOpen()"
        *ngIf="
          PERMISSIONS &&
          (PERMISSIONS.permissions.includes('system admin') || PERMISSIONS.permissions.includes('add new weld log'))
        "
      >
        <em class="pi pi-plus-circle mr-2"></em> Add Qualification Log</p-button
      >
      <p-button styleClass="p-button-m mt-4" (click)="exportToExcel()"
        ><em class="pi pi-file-excel mr-2"></em>Export to Excel</p-button
      >
      <!-- #endregion -->
    </div>
  </div>
  <!-- #endregion -->

  <!-- #region Free space -->
  <div class="col-md-6"></div>
  <!-- #endregion -->

  <!-- #region Color Keys -->
  <div class="col-md-2">
    <div><span class="centerContent">--- Days to expire ---</span></div>
    <div class="green">
      <span class="centerContent">{{ this._srvWCS.Time01 }} or more days</span>
    </div>
    <div class="yellow">
      <span class="centerContent">Between {{ this._srvWCS.Time01 }} and {{ this._srvWCS.Time02 }} days</span>
    </div>
    <div class="orange">
      <span class="centerContent">Between {{ this._srvWCS.Time02 }} and {{ this._srvWCS.Time03 }} day</span>
    </div>
    <div class="red">
      <span class="centerContent">{{ this._srvWCS.Time03 }} Day or expired</span>
    </div>
  </div>
  <!-- #endregion -->
</div>
<!-- #endregion -->

<!-- #region Table -->
<br />
<p-table [columns]="tableColumns" [value]="tableData" responsiveLayout="scroll">
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th
        id="{{ col.header }}"
        *ngFor="let col of columns; let i = index"
        [ngClass]="{ center: i > 0 }"
        [pSortableColumn]="col.field + 'ORD'"
      >
        {{ col.header }}
        <p-sortIcon *ngIf="col.field !== 'icon'" [field]="col.field + 'ORD'"></p-sortIcon>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-columns="columns">
    <tr>
      <td
        *ngFor="let col of columns; let j = index"
        [class]="rowData[col.field + 'Color']"
        [ngClass]="{ center: j > 0 }"
      >
        <div *ngIf="col.field !== 'icon'">
          {{ rowData[col.field] }}
        </div>
        <div *ngIf="col.field === 'icon'">
          <span
            ><em
              (click)="loadQualificationLogs(rowData['refinery'], rowData['stamp'], rowData['welder'])"
              style="font-size: 20px; margin-right: 6px"
              class="pi pi-list"
              pTooltip="View List"
              tooltipPosition="top"
            ></em
          ></span>
        </div>
      </td>
    </tr>
  </ng-template>
</p-table>
<br /><br />
<!-- #endregion -->

<!-- #region Customize view dialog -->
<p-dialog
  header="Qualification Logs"
  [(visible)]="displayQualificationLogs"
  [modal]="true"
  [closeOnEscape]="true"
  [draggable]="false"
  [resizable]="true"
  [blockScroll]="false"
>
  <qualification-log
    *ngIf="displayQualificationLogs && !addNewLog"
    [qualificationLogTableData]="qualificationLogTableData"
    [selectedWelder]="selectedWelder"
  >
  </qualification-log>

  <app-add-edit-view-qualification-log
    *ngIf="displayQualificationLogs && addNewLog"
    [actionQualification]="0"
    [dialogHeader]="'Add New Qualification Log'"
    [dialogSubmit]="'Submit Qualification Log'"
    [userPermissions]="PERMISSIONS"
    [isViewEdit]="false"
    [hideActionBtns]="false"
    [currentLog]="newLog"
    (onCancel)="closeQualificationLogs()"
    (onSuccess)="logSuccessOrFail($event)"
  ></app-add-edit-view-qualification-log>
</p-dialog>
<!-- #endregion -->
