import { Injectable } from '@angular/core';
import moment from 'moment';
import { catchError, of } from 'rxjs';
import { WPSApiService } from 'src/app/core/api';
import { WPS } from 'src/app/core/models';
import { UserInfoService } from '.';

@Injectable({
  providedIn: 'root'
})
export class WPSService {
  constructor(private _srvWPS: WPSApiService, private _srvUserInfo: UserInfoService) {}

  public GetWPsAsync(): Promise<WPS[]> {
    return new Promise<WPS[]>((resolve, reject) => {
      try {
        this._srvWPS.GetWPsAsync().subscribe(res => {
          res.sort((a, b) => (a.wpsid < b.wpsid ? -1 : 1));
          resolve(res);
        });
      } catch (err) {
        reject(Error(err));
      }
    });
  }

  public GetWPsByRefineryAsync(refineryId: number, active?: boolean): Promise<WPS[]> {
    return new Promise<WPS[]>((resolve, reject) => {
      try {
        const observable = active
          ? this._srvWPS.GetActiveWPsByRefineryAsync(refineryId)
          : this._srvWPS.GetAllWPsByRefineryAsync(refineryId, active);

        observable.subscribe(res => {
          resolve(this.processWPSResults(res));
        });
      } catch (err) {
        reject(Error(err));
      }
    });
  }

  private processWPSResults(res: WPS[]): WPS[] {
    res.sort((a, b) => (a.wpsname.toLocaleLowerCase() < b.wpsname.toLocaleLowerCase() ? -1 : 1));
    res.forEach(wps => {
      wps['status'] = wps.active ? 'Active' : 'Inactive';
      wps.createdDateTime = `${moment(wps.createdDateTime).format('MMMM DD, YYYY')}`;
      wps.updatedDateTime = wps.updatedDateTime ? `${moment(wps.updatedDateTime).format('MMMM DD, YYYY')}` : null;
    });
    return res;
  }

  public AddorUpdateWPSAsync(wps: WPS, existing: boolean): Promise<string> {
    if (existing) {
      wps.updatedBy = this._srvUserInfo.userName;
      wps.updatedDateTime = new Date().toJSON();
    } else {
      wps.active = true;
      wps.refineryId = Number(this._srvUserInfo.refineryId);
      wps.createdBy = this._srvUserInfo.userName;
      wps.createdDateTime = new Date().toJSON();
    }

    return new Promise<string>((resolve, reject) => {
      try {
        const observable = existing ? this._srvWPS.UpdateWPsAsync(wps) : this._srvWPS.AddWPsAsync(wps);

        this.handleWPSOperation(observable).then(resolve).catch(reject);
      } catch (err) {
        reject(Error(err));
      }
    });
  }

  private handleWPSOperation(observable): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      observable
        .pipe(
          catchError(err => {
            return of(err.error.Message);
          })
        )
        .subscribe(res => {
          if (res instanceof Error) {
            reject(res);
          } else {
            resolve(res.toString());
          }
        }, reject);
    });
  }
}
