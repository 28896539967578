import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { WeldLog } from '../../../../core/models';
import { DropdownService } from '../../../../data/services';
import { DropdownList } from 'src/app/data/models';

@Component({
  selector: 'app-edit-multiple-qa-types',
  templateUrl: './edit-multiple-qa-types.component.html',
  styleUrls: ['./edit-multiple-qa-types.component.scss']
})
export class EditMultipleQaTypesComponent implements OnInit {
  //#region 'Variables'
  @Input() public action: number; // 0 = New, 1 = Edit, 2 = View
  @Input() public currentLog: WeldLog = new WeldLog();
  @Input() public showCalendar: number;
  @Output() childEvent = new EventEmitter<string[]>();

  public formDisabled = false;
  public qaForm = new UntypedFormGroup({
    qaTypesAndLabels: new UntypedFormArray([])
  });

  public qaTypeInformation = [];
  public itemList = new DropdownList().items;
  public options = new DropdownList().items;
  //#endregion

  constructor(public _srvDropDown: DropdownService) {}

  ngOnInit(): void {
    this.newLog();
    this.resetDatesFields();
    if (this.action === 0) {
      this.setupQADates('Default load', 0);
      this.filterDataForDropdown();
    }
  }

  ngOnDestroy(): void {
    this.resetDatesFields();
  }

  private resetDatesFields() {
    this._srvDropDown.qaTypeLabelList = [];
    this._srvDropDown.splitQATypeLabelValues = [];
    this._srvDropDown.splitIndividualValues = [];
  }

  public newLog() {
    this.addQATypeAndLabelRow();
    this.enableAllQATypes();
    this.enableForm();
  }

  public getQATypeAndLabelRows(): UntypedFormArray {
    return this.qaForm.get('qaTypesAndLabels') as UntypedFormArray;
  }

  public getQATypeAndLabelRowByIndex(index: number): UntypedFormGroup {
    return this.getQATypeAndLabelRows().at(index) as UntypedFormGroup;
  }

  public getQADatesByIndex(index: number): UntypedFormArray {
    return this.getQATypeAndLabelRowByIndex(index).controls['qaDate'] as UntypedFormArray;
  }

  public enableDisableQATypes() {
    // dynamically enable or disable QA Types based on if they are already selected (so we can't have duplicates)
    for (const dt of this._srvDropDown.qaType.items) {
      for (let j = 0; j < this.getQATypeAndLabelRows().length; j++) {
        if (dt.id === this.getQATypeAndLabelRows().at(j).value['qaTypeID']) {
          dt.disabled = true;
          break;
        }
        dt.disabled = false;
      }
    }
  }

  public removeQATypeAndLabelRow(index: number) {
    this.getQATypeAndLabelRows().removeAt(index);
    this.enableDisableQATypes();
  }

  public clearQATypeLabel(index: number) {
    this.qaForm.controls['qaTypesAndLabels']['controls'][index]['controls']['qaTypeLabelID'].setValue(null);
    this.qaForm.controls['qaTypesAndLabels']['controls'][index]['controls']['qaDate'].reset();
    this.qaTypeInformation = [];
  }

  public enableAllQATypes() {
    for (let item of this._srvDropDown.qaType.items) {
      item.disabled = false;
    }
  }

  public addQATypeAndLabelRow() {
    let temp = new UntypedFormGroup({
      qaTypeID: new UntypedFormControl(),
      qaTypeLabelID: new UntypedFormControl(),
      qaDate: new UntypedFormArray([]),
      defectTypeID: new UntypedFormControl()
    });

    this.getQATypeAndLabelRows().push(temp);
  }

  public setupQADates(selectedLabel: any, index: number) {
    this.getQATypeAndLabelRowByIndex(index).controls['qaDate'] = new UntypedFormArray([]);
    if (selectedLabel === 'Default load') {
      this._srvDropDown.splitQATypeLabelValues.splice(0, 1, ['Completion']);
    } else {
      let selectedValue = this._srvDropDown.qaTypeLabelList[index].items.find(x => x.id === selectedLabel.value);
      this._srvDropDown.getSplitValues(selectedValue, index);
    }

    for (let i = 0; i < this._srvDropDown.splitQATypeLabelValues[index].length; i++) {
      let control = new UntypedFormControl();
      let temp = new UntypedFormGroup({
        date: control
      });

      if (this.getQADatesByIndex(index).length > 0) {
        this.getQADatesByIndex(index).insert(index, temp);
      } else {
        this.getQADatesByIndex(index).push(temp);
      }
    }
  }

  async sendData() {
    for (let i = 0; i < this.getQATypeAndLabelRows().length; i++) {
      let row = this.getQATypeAndLabelRowByIndex(i);
      let qaTypeID = row.controls['qaTypeID'].value;
      let date;

      let dateElem = row.controls['qaDate'] as UntypedFormArray;
      date = dateElem['value'][0]['date'];

      await this._srvDropDown.GetQATypeLabels(qaTypeID, i).then(res => {
        let calendarList = this._srvDropDown.qaTypeLabelList[i].items;
        let element = calendarList.find(x => x.id == row.controls['qaTypeLabelID'].value);

        if (element !== undefined) {
          this._srvDropDown.getSplitValues(element, i);
          let qaContentArray = [...this.getQATypeAndLabelRows().value];
          let dateFields = (qaContentArray[0].qaDate = []);
          if (this.showCalendar != 1) {
            for (let j = 0; j < this._srvDropDown.splitQATypeLabelValues[0].length; j++) {
              dateFields.push(-1);
            }
          } else {
            dateFields.push(date);
          }

          this.qaTypeInformation = qaContentArray;
          this.childEvent.emit(this.qaTypeInformation);
        }
      });
    }
  }

  private disableForm() {
    this.formDisabled = true;
    for (let control of this.qaForm.controls['qaTypesAndLabels']['controls']) {
      control.disable();
    }
  }

  private enableForm() {
    this.formDisabled = false;
    for (let control of this.qaForm.controls['qaTypesAndLabels']['controls']) {
      control.enable();
    }
  }

  filterDataForDropdown() {
    if (this.showCalendar == 1) {
      for (let i = 0; i < this._srvDropDown.qaType.items.length; i++) {
        let currItemID = this._srvDropDown.qaType.items[i].id;
        this._srvDropDown.GetQATypeLabelsPlain(currItemID).then((res: any[]) => {
          for (let j = 0; j < res.length; j++) {
            if (res[j].noofLabels === 1) {
              this.itemList.push(this._srvDropDown.qaType.items[i]);
              break;
            }
          }
        });
      }
    } else {
      this.itemList = this._srvDropDown.qaType.items;
    }
  }
}
