import { Injectable } from '@angular/core';
import moment from 'moment';
import { catchError, of } from 'rxjs';
import { WeldProcessApiService } from 'src/app/core/api';
import { WeldProcess } from 'src/app/core/models';
import { UserInfoService } from '.';

@Injectable({
  providedIn: 'root'
})
export class WeldProcessService {
  constructor(private _srvWeldProcess: WeldProcessApiService, private _srvUserInfo: UserInfoService) {}

  public GetWeldProcessesAsync(active?: boolean): Promise<WeldProcess[]> {
    return new Promise<WeldProcess[]>((resolve, reject) => {
      try {
        const observable = active
          ? this._srvWeldProcess.GetActiveWeldProcessesAsync()
          : this._srvWeldProcess.GetAllWeldProcessesAsync(active);

        observable.subscribe(res => {
          resolve(this.processWeldProcessResults(res));
        });
      } catch (err) {
        reject(Error(err));
      }
    });
  }

  private processWeldProcessResults(res: WeldProcess[]): WeldProcess[] {
    res.sort((a, b) => (a.weldProcessName.toLocaleLowerCase() < b.weldProcessName.toLocaleLowerCase() ? -1 : 1));
    res.forEach(weldProcess => {
      weldProcess['status'] = weldProcess.active ? 'Active' : 'Inactive';
      weldProcess.createdDateTime = `${moment(weldProcess.createdDateTime).format('MMMM DD, YYYY')}`;
      weldProcess.updatedDateTime = weldProcess.updatedDateTime
        ? `${moment(weldProcess.updatedDateTime).format('MMMM DD, YYYY')}`
        : null;
    });

    return res;
  }

  public AddorUpdateWeldProcessAsync(weldProcess: WeldProcess, existing: boolean): Promise<string> {
    if (existing) {
      weldProcess.updatedBy = this._srvUserInfo.userName;
      weldProcess.updatedDateTime = new Date().toJSON();
    } else {
      weldProcess.active = true;
      weldProcess.createdBy = this._srvUserInfo.userName;
      weldProcess.createdDateTime = new Date().toJSON();
    }
    return new Promise<string>((resolve, reject) => {
      try {
        const weldProcessObservable = existing
          ? this._srvWeldProcess.UpdateWeldProcessAsync(weldProcess)
          : this._srvWeldProcess.AddWeldProcessAsync(weldProcess);

        weldProcessObservable
          .pipe(
            catchError(err => {
              return of(err.error.Message);
            })
          )
          .subscribe(res => {
            resolve(res.toString());
          });
      } catch (err) {
        reject(Error(err));
      }
    });
  }
}
