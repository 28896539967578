import { Component, OnDestroy, OnInit } from '@angular/core';
import * as moment from 'moment';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { QualificationLog, TableHeaders } from '../../../core/models';
import { QualificationLogTable } from '../../../data/models';
import {
  DropdownService,
  ExportToExcelService,
  LoadingIndicatorService,
  QualificationLogService,
  WeldCertificationService,
  WeldProcessService
} from '../../../data/services';
import { NotificationToast, NotificationService } from '../../shared/notification';

export interface IRefinery {
  id: number;
  name: string;
}

@Component({
  selector: 'app-weld-certification',
  templateUrl: './weld-certification.component.html',
  styleUrls: ['./weld-certification.component.scss'],
  providers: [MessageService, QualificationLogService]
})
export class WeldCertificationComponent implements OnInit, OnDestroy {
  //#region 'Variables'
  public readonly PERMISSIONS = JSON.parse(localStorage.getItem('user'));
  public refineryId: number;
  public refineryname: string;
  public tableColumns: any[] = [];
  public tableData: any[] = [];
  public processes: any[] = [];

  //qualification log table variables
  public qualificationLogTableData: QualificationLogTable[];
  public displayQualificationLogs: boolean = false;
  public addNewLog: boolean = false;
  public selectedWelder: string;
  public newLog = new QualificationLog();

  //? Subscriptions
  private SUBS$ = new Subscription();
  //#endregion

  //#region 'Angular Life Cycle'
  constructor(
    public _srvDropDown: DropdownService,
    public _srvWCS: WeldCertificationService,
    public _srvQLS: QualificationLogService,
    private _notify: MessageService,
    private _loader: LoadingIndicatorService,
    private _notifSrv: NotificationService,
    private _expExcel: ExportToExcelService,
    private _weldProcessSrv: WeldProcessService
  ) {}

  ngOnInit(): void {
    this._loader.show();
    setTimeout(() => {
      this.getDropdownsData();
      this.loadRefineries();
      this.loadWeldProcesses();
    }, 1000);
  }

  ngOnDestroy(): void {
    this.SUBS$.unsubscribe();
  }
  //#endregion

  //#region 'Load'
  public loadRefineries(): void {
    this.SUBS$.add(
      this._srvWCS.loadRefineries().subscribe(dto => {
        this.refineryname = dto[0].refineryName;
        this.refineryId = dto[0].refineryId;
        this._srvWCS.loadTimes(this.refineryId);
        this.loadWeldCertData();
      })
    );
  }

  private loadWeldProcesses(): void {
    this.SUBS$.add(
      this._srvWCS.loadWeldProcesses().subscribe(dto => {
        this.processes = dto;
      })
    );
  }

  public loadWeldCertData(): void {
    this.SUBS$.add(
      this._srvWCS.loadWeldCertData(this.refineryId).subscribe(dto => {
        this.processWeldCertData(dto);
      })
    );
  }

  private getDropdownsData() {
    if (this._srvDropDown.userRefinery) {
      this._srvDropDown.GetMaterials();
      this._srvDropDown.GetMaterialForms();
      this._srvDropDown.GetWeldProcesses();
      this._srvDropDown.GetFillerMat();
      this._srvDropDown.GetWPS();
      this._srvDropDown.GetStampAllocations();
      this._srvDropDown.GetMaterial();
      this._srvDropDown.GetSchedule();
      this._srvDropDown.GetSize();
      this._srvDropDown.GetQAType();
    } else {
      this._srvDropDown.setRefineryId();
      setTimeout(() => {
        this.getDropdownsData();
      }, 1000);
    }
  }

  //#endregion

  //#region 'General Methods'
  private processWeldCertData(data: any): void {
    //? Clear arrays
    const DAYS = this._srvWCS.DaysUntilExpired;
    this.tableData = [];
    this.tableColumns = [];
    let headers: TableHeaders[] = JSON.parse(localStorage.getItem('tableHeaders'));
    let columns = this.loadHeaders(headers) as any[];
    this.tableColumns.push(...columns);

    //? Define a color number for each class
    const COLORCLASS = { 1: 'green', 2: 'yellow', 3: 'orange', 4: 'red' };
    //? set an array of all the headers of the table
    this.processes.forEach((el: any) => {
      this.tableColumns.push({ field: el.weldProcessName, header: el.weldProcessName, excelWidth: 20 });
    });

    //? set the data to show
    data.forEach((el: any) => {
      //? create a base object
      let obj = {
        welderid: el.welderid,
        welder: el.weldername,
        icon: '',
        refinery: el.refineryId,
        stamp: el.stampNumber,
        status: el.status,
        welderORD: el.weldername,
        stampORD: el.stampNumber,
        statusORD: el.status
      };

      this.processes.forEach(pel => {
        const dt: any[] = el.welderweldprocesses.filter((wp: any) => wp.weldProcessId === pel.weldProcessId);

        //? validate if the process exist, if not it will fill with no data.
        if (dt.length > 0) {
          obj[`${pel.weldProcessName}`] = moment(dt[0].datewelded).add(DAYS, 'days').format('MMMM DD, YYYY');
          obj[`${pel.weldProcessName}ORD`] = moment(dt[0].datewelded).add(DAYS, 'days');
          obj[`${pel.weldProcessName}Color`] = COLORCLASS[dt[0].colorcode];
        } else {
          obj[`${pel.weldProcessName}`] = '';
          obj[`${pel.weldProcessName}ORD`] = '';
          obj[`${pel.weldProcessName}Color`] = '';
        }
      });
      this.tableData.push(obj);
    });

    // go thru tableData, scan "welder", and get the max length of the string
    let maxWelderLength = 0;
    this.tableData.forEach(row => {
      if (row.welder.length > maxWelderLength) {
        maxWelderLength = row.welder.length;
      }
    });

    // basically, the width of the column will be the length of the longest welder name + a coefficient
    const WIDTH_COEFFICIENT = 5;
    this.tableColumns.forEach(col => {
      col.excelWidth = maxWelderLength + WIDTH_COEFFICIENT;
    });

    //? Order data by welder
    this.tableData.sort((a, b) => a.welder.localeCompare(b.welder));
    this._loader.hide();
  }

  //#region 'Load Default Table Headers'
  private loadHeaders(headers: TableHeaders[]) {
    let tableHeaders = [];
    let filteredResponse = headers.filter(
      x => x.componentName == 'weld-certification' && x.arrayName == 'tableColumns'
    );
    for (let i = 0; i < filteredResponse.length; i++) {
      tableHeaders.push({
        field: filteredResponse[i].field,
        header: filteredResponse[i].header,
        excelWidth: 0
      });
    }

    return tableHeaders;
  }
  //#endregion

  public loadQualificationLogs(refineryId: number, stampNumber: string, welder: string) {
    this._loader.show();
    let tempName = `${welder} (${stampNumber})`;
    this.selectedWelder = tempName;

    this.loadQualificationLogTable(refineryId, stampNumber);
  }

  private async loadQualificationLogTable(refineryId: number, stampNumber: string) {
    this.addNewLog = false;
    this.qualificationLogTableData = await this._srvQLS.loadQualificationLogs(refineryId, stampNumber);
    setTimeout(() => {
      this._loader.hide();
      this.displayQualificationLogs = true;
    }, 200);
  }

  public newLogOpen() {
    this.displayQualificationLogs = true;
    this.addNewLog = true;
  }

  public closeQualificationLogs() {
    this.displayQualificationLogs = false;
    this.addNewLog = false;
  }

  public logSuccessOrFail(success: boolean) {
    if (success) {
      this.displayQualificationLogs = false;
      this._loader.hide();
      this.showNotification(new NotificationToast('success', 'Log successfully submitted', '', 'success'));
      this.loadWeldCertData();
    } else {
      this.showNotification(new NotificationToast('error', 'Error sending log', '', 'error', 6000));
      this._loader.hide();
    }
  }
  //#endregion 'General Methods'

  //#region 'Notification'
  private showNotification(msg: NotificationToast) {
    this._notifSrv.sendNotification(msg);
  }
  //#endregion 'Notification'

  //#region 'Export to excel'
  public async exportToExcel() {
    let tempCols = this.tableColumns.filter(x => x.field != 'icon');

    // Getting weld processes dynamically
    try {
      const weldProcesses = await this._weldProcessSrv.GetWeldProcessesAsync(true);
      weldProcesses.forEach(weldProcess => {
        const colorField = weldProcess.weldProcessName + 'Color';
        tempCols.push({
          field: colorField,
          header: colorField,
          excelWidth: colorField.length + 5
        });
      });

      let tempProcesses = [];
      this.processes.forEach(x => {
        tempProcesses.push(x.weldProcessName);
      });

      // Exporting to Excel with dynamic columns
      this._expExcel.CertificationTableToExcel(tempCols, this.tableData, tempProcesses);
    } catch (error) {
      console.error('Error exporting to Excel:', error);
    }
  }
  //#endregion 'Export to excel'
}
