<form [formGroup]="processForm" id="processForm">
  <div formArrayName="processes">
    <div *ngFor="let process of getProcesses().controls; index as i">
      <div [formGroupName]="i" class="row">
        <!-- #region Welder Stamps -->
        <div class="col-3" *ngIf="showAcceptReject == 0">
          <label>Welder Id <span style="color: red">*</span></label>
          <p-dropdown
            optionValue="id"
            optionLabel="name"
            formControlName="stampid"
            placeholder="Select a Welder"
            [options]="_srvDropDown.stampAllocation.items"
            appendTo="body"
            (onChange)="enableDisableWelders()"
            [filter]="true"
          ></p-dropdown>
        </div>
        <!-- #endregion Welder Stamp -->

        <!-- #region Processes -->
        <div class="col-6" *ngIf="showAcceptReject == 0">
          <label>Processes <span style="color: red">*</span></label>
          <p-multiSelect
            optionValue="id"
            optionLabel="name"
            formControlName="processids"
            placeholder="Select a Process"
            [options]="_srvDropDown.WeldProcesses.items"
            (onChange)="sendData()"
            appendTo="body"
          ></p-multiSelect>
        </div>
        <!-- #endregion Processes -->

        <!-- #region Accept/Reject -->
        <div class="col-3" *ngIf="showAcceptReject == 1">
          <label>Accept/Reject </label>
          <p-dropdown
            name="rtacceptedRejected"
            optionValue="value"
            optionLabel="label"
            formControlName="rtacceptedRejected"
            placeholder="Select Accept/Reject"
            [options]="acceptedRejectedDTO"
            (onChange)="sendData()"
            appendTo="body"
          ></p-dropdown>
        </div>
        <!-- #endregion -->
      </div>
    </div>
  </div>
</form>
