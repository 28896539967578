import { Injectable } from '@angular/core';

@Injectable()
export class UserInfoService {
  private _userName: string;
  public get userName(): string {
    return this._userName;
  }

  private _userId: string;
  public get userId(): string {
    return this._userId;
  }

  private _refineryId: string;
  public get refineryId(): string {
    return this._refineryId;
  }

  private _refineryName: string;
  public get refineryName(): string {
    return this._refineryName;
  }

  private _roleId: string;
  public get roleId(): string {
    return this._roleId;
  }

  private _roleName: string;
  public get roleName(): string {
    return this._roleName;
  }

  private _permissions: number[];
  public get permissions(): number[] {
    return this._permissions;
  }

  // private readonly _srvRole: RoleApiService
  constructor() {
    this.loadUser();
  }

  public loadUser(): void {
    const USER = JSON.parse(localStorage.getItem('user'));
    if (USER) {
      this._userId = USER.userId;
      this._userName = USER.userName;
      this._refineryId = USER.refineryId;
      this._refineryName = USER.refineryName;
      this._roleId = USER.roleId;
      this._roleName = USER.roleName;
      this._permissions = USER.permissions;
    }
  }
}
