import { Base } from './Base.model';

export class RTPercentage extends Base {
  constructor() {
    super();
  }

  public rtpercentageId: number;
  public rtPercentage: number;
  public refineryId: number;
}
