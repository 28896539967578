import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { QualificationLogApiService } from 'src/app/core/api';
import { QualificationLog, WeldLogFiles, WeldLogFillerMaterial, WeldLogStampProcess } from 'src/app/core/models';
import { QualificationLogTable } from '../models/qualification-log-table.model';
import { UserInfoService } from './user-info.service';

@Injectable()
export class QualificationLogService {
  private qualificationLogTable: QualificationLogTable[] = [];

  constructor(private _srvQualLogApi: QualificationLogApiService, private _srvUserInfo: UserInfoService) {}

  //#region 'CRUD Methods'
  public loadQualificationLogs(refineryId: number, stampNumber: string): Promise<QualificationLogTable[]> {
    return new Promise((resolve, reject) => {
      try {
        this.qualificationLogTable = [];
        this._srvQualLogApi.GetQualificationLogAsync(refineryId, stampNumber).subscribe(res => {
          for (const dt of res) {
            dt.weldLogStampProcesses.sort((a, b) => (a.rowSequence < b.rowSequence ? -1 : 1));
            this.qualificationLogTable.push(new QualificationLogTable(dt));
          }
          resolve(this.qualificationLogTable);
        });
      } catch (err) {
        reject(Error(err));
      }
    });
  }

  public GetQualificationLogFileByIdAsync(weldLogFileId: number): Promise<WeldLogFiles[]> {
    return new Promise((resolve, reject) => {
      try {
        this._srvQualLogApi.GetQualificationLogFileByIdAsync(weldLogFileId).subscribe(res => {
          resolve(res);
        });
      } catch (err) {
        reject(Error(err));
      }
    });
  }

  public AddorUpdateWeldLog(
    existing: boolean,
    log: QualificationLog,
    stampProcesses: any[],
    selectedStampId: number,
    fileString: string,
    file: File[]
  ): Promise<boolean> {
    this.setupLogVals(log, existing);
    log.logEntryStatus = true;
    log = this.processFile(file, fileString, existing, log);
    log = this.processStamp(stampProcesses, log, existing, selectedStampId);
    return new Promise((resolve, reject) => {
      try {
        if (existing) {
          this._srvQualLogApi.UpdateQualificationLogAsync(log).subscribe(res => {
            resolve(res);
          });
        } else {
          log.refineryId = Number(this._srvUserInfo.refineryId);
          log.createdBy = this._srvUserInfo.userId;
          log.createdDateTime = new Date().toJSON();
          this._srvQualLogApi.AddQualificationLogAsync(log).subscribe(res => {
            resolve(res);
          });
        }
      } catch (err) {
        reject(Error(err));
      }
    });
  }

  public DeleteQualificationLog(log: QualificationLog, deletionReason: string): Promise<boolean> {
    log.reasonForDeletion = deletionReason;
    log.active = false;
    log.updatedBy = this._srvUserInfo.userId;
    log.updatedDateTime = new Date().toJSON();
    log.createdDateTime = new Date().toJSON();

    return new Promise((resolve, reject) => {
      this._srvQualLogApi.UpdateQualificationLogAsync(log).subscribe({
        next: res => {
          resolve(res);
        },
        error: err => {
          reject(Error(err));
        }
      });
    });
  }
  //#endregion 'CRUD Methods'

  //#region 'Process/Format Data'
  private getDateFormatted(value: string): string {
    return `${value}T00:00:00.000Z`;
  }

  private setupLogVals(log: QualificationLog, existing: boolean): void {
    log.active = true;
    log.weldLogId = existing ? log.weldLogId : 0;
    log.weldType = 3;
    log.refineryId = log.refineryId ? log.refineryId : Number(this._srvUserInfo.refineryId);
    log.dateWelded = log.dateWelded ? this.getDateFormatted(log.dateWelded) : '';
    log.weldLogStampProcesses = new Array<WeldLogStampProcess>();
    log.weldLogFillerMaterials = new Array<WeldLogFillerMaterial>();
    log.weldLogFiles = new Array<WeldLogFiles>();
    log.updatedBy = this._srvUserInfo.userId;
    log.updatedDateTime = this.getDateFormatted(moment(new Date()).format('YYYY-MM-DD'));
    log.createdBy = existing ? log.original.createdBy : this._srvUserInfo.userId;
    log.createdDateTime = existing
      ? this.getDateFormatted(moment(log.original.createdDateTime).format('YYYY-MM-DD'))
      : this.getDateFormatted(moment(new Date()).format('YYYY-MM-DD'));
  }

  private processStamp(stampProcesses: any[], log: any, existing: boolean, selectedStampId: number): QualificationLog {
    for (let i = 0; i < stampProcesses.length; i++) {
      const DTO = new WeldLogStampProcess();
      log = this.processFillerMaterial(
        stampProcesses[i].fillerMatNeeded,
        log,
        existing,
        i + 1,
        stampProcesses[i].weldMetalThickness,
        stampProcesses[i].qualifiedThickness
      );
      DTO.weldLogId = existing ? log.weldLogId : 0;
      DTO.stampAllocationId = selectedStampId;
      DTO.weldProcessId = stampProcesses[i].processid;
      DTO.rowSequence = i + 1;
      log.weldLogStampProcesses.push(this.fillBase(DTO));
    }
    return log;
  }

  private processFillerMaterial(
    fillermats: number[],
    log: QualificationLog,
    existing: boolean,
    row: number,
    thickness: string,
    qualifiedThickness: string
  ): any {
    for (let mat of fillermats) {
      const DTO = new WeldLogFillerMaterial();
      DTO.weldLogId = existing ? log.weldLogId : 0;
      DTO.weldLogFillerMaterialId = 0;
      DTO.fillerMaterialName = '';
      DTO.fillerMaterialId = mat;
      DTO.rowSequence = row;
      DTO.weldMetalThickness = thickness;
      DTO.qualifiedThickness = qualifiedThickness;
      log.weldLogFillerMaterials.push(this.fillBase(DTO));
    }
    return log;
  }

  private processFile(file: any[], fileString: string, existing: boolean, log: QualificationLog): any {
    const DTO = new WeldLogFiles();
    DTO.weldLogId = existing ? log.weldLogId : 0;
    DTO.fileName = file[0].name;
    DTO.fileContent = fileString;
    log.weldLogFiles.push(this.fillBase(DTO));
    return log;
  }

  private fillBase(DTO: any): any {
    const USER = this._srvUserInfo.userId;
    const DATE = this.getDateFormatted(moment(new Date()).format('YYYY-MM-DD'));

    DTO.createdBy = USER;
    DTO.createdDateTime = DATE;
    DTO.updatedBy = USER;
    DTO.updatedDateTime = DATE;
    DTO.active = true;
    DTO.reasonForDeletion = '';
    DTO.fillerMaterialIssuedDate = DATE;

    return DTO;
  }
  //#endregion 'Process/Format Data'
}
