import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Division } from '../models';
import { BaseApiService } from '../shared/base-api.service';

@Injectable({
  providedIn: 'root'
})
export class DivisionApiService extends BaseApiService<Division> {
  //#region 'Angular Life Cycle'
  constructor(protected _http: HttpClient) {
    super(_http, 'division');
  }
  //#endregion

  //#region 'Get'
  public GetDivisionsAsync(): Observable<Division[]> {
    return this.getAll('/GetDivisionsAsync');
  }

  public GetDivisionsAsyncById(divisionid: number): Observable<Division> {
    const options = { divisionid };
    return this.getById(options, '/GetDivisionsAsync');
  }

  public GetActiveDivisionsByRefineryAsync(refineryid: number): Observable<Division[]> {
    const options = { refineryid };
    return this.getAllById(options, '/GetDivisionsByRefineryAsync');
  }

  public GetAllDivisionsByRefineryAsync(refineryid: number, active: boolean): Observable<Division[]> {
    const options = { refineryid, active };
    return this.getAllById(options, '/GetDivisionsByRefineryAsync');
  }
  //#endregion

  //#region 'Create'
  public AddDivisionAsync(division: Division): Observable<boolean> {
    return this.createAny(division, '/AddDivisionAsync');
  }
  //#endregion

  //#region 'Update'
  public UpdateDivisionAsync(division: Division): Observable<boolean> {
    return this.createAny(division, '/UpdateDivisionAsync');
  }
  //#endregion

  //#region 'Delete'
  //#endregion

  //#region 'Other'
  //#endregion
}
