import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { WPS } from '../models';
import { BaseApiService } from '../shared/base-api.service';

@Injectable({
  providedIn: 'root'
})
export class WPSApiService extends BaseApiService<WPS> {
  //#region 'Angular Life Cycle'
  constructor(protected _http: HttpClient) {
    super(_http, 'wps');
  }
  //#endregion

  //#region 'Get'
  public GetWPsAsync(): Observable<WPS[]> {
    return this.getAll('/GetWPsAsync');
  }

  public GetWPsAsyncById(wpsid: number): Observable<WPS> {
    const options = { wpsid };
    return this.getById(options, '/GetWPsAsync');
  }

  public GetActiveWPsByRefineryAsync(refineryid: number): Observable<WPS[]> {
    const options = { refineryid };
    return this.getAllById(options, '/GetWPsByRefineryAsync');
  }

  public GetAllWPsByRefineryAsync(refineryid: number, active: boolean): Observable<WPS[]> {
    const options = { refineryid, active };
    return this.getAllById(options, '/GetWPsByRefineryAsync');
  }
  //#endregion

  //#region 'Create'
  public UpdateWPsAsync(wps: WPS): Observable<boolean> {
    return this.createAny(wps, '/UpdateWPsAsync');
  }
  //#endregion

  //#region 'Update'
  public AddWPsAsync(wps: WPS): Observable<boolean> {
    return this.createAny(wps, '/AddWPsAsync');
  }
  //#endregion

  //#region 'Delete'
  //#endregion

  //#region 'Other'
  //#endregion
}
