import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
  DivisionService,
  DropdownService,
  FillerMaterialService,
  LoadingIndicatorService,
  MaterialService,
  RefineryService,
  SizeService,
  UnitService,
  WelderService,
  WeldGroupService,
  WeldManagerUserService,
  WPSService,
  KeypressValidationService,
  ContractCompanyService
} from 'src/app/data/services';
import { map, Observable, Subject, switchMap, timer } from 'rxjs';
import { AadEmployeeApiService } from 'src/app/core/api';
import { IAadEmployee } from 'src/app/core/models/IAadEmployee';
import { Router } from '@angular/router';
import { NotificationService, NotificationToast } from 'src/app/layout/shared/notification';
import {
  Division,
  FillerMaterial,
  Material,
  Refinery,
  Size,
  Unit,
  WeldGroup,
  WPS,
  RTPercentage,
  WeldProcess,
  Role,
  ContractCompany
} from 'src/app/core/models';
import { RTPercentageService } from 'src/app/data/services/rtpercentage.service';
import { WeldProcessService } from 'src/app/data/services/weldprocess.service';
import { RoleService } from 'src/app/data/services/role.service';
import { FileUpload } from 'primeng/fileupload';
import { ContractWelderPhotoComponent } from './contract-welder-photo/contract-welder-photo.component';

@Component({
  selector: 'app-add-edit-admin',
  templateUrl: './add-edit-admin.component.html',
  styleUrls: ['./add-edit-admin.component.scss']
})
export class AddEditAdminComponent implements OnInit {
  //#region 'Input, Output, ViewChild'
  @ViewChild('fileUploader', { read: FileUpload, static: false }) fileUploader: FileUpload;
  @ViewChild(ContractWelderPhotoComponent) photoComponent: ContractWelderPhotoComponent;

  @Input() public existing: boolean;
  @Input() public dataType: any; //object to identify dataset dynamically including screen titles, labels, and model property names
  @Input() public selectedRow: any;
  @Output() public onCancel: EventEmitter<any> = new EventEmitter<any>();
  @Output() public onSuccess: EventEmitter<string> = new EventEmitter<string>();
  //#endregion 'Input, Output, ViewChild'

  //#region 'Variables'
  public adminForm: UntypedFormGroup;
  public addUpdateButtonLabel: string; //dynamic label for Add or Update button
  public aadEmployeeValidation = false; //used for custom form validation styling on AAD lookup
  public originalValue: any[] = []; //used to hold the original control value in order to determine if submit button should be enabled (only enable when value is changed)
  private emailSettingsControls = [
    'emailsListArray',
    'certValidityDays',
    'reminderEmail1',
    'reminderEmail2',
    'reminderEmail3'
  ];
  private validEmailRegEx = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  //contract Welder
  public displayPhotoDialog: boolean = false;
  public photoString: string = '';
  public isContractWelder = false; //form flag for adding new chevron/contract welder
  private requiredContractWelderFields = ['firstName', 'lastName', 'contractCompanyId', 'contractorStampNumber'];

  //Microsoft Graph api
  private _aadSearchSource: Subject<string> = new Subject<string>();
  public selectedEmployee: IAadEmployee = null; //used to store the data from Microsoft Graph api call

  //azure active directory lookup
  public suggestions$: Observable<IAadEmployee[]> = this._aadSearchSource.pipe(
    switchMap(query => {
      return this._srvAadEmplApi.getParticipants(query).pipe(
        map(users =>
          users.map(user => {
            const fullName = `${user.firstName} ${user.lastName}`;
            return { ...user, fullName };
          })
        )
      );
    })
  );
  //#endregion 'Variables'

  //#region 'Angular Life Cycle'
  constructor(
    public router: Router,
    private fb: UntypedFormBuilder,
    public _srvDropDown: DropdownService,
    private _loader: LoadingIndicatorService,
    private _notifSrv: NotificationService,
    private _srvAadEmplApi: AadEmployeeApiService,
    private _srvUsers: WeldManagerUserService,
    private _srvWelders: WelderService,
    private _srvDivision: DivisionService,
    private _srvFillMat: FillerMaterialService,
    private _srvMaterial: MaterialService,
    private _srvRefinery: RefineryService,
    private _srvSize: SizeService,
    private _srvUnit: UnitService,
    private _srvGroup: WeldGroupService,
    private _srvWps: WPSService,
    private _srvRTPercentage: RTPercentageService,
    private _srvWeldProcess: WeldProcessService,
    public _srvRoleService: RoleService,
    public keyValidation: KeypressValidationService,
    public _srvContractCompany: ContractCompanyService
  ) {
    /*empty form is created so we can dynamically create controls
        --> use the addControl() method to create controls names dynamically */
    this.adminForm = this.fb.group({
      emailValidator: ['', Validators.pattern(this.validEmailRegEx)],
      rolePermissionIdArray: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.setupForm();
  }
  //#endregion 'Angular Life Cycle'

  //#region 'Load'
  private setupForm(): void {
    if (this.existing) {
      this.isContractWelder = this.selectedRow['isContractWelder'];
      this.fillForm();
    } else {
      this.newForm();
    }
  }
  //#endregion 'Load'

  //#region 'General Mehtods'
  public searchEmployee(query: string): void {
    this._aadSearchSource.next(query);
  }

  public cancel(): void {
    this.onCancel.emit();
  }

  public onSuccessOrFail(success: string): void {
    this.onSuccess.emit(success);
  }

  private showNotification(notificationToast: NotificationToast) {
    this._notifSrv.sendNotification(notificationToast);
  }

  public isSubmitButtonDisabled(): boolean {
    if (
      this.router.url !== '/admin/email' &&
      this.existing &&
      this.originalValue[this.dataType.formControlName] == this.adminForm.value[this.dataType.formControlName] &&
      this.originalValue[this.dataType.formControlName2] == this.adminForm.value[this.dataType.formControlName2] &&
      this.originalValue['divisionId'] == this.adminForm.value['divisionId'] &&
      this.originalValue['rolePermissionIdArray'] == this.adminForm.value['rolePermissionIdArray']
    ) {
      return true;
    } else if (
      this.router.url == '/admin/email' &&
      JSON.stringify(this.originalValue['emailsListArray']) ===
        JSON.stringify(this.adminForm.value['emailsListArray']) &&
      this.originalValue['certValidityDays'] == this.adminForm.value['certValidityDays'] &&
      this.originalValue['reminderEmail1'] == this.adminForm.value['reminderEmail1'] &&
      this.originalValue['reminderEmail2'] == this.adminForm.value['reminderEmail2'] &&
      this.originalValue['reminderEmail3'] == this.adminForm.value['reminderEmail3']
    ) {
      return true;
    } else {
      return false;
    }
  }

  public clearEmailArray() {
    this.adminForm.patchValue({
      emailsListArray: []
    });
  }

  appendPercentageSign(event: any) {
    let inputValue = event.target.value;
    if (inputValue) {
      inputValue = inputValue.replace(/%/g, '');
      event.target.value = inputValue + '%';
    }
  }
  //#endregion 'General Mehtods'

  //#region 'CRUD'
  //using switch case here bc there will be cases added with additional controls...
  private newForm() {
    this.addUpdateButtonLabel = 'Add';
    switch (this.router.url) {
      case '/admin/division':
      case '/admin/unit':
        this.adminForm.addControl(`${this.dataType.formControlName}`, this.fb.control(''));
        this.originalValue[this.dataType.formControlName] = this.adminForm.value[this.dataType.formControlName];
        this.adminForm.addControl(`${this.dataType.formControlName2}`, this.fb.control(''));
        this.originalValue[this.dataType.formControlName2] = this.adminForm.value[this.dataType.formControlName2];

        if (this.router.url == '/admin/unit') {
          this.adminForm.addControl('divisionId', this.fb.control(''));
          this.originalValue['divisionId'] = this.adminForm.value['divisionId'];
        }
        break;

      case '/admin/welder':
        this.adminForm.addControl('firstName', this.fb.control(''));
        this.adminForm.addControl('lastName', this.fb.control(''));
        this.adminForm.addControl('emailId', this.fb.control(''));
        this.adminForm.addControl('contractCompanyId', this.fb.control(0));
        this.adminForm.addControl('contractorStampNumber', this.fb.control(''));
        this.adminForm.addControl('stampNumber', this.fb.control(''));
        this.adminForm.addControl('photo', this.fb.control(''));
        break;

      case '/admin/rolepermission':
        this.adminForm.addControl(`${this.dataType.formControlName}`, this.fb.control(''));
        this.originalValue[this.dataType.formControlName] = this.adminForm.value[this.dataType.formControlName];

        if (this.router.url == '/admin/rolepermission') {
          this.adminForm.addControl('rolePermissionIdArray', this.fb.control(''));
          this.originalValue['rolePermissionIdArray'] = this.adminForm.value['rolePermissionIdArray'];
        }
        break;

      case '/admin/rtpercentage':
        this.adminForm.addControl('rtPercentage', this.fb.control(null));
        this.originalValue['rtPercentage'] = this.adminForm.value['rtPercentage'];
        break;

      default:
        this.adminForm.addControl(`${this.dataType.formControlName}`, this.fb.control(''));
        this.originalValue[this.dataType.formControlName] = this.adminForm.value[this.dataType.formControlName];
        break;
    }
  }

  //using switch case here bc there will be cases added with additional controls...
  private fillForm() {
    this.addUpdateButtonLabel = 'Update';
    switch (this.router.url) {
      case '/admin/division':
      case '/admin/unit':
        this.adminForm.addControl(
          `${this.dataType.formControlName}`,
          this.fb.control(this.selectedRow[`${this.dataType.formControlName}`])
        );
        this.originalValue[this.dataType.formControlName] = this.adminForm.value[this.dataType.formControlName];

        this.adminForm.addControl(
          `${this.dataType.formControlName2}`,
          this.fb.control(this.selectedRow[`${this.dataType.formControlName2}`])
        );
        this.originalValue[this.dataType.formControlName2] = this.adminForm.value[this.dataType.formControlName2];

        if (this.router.url == '/admin/unit') {
          this.adminForm.addControl('divisionId', this.fb.control(this.selectedRow['divisionId']));
          this.originalValue['divisionId'] = this.adminForm.value['divisionId'];
        }
        break;

      case '/admin/email':
        this.emailSettingsControls.forEach(control => {
          this.adminForm.addControl(`${control}`, this.fb.control(this.selectedRow[control]));
          this.originalValue[control] = this.adminForm.value[control];
        });
        break;

      case '/admin/rolepermission':
        this.adminForm.addControl(
          `${this.dataType.formControlName}`,
          this.fb.control(this.selectedRow[`${this.dataType.formControlName}`])
        );
        this.originalValue[this.dataType.formControlName] = this.adminForm.value[this.dataType.formControlName];
        this.adminForm.addControl('rolePermissionIdArray', this.fb.control(this.selectedRow['rolePermissionIdArray']));
        this.adminForm.get('rolePermissionIdArray').setValue(this.selectedRow['rolePermissionIdArray']);
        this.originalValue['rolePermissionIdArray'] = this.adminForm.value['rolePermissionIdArray'];
        break;

      default:
        this.adminForm.addControl(
          `${this.dataType.formControlName}`,
          this.fb.control(this.selectedRow[`${this.dataType.formControlName}`])
        );
        this.originalValue[this.dataType.formControlName] = this.adminForm.value[this.dataType.formControlName];

        if (this.router.url == '/admin/welder') {
          // Contract Company Dropdown
          this.adminForm.addControl('contractCompanyID', this.fb.control(this.selectedRow['contractCompanyID']));
          this.originalValue['contractCompanyID'] = this.adminForm.value['contractCompanyID'];

          // Contractor First Name and Last Name
          this.adminForm.addControl('firstName', this.fb.control(this.selectedRow['firstName']));
          this.originalValue['firstName'] = this.adminForm.value['firstName'];

          this.adminForm.addControl('lastName', this.fb.control(this.selectedRow['lastName']));
          this.originalValue['lastName'] = this.adminForm.value['lastName'];
        }
        break;
    }
  }

  public submitForm() {
    this._loader.show();
    let dto = {};
    if (this.verifyValidForm()) {
      if (this.existing) {
        dto = { ...this.selectedRow };
      } else if (this.router.url == '/admin/user' || (this.router.url == '/admin/welder' && !this.isContractWelder)) {
        dto = { ...this.selectedEmployee };
      }
      if (this.router.url == '/admin/welder' && !this.isContractWelder) {
        dto['stampNumber'] = this.adminForm.value['stampNumber'];
      } else {
        Object.assign(dto, this.adminForm.value);
      }
      switch (this.router.url) {
        case '/admin/user':
          this._srvUsers.AddorUpdateUserAsync(dto, this.existing).then(res => this.onSuccessOrFail(res));
          break;

        case '/admin/welder':
          if (!this.existing) {
            dto['isContractWelder'] = this.isContractWelder;
          }
          dto['photo'] = this.photoString;
          this._srvWelders.AddorUpdateWelderAsync(dto, this.existing).then(res => this.onSuccessOrFail(res));
          break;

        case '/admin/filler-materials':
          let fillerMat = new FillerMaterial();
          Object.assign(fillerMat, dto);
          Object.assign(fillerMat, this.adminForm.value);
          this._srvFillMat
            .AddorUpdateFillerMaterialAsync(fillerMat, this.existing)
            .then(res => this.onSuccessOrFail(res));
          break;

        case '/admin/materials':
          let material = new Material();
          Object.assign(material, dto);
          Object.assign(material, this.adminForm.value);
          this._srvMaterial.AddorUpdateMaterialAsync(material, this.existing).then(res => this.onSuccessOrFail(res));
          break;

        case '/admin/pipe-sizes':
          let size = new Size();
          Object.assign(size, dto);
          Object.assign(size, this.adminForm.value);
          this._srvSize.AddorUpdaterSizeAsync(size, this.existing).then(res => this.onSuccessOrFail(res));
          break;

        case '/admin/wps':
          let wps = new WPS();
          Object.assign(wps, dto);
          Object.assign(wps, this.adminForm.value);
          this._srvWps.AddorUpdateWPSAsync(wps, this.existing).then(res => this.onSuccessOrFail(res));
          break;

        case '/admin/switchsite':
          this._srvUsers.AddorUpdateUserAsync(dto, this.existing).then(res => this.onSuccessOrFail(res));
          this.clearCache();
          break;

        case '/admin/email':
          let refinery = new Refinery();
          Object.assign(refinery, dto);
          Object.assign(refinery, this.adminForm.value);
          refinery.reminderEmailCopyTo = this.adminForm.value.emailsListArray
            ? this.adminForm.value.emailsListArray.join(',')
            : '';
          this._srvRefinery.UpdateRefineryEmailSettingsAsync(refinery).then(res => this.onSuccessOrFail(res));
          break;

        case '/admin/division':
          let division = new Division();
          Object.assign(division, dto);
          Object.assign(division, this.adminForm.value);
          this._srvDivision.AddorUpdateDivisionAsync(division, this.existing).then(res => this.onSuccessOrFail(res));
          break;

        case '/admin/unit':
          let unit = new Unit();
          Object.assign(unit, dto);
          Object.assign(unit, this.adminForm.value);
          this._srvUnit.AddorUpdateUnitAsync(unit, this.existing).then(res => this.onSuccessOrFail(res));
          break;

        case '/admin/group':
          let group = new WeldGroup();
          Object.assign(group, dto);
          Object.assign(group, this.adminForm.value);
          this._srvGroup.AddorUpdateWeldGroupAsync(group, this.existing).then(res => this.onSuccessOrFail(res));
          break;

        case '/admin/rtpercentage':
          let rtpercentage = new RTPercentage();
          Object.assign(rtpercentage, dto);
          Object.assign(rtpercentage, this.adminForm.value);

          this._srvRTPercentage
            .AddorUpdateRTPercentageAsync(rtpercentage, this.existing)
            .then(res => this.onSuccessOrFail(res));
          break;

        case '/admin/weldprocess':
          let process = new WeldProcess();
          Object.assign(process, dto);
          Object.assign(process, this.adminForm.value);
          this._srvWeldProcess
            .AddorUpdateWeldProcessAsync(process, this.existing)
            .then(res => this.onSuccessOrFail(res));
          break;

        case '/admin/rolepermission':
          let role = new Role();
          Object.assign(role, dto);
          Object.assign(role, this.adminForm.value);
          this._srvRoleService
            .AddorUpdateRoleAsync(role, this.existing, dto['rolePermissionIdArray'])
            .then(res => this.onSuccessOrFail(res));
          break;

        case '/admin/contract-company':
          let contractCompany = new ContractCompany();
          Object.assign(contractCompany, dto);
          Object.assign(contractCompany, this.adminForm.value);
          this._srvContractCompany
            .AddorUpdateContractCompanyAsync(contractCompany, this.existing)
            .then(res => this.onSuccessOrFail(res));
          break;

        default:
          break;
      }
    } else {
      this._loader.hide();
    }
  }
  //#endregion 'CRUD'

  //#region 'Validations'
  private verifyValidForm(): boolean {
    let flag = true;
    let minMaxPercentageFlag = true;
    this.clearErrors();

    switch (this.router.url) {
      case '/admin/user':
      case '/admin/welder':
        if (!this.existing && !this.isContractWelder) {
          if (this.selectedEmployee == null) {
            this.aadEmployeeValidation = true;
            flag = false;
          }
        }
        if (
          this.adminForm.value[this.dataType.formControlName] == null ||
          this.adminForm.value[this.dataType.formControlName] == ''
        ) {
          this.adminForm.controls[this.dataType.formControlName].setErrors({ incorrect: true });
          this.adminForm.controls[this.dataType.formControlName].markAsDirty();
          flag = false;
        }

        if (this.adminForm.value['firstName'] && this.adminForm.value['firstName'].trim() == '') {
          this.adminForm.controls['firstName'].setErrors({ incorrect: true });
          this.adminForm.controls['firstName'].markAsDirty();
          flag = false;
        }

        if (this.adminForm.value['lastName'] && this.adminForm.value['lastName'].trim() == '') {
          this.adminForm.controls['lastName'].setErrors({ incorrect: true });
          this.adminForm.controls['lastName'].markAsDirty();
          flag = false;
        }

        if (this.isContractWelder && this.addUpdateButtonLabel != 'Update') {
          this.requiredContractWelderFields.forEach(field => {
            if (this.adminForm.value[field] == null || this.adminForm.value[field] == '') {
              this.adminForm.controls[field].setErrors({ incorrect: true });
              this.adminForm.controls[field].markAsDirty();
              flag = false;
            }
          });
        }
        break;

      case '/admin/email':
        this.emailSettingsControls.forEach(control => {
          if (control === 'emailsListArray') {
            return;
          }
          if (
            this.adminForm.value[control] == null ||
            (typeof this.adminForm.value[control] !== 'number' && this.adminForm.value[control] == '')
          ) {
            this.adminForm.controls[control].setErrors({ incorrect: true });
            this.adminForm.controls[control].markAsDirty();
            flag = false;
          }
        });
        break;

      case '/admin/rolepermission':
        if (this.adminForm.controls['rolePermissionIdArray']) {
          if (
            this.adminForm.value['rolePermissionIdArray'] == null ||
            this.adminForm.value['rolePermissionIdArray'] == ''
          ) {
            this.adminForm.controls['rolePermissionIdArray'].setErrors({ incorrect: true });
            this.adminForm.controls['rolePermissionIdArray'].markAsDirty();
            flag = false;
          }
        }
        break;

      case '/admin/rtpercentage':
        if (this.adminForm.value['rtPercentage'] == null) {
          this.adminForm.controls['rtPercentage'].setErrors({ incorrect: true });
          this.adminForm.controls['rtPercentage'].markAsDirty();
          flag = false;
        }
        if (this.adminForm.value['rtPercentage'] < 0 || this.adminForm.value['rtPercentage'] > 100) {
          this.adminForm.controls['rtPercentage'].setErrors({ incorrect: true });
          this.adminForm.controls['rtPercentage'].markAsDirty();
          minMaxPercentageFlag = false;
        }
        break;

      default:
        if (
          this.adminForm.value[this.dataType.formControlName] == null ||
          this.adminForm.value[this.dataType.formControlName] == ''
        ) {
          this.adminForm.controls[this.dataType.formControlName].setErrors({ incorrect: true });
          this.adminForm.controls[this.dataType.formControlName].markAsDirty();
          flag = false;
        }
        if (this.adminForm.controls[this.dataType.formControlName2]) {
          if (
            this.adminForm.value[this.dataType.formControlName2] == null ||
            this.adminForm.value[this.dataType.formControlName2] == ''
          ) {
            this.adminForm.controls[this.dataType.formControlName2].setErrors({ incorrect: true });
            this.adminForm.controls[this.dataType.formControlName2].markAsDirty();
            flag = false;
          }
        }
        if (this.adminForm.controls['divisionId']) {
          if (this.adminForm.value['divisionId'] == null || this.adminForm.value['divisionId'] == '') {
            this.adminForm.controls['divisionId'].setErrors({ incorrect: true });
            this.adminForm.controls['divisionId'].markAsDirty();
            flag = false;
          }
        }

        break;
    }

    if (!flag) {
      this.showNotification(new NotificationToast('error', 'Form is missing required field', '', 'error', 4000));
    }
    if (!minMaxPercentageFlag) {
      this.showNotification(
        new NotificationToast('error', 'Percentage must be a number between 0 and 100', '', 'error', 4000)
      );
      flag = false;
    }
    return flag;
  }

  private clearErrors(): void {
    Object.keys(this.adminForm.controls).forEach(key => {
      this.adminForm.controls[key].setErrors({ incorrect: null });
      this.adminForm.controls[key].updateValueAndValidity();
    });
  }

  public clearEmpoyeeError() {
    this.aadEmployeeValidation = false;
  }

  public isValidEmail(event: any) {
    this.adminForm.patchValue({
      emailValidator: event.value
    });

    if (!this.adminForm.get('emailValidator').valid) {
      this.adminForm.value['emailsListArray'].pop();
    }
  }
  //#endregion 'Validations'

  //#region 'Contractor Photo'
  public openClosePhotoDialog() {
    this.displayPhotoDialog = !this.displayPhotoDialog;
  }

  public addPhoto() {
    this.openClosePhotoDialog();
    const timerObservable = timer(500);
    timerObservable.subscribe(() => {
      this.photoComponent.clearFileInput();
    });
  }

  public editPhoto() {
    this.openClosePhotoDialog();
  }

  public removeUploadImage() {
    this.photoString = '';
    this.photoComponent.removeUploadImage();
  }

  public savePhoto(fileString: any) {
    this.photoString = fileString;
    this.displayPhotoDialog = false;
  }
  //#endregion 'Contractor Photo'

  //#region 'Clear localstorage'
  public clearCache() {
    setTimeout(() => {
      localStorage.clear();
    }, 2000);
    this._loader.show();
  }
  //#endregion 'Clear localstorage'
}
