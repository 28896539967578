import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { QualificationLog } from '../../../../../core/models';
import {
  DropdownService,
  KeypressValidationService,
  LoadingIndicatorService,
  QualificationLogService
} from '../../../../../data/services';
import { NotificationToast, NotificationService } from 'src/app/layout/shared/notification';
import { FileUpload } from 'primeng/fileupload';

@Component({
  selector: 'app-add-edit-view-qualification-log',
  templateUrl: './add-edit-view-qualification-log.component.html',
  styleUrls: ['./add-edit-view-qualification-log.component.scss']
})
export class AddEditViewQualificationLogComponent implements OnInit {
  //#region 'Input, Output, ViewChild'
  @ViewChild('fileUploader', { read: FileUpload, static: false }) fileUploader: FileUpload;

  @Input() public actionQualification: number; // 0 = New, 1 = Edit, 2 = View
  @Input() public dialogHeader: string;
  @Input() public dialogSubmit: string;
  @Input() public userPermissions: any;
  @Input() public isViewEdit: boolean;
  @Input() public hideActionBtns: boolean;
  @Input() public currentLog: QualificationLog = new QualificationLog();

  @Output() public onCancel: EventEmitter<any> = new EventEmitter<any>();
  @Output() public onSuccess: EventEmitter<boolean> = new EventEmitter<boolean>();
  //#endregion

  //#region 'Variables'
  public selectedStampId: number;
  public formDisabled = false;
  public uploadedFiles: File[] = [];
  public fileString: string = '';
  public qw484File: boolean = false;

  public logForm = new UntypedFormGroup({
    stampid: new UntypedFormControl(),
    materialFromId: new UntypedFormControl(),
    materialToId: new UntypedFormControl(),
    materialFormId: new UntypedFormControl(),
    processes: new UntypedFormArray([]),
    wpsid: new UntypedFormControl(),
    dateWelded: new UntypedFormControl(),
    pdfFile: new UntypedFormControl(),
    uploadedFileName: new UntypedFormControl(),
    comments: new UntypedFormControl()
  });

  private requiredFields = [
    'stampid',
    'materialFromId',
    'materialToId',
    'materialFormId',
    'wpsid',
    'dateWelded',
    'pdfFile'
  ];

  //#endregion

  //#region 'Angular Life Cycle'
  constructor(
    public _srvDropDown: DropdownService,
    private _srvQLS: QualificationLogService,
    private _srvNotify: NotificationService,
    private _loader: LoadingIndicatorService,
    public keyValidation: KeypressValidationService
  ) {}

  ngOnInit(): void {
    this.setUpMode();
  }
  //#endregion

  //#region 'Load'
  private setUpMode(): void {
    if (this.actionQualification === 0) {
      this.newLog();
    } else if (this.actionQualification === 1) {
      this.editLog();
    } else if (this.actionQualification === 2) {
      this.viewLog();
    }
  }

  //#region 'CRUD Weld Log'
  public newLog() {
    this.addAllocationRow();
    this.enableForm();
    this.enableAllProcesses();
  }

  public editLog() {
    this.fillForm();
    this.setupAllocationsFillerMats();
    this.enableDisableProcesses();
    this.enableForm();
    this.logForm.controls['stampid'].disable();
  }

  public viewLog() {
    this.fillForm();
    this.setupAllocationsFillerMats();
    this.enableDisableProcesses();
    this.disableForm();
  }

  private disableForm() {
    this.formDisabled = true;
    this.logForm.disable();
    for (let control of this.logForm.controls['processes']['controls']) {
      control.disable();
    }
  }

  private enableForm() {
    this.formDisabled = false;
    this.logForm.enable();
    for (let control of this.logForm.controls['processes']['controls']) {
      control.enable();
    }
    this.logForm.controls['uploadedFileName'].disable();
  }

  private fillForm() {
    this.logForm.patchValue({
      stampid: this.currentLog.original.weldLogStampProcesses[0].stampAllocationId,
      materialFromId: this.currentLog.original.materialFromId,
      materialToId: this.currentLog.original.materialToId,
      materialFormId: this.currentLog.original.materialFormId,
      wpsid: this.currentLog.original.wpsid,
      dateWelded: this.currentLog.dateWelded,
      pdfFile: this.currentLog.weldLogFiles,
      uploadedFileName: this.currentLog.weldLogFiles[0].fileName,
      comments: this.currentLog.original.comments
    });
  }

  //#endregion

  //#region 'Log CRUD'
  public submitNewLog() {
    this._loader.show();
    const PCS = this.getProcesses().value;
    const file = this.logForm.value.pdfFile;
    this.selectedStampId = this.logForm.getRawValue()['stampid'];
    if (this.logForm.value['comments'] != null) {
      this.logForm.controls['comments'].setValue(this.logForm.value['comments'].trim());
    }
    Object.assign(this.currentLog, this.logForm.value);

    if (this.verifyValidLog()) {
      this._srvQLS
        .AddorUpdateWeldLog(this.isViewEdit, this.currentLog, PCS, this.selectedStampId, this.fileString, file)
        .then(res => {
          this.logSuccessOrFail(res);
        });
    } else {
      this._loader.hide();
    }
  }
  //#endregion

  //#region 'Processes'
  public getProcesses(): UntypedFormArray {
    return this.logForm.get('processes') as UntypedFormArray;
  }

  public enableAllProcesses() {
    for (let item of this._srvDropDown.WeldProcesses.items) {
      item.disabled = false;
    }
  }

  public enableDisableProcesses() {
    // dynamically enable or disable processes based on if they are already selected (so we can't have duplicates)
    for (const dt of this._srvDropDown.WeldProcesses.items) {
      for (let j = 0; j < this.getProcesses().length; j++) {
        if (dt.id === this.getProcesses().at(j).value['processid']) {
          dt.disabled = true;
          break;
        }
        dt.disabled = false;
      }
    }
  }

  private setupAllocationsFillerMats() {
    this.getProcesses().clear();
    if (this.currentLog.weldLogStampProcesses.length > 0) {
      this.currentLog.weldLogStampProcesses.forEach(el => {
        const DTO = this.currentLog.weldLogFillerMaterials.filter(x => x.rowSequence === el.rowSequence);
        let tempMetalThickness = DTO[0].weldMetalThickness;
        let tempQualifiedThickness = DTO[0].qualifiedThickness;
        let tempMat = [];
        for (const fillerMat of DTO) {
          tempMat.push(fillerMat.fillerMaterialId);
        }
        // for each stamp process in newlog
        // if stampProcesses has items
        let index = this.getProcesses()
          .getRawValue()
          .map(e => e.processid)
          .indexOf(el.weldProcessId);
        if (index == -1) {
          //new items, add it
          index++;
          const PCS = new UntypedFormGroup({
            processid: new UntypedFormControl(el.weldProcessId),
            fillerMatNeeded: new UntypedFormControl(tempMat),
            weldMetalThickness: new UntypedFormControl(tempMetalThickness),
            qualifiedThickness: new UntypedFormControl(tempQualifiedThickness)
          });
          this.getProcesses().push(PCS);
        }
      });
    } else {
      //if stampProcesses empty, add item
      this.addAllocationRow();
    }
    // this.setupFiles();
  }

  public addAllocationRow() {
    let temp = new UntypedFormGroup({
      processid: new UntypedFormControl(),
      fillerMatNeeded: new UntypedFormControl([]),
      weldMetalThickness: new UntypedFormControl(),
      qualifiedThickness: new UntypedFormControl()
    });
    this.getProcesses().push(temp);
  }

  public removeAllocationRow(index: number) {
    this.getProcesses().removeAt(index);
    this.enableDisableProcesses();
  }

  public setupFiles() {
    if (this.currentLog.weldLogFiles && this.currentLog.weldLogFiles.length > 0) {
      this.logForm.controls['pdfFile'].setValue([]);

      for (const dt of this.currentLog.weldLogFiles) {
        let temp = this.logForm.controls['pdfFile'].value;
        temp.push(dt.fileName);
        this.logForm.controls['pdfFile'].setValue(temp);
      }
    }
  }

  //#endregion

  //#region 'Validations'
  private verifyValidLog(): boolean {
    let flag = true;
    this.clearErrors();

    for (const dt of this.requiredFields) {
      if (this.logForm.getRawValue()[dt] == null || this.logForm.getRawValue()[dt] == '') {
        this.logForm.controls[dt].setErrors({ incorrect: true });
        this.logForm.controls[dt].markAsDirty();
        flag = false;
      }
    }

    if (this.logForm.value.pdfFile == null || this.logForm.value.pdfFile == '') {
      this.qw484File = true;
    }

    flag = this.verifyValidProcesses(flag);

    if (!flag) {
      this.showNotification(
        new NotificationToast(
          'error',
          'Production Log is missing some fields, please check the form.',
          '',
          'error',
          4000
        )
      );
    }

    return flag;
  }

  private verifyValidProcesses(existing: boolean): boolean {
    let flag = existing;
    let index = 0;
    for (let stampProcess of this.getProcesses().getRawValue()) {
      const PRCS = this.getProcesses().controls[index] as UntypedFormGroup;
      if (stampProcess.processid === null) {
        PRCS.controls['processid'].setErrors({ incorrect: true });
        PRCS.controls['processid'].markAsDirty();
        flag = false;
      }

      if (stampProcess.fillerMatNeeded.length === 0 || stampProcess.fillerMatNeeded === null) {
        PRCS.controls['fillerMatNeeded'].setErrors({ incorrect: true });
        PRCS.controls['fillerMatNeeded'].markAsDirty();
        flag = false;
      }

      if (stampProcess.qualifiedThickness === null || stampProcess.qualifiedThickness === '') {
        PRCS.controls['qualifiedThickness'].setErrors({ incorrect: true });
        PRCS.controls['qualifiedThickness'].markAsDirty();
        flag = false;
      }

      index++;
    }
    return flag;
  }

  private clearErrors(): void {
    Object.keys(this.logForm.controls).forEach(key => {
      this.logForm.controls[key].setErrors({ incorrect: null });
      this.logForm.controls[key].updateValueAndValidity();
    });

    for (let i = 0; i < this.getProcesses().getRawValue().length; i++) {
      const PRCS = this.getProcesses().controls[i] as UntypedFormGroup;
      PRCS.controls['processid'].setErrors({ incorrect: null });
      PRCS.controls['processid'].updateValueAndValidity();

      PRCS.controls['fillerMatNeeded'].setErrors({ incorrect: null });
      PRCS.controls['fillerMatNeeded'].updateValueAndValidity();

      PRCS.controls['qualifiedThickness'].setErrors({ incorrect: null });
      PRCS.controls['qualifiedThickness'].updateValueAndValidity();
    }
  }
  //#endregion

  //#region 'Notification'
  private showNotification(msg: NotificationToast) {
    this._srvNotify.sendNotification(msg);
  }
  //#endregion 'Notification'

  //#region 'Events'
  public cancel(): void {
    this.onCancel.emit();
  }

  public logSuccessOrFail(success: boolean): void {
    this.onSuccess.emit(success);
  }
  //#endregion 'Events'

  //#region 'Files'
  public handleOnSelect({ currentFiles }: { originalEvent: any; files: File[]; currentFiles: File[] }): void {
    this.uploadedFiles = currentFiles;
    const selectedFile: File = this.uploadedFiles[0];
    this.qw484File = false;
    this.onUpload(selectedFile);
    if (this.uploadedFiles.length) {
      this.logForm.patchValue({ pdfFile: this.uploadedFiles });
    }
  }

  public handleOnRemove({ file }: { originalEvent: any; file: File }): void {
    if (this.logForm.getRawValue()['pdfFile']) {
      const tempFiles = this.logForm.value.pdfFile.filter(x => x.name !== file.name);
      this.logForm.patchValue({ pdfFile: tempFiles });
    }
  }

  public onUpload(file): any {
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onloadend = evt => {
      if (evt.target.readyState === FileReader.DONE) {
        const base64 = evt.target.result;
        this.fileString = base64.toString().split('base64,')[1];
      }
    };
  }
  //#endregion 'Handlers
}
