import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { catchError, of } from 'rxjs';
import { WelderApiService } from 'src/app/core/api';
import { Welder, WelderStamp } from 'src/app/core/models';
import { UserInfoService } from '.';

@Injectable({
  providedIn: 'root'
})
export class WelderService {
  constructor(private _srvWelders: WelderApiService, private _srvUserInfo: UserInfoService) {}

  public GetWeldersAsync(): Promise<Welder[]> {
    return new Promise<Welder[]>((resolve, reject) => {
      try {
        this._srvWelders.GetWeldersAsync().subscribe(res => {
          resolve(res);
        });
      } catch (err) {
        reject(Error(err));
      }
    });
  }

  public GetWeldersByRefineryAsync(refineryId: number, active?: boolean): Promise<Welder[]> {
    return new Promise<Welder[]>((resolve, reject) => {
      try {
        if (active == false) {
          this._srvWelders.GetAllWeldersByRefineryAsync(refineryId, active).subscribe(res => {
            resolve(res);
          });
        } else {
          this._srvWelders.GetActiveWeldersByRefineryAsync(refineryId).subscribe(res => {
            resolve(res);
          });
        }
      } catch (err) {
        reject(Error(err));
      }
    });
  }

  public GetWelderStampsByRefineryAsync(refineryId: number, active?: boolean): Promise<WelderStamp[]> {
    return new Promise<WelderStamp[]>((resolve, reject) => {
      try {
        const subscription = active
          ? this._srvWelders.GetActiveWelderStampsByRefineryAsync(refineryId)
          : this._srvWelders.GetAllWelderStampsByRefineryAsync(refineryId, active);

        subscription.subscribe(res => {
          const processedWelders = res.map(welder => {
            const startDateFormatted = moment(welder.allocationStartDate).format('MMMM DD, YYYY');
            const endDateFormatted = welder.allocationEndDate
              ? moment(welder.allocationEndDate).format('MMMM DD, YYYY')
              : 'Current';
            const welderType = welder.isContractWelder ? 'Contract Welder' : 'Welder';
            return {
              ...welder,
              welderType: welderType,
              stampDate: `${startDateFormatted} - ${endDateFormatted}`
            };
          });
          resolve(processedWelders);
        });
      } catch (err) {
        reject(Error(err));
      }
    });
  }

  private getWelderId(dto: any, existing: boolean): string {
    if (!existing && dto.isContractWelder) {
      const generateUniqueId = require('generate-unique-id');
      return generateUniqueId({
        length: 25,
        includeSymbols: ['*', '#', '|'],
        excludeSymbols: ['-']
      });
    }
    return dto.welderId ?? dto.userId;
  }

  private setDateTimes(dto: any, existing: boolean) {
    if (existing) {
      dto['updatedDateTime'] = new Date().toJSON();
      dto['updatedBy'] = this._srvUserInfo.userName;
    } else {
      dto['createdDateTime'] = new Date().toJSON();
      dto['createdBy'] = this._srvUserInfo.userName;
    }
  }

  public AddorUpdateWelderAsync(dto: any, existing: boolean): Promise<string> {
    dto['welderId'] = this.getWelderId(dto, existing);
    dto['cai'] = dto.cai ?? '';
    dto['refineryId'] = dto.refineryId ?? this._srvUserInfo.refineryId;
    dto['allocationStartDate'] = dto.allocationStartDate ?? new Date().toJSON();
    dto['allocationEndDate'] = dto.active ? null : new Date().toJSON();
    dto['active'] = dto.active ?? true;
    dto['reasonForDeletion'] = dto.reasonForDeletion ?? null;

    this.setDateTimes(dto, existing);

    return new Promise<string>((resolve, reject) => {
      try {
        const observable = existing ? this._srvWelders.UpdateWelderAsync(dto) : this._srvWelders.AddWelderAsync(dto);

        observable
          .pipe(
            catchError(err => {
              return of(err.error.Message);
            })
          )
          .subscribe(res => {
            resolve(res.toString());
          });
      } catch (err) {
        reject(Error(err));
      }
    });
  }

  public AssignWelderStampAsync(dto: any): Promise<string> {
    dto['cai'] = dto.cai ?? '';
    dto.allocationStartDate = new Date().toJSON();
    dto.allocationEndDate = null;
    dto.active = true;
    dto.reasonForDeletion = null;
    dto.updatedDateTime = new Date().toJSON();
    dto.updatedBy = this._srvUserInfo.userName;
    dto.createdDateTime = new Date().toJSON();
    dto.createdBy = this._srvUserInfo.userName;

    return new Promise<string>((resolve, reject) => {
      try {
        this._srvWelders
          .AssignWelderStampAsync(dto)
          .pipe(
            catchError(err => {
              return of(err.error.Message);
            })
          )
          .subscribe(res => {
            resolve(res.toString());
          });
      } catch (err) {
        reject(Error(err));
      }
    });
  }
}
