import { Component, OnInit } from '@angular/core';
import { LoadingIndicatorService } from 'src/app/data/services/loading-indicator.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-generic-error',
  templateUrl: './generic-error.component.html',
  styleUrls: ['./generic-error.styles.scss']
})
export class GenericErrorComponent implements OnInit {
  public readonly _link = environment.supportLink;

  constructor(private _loader: LoadingIndicatorService) {}

  ngOnInit(): void {
    setTimeout(() => {
      this._loader.hide();
    }, 1000);
  }
}
