import { Base } from './Base.model';

export class QATypesAndLabel extends Base {
  constructor() {
    super();
  }

  public weldLogQAID: number = 0;
  public weldLogId: number = 0; // might need to remove
  public qaTypeID: number = 0;
  public qaTypeName: string;
  public description: string;
  public maxDateFields: number = 0;
  public qaTypeLabelID: number = 0;
  public noofLabels: number = 0;
  public labelNames: string;
  public fieldSequence: number;
  public qaDate: string;
  public defectTypeID: number = 0;
  public active: boolean;
  public reasonForDeletion: string = '';
  public ndeRequestURL: string;
}
