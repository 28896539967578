import { Injectable } from '@angular/core';
import moment from 'moment';
import { catchError, of } from 'rxjs';
import { RoleApiService } from 'src/app/core/api';
import { Role } from 'src/app/core/models';
import { UserInfoService } from '.';

@Injectable({
  providedIn: 'root'
})
export class RoleService {
  constructor(private _srvRole: RoleApiService, private _srvUserInfo: UserInfoService) {}

  public GetRolesAsync(active?: boolean): Promise<Role[]> {
    return new Promise<Role[]>((resolve, reject) => {
      try {
        this._srvRole.GetRolesAsync().subscribe(allRoles => {
          allRoles = allRoles
            .sort((a, b) => a.roleName.toLocaleLowerCase().localeCompare(b.roleName.toLocaleLowerCase()))
            .filter(x => x.refineryId == Number(this._srvUserInfo.refineryId))
            .filter(x => (active ? x.active : true));

          this._srvRole
            .GetRolesPermissionsByRefineryAsync(Number(this._srvUserInfo.refineryId))
            .subscribe(allRolesByRefinery => {
              allRoles.forEach(role => {
                role['rolePermissionIdArray'] = allRolesByRefinery
                  .filter(rolePermission => rolePermission.roleId === role.roleId)
                  .map(rolePermission => rolePermission['permissionId']);

                role['status'] = role.active ? 'Active' : 'Inactive';
                role.createdDateTime = `${moment(role.createdDateTime).format('MMMM DD, YYYY')}`;
                role.updatedDateTime = role.updatedDateTime
                  ? `${moment(role.updatedDateTime).format('MMMM DD, YYYY')}`
                  : null;
              });
              resolve(allRoles);
            });
        });
      } catch (err) {
        reject(Error(err.error.Message));
      }
    });
  }

  public AddorUpdateRoleAsync(role: Role, existing: boolean, permissionNumbers: number[]): Promise<string> {
    if (existing) {
      role.updatedBy = this._srvUserInfo.userName;
      role.updatedDateTime = new Date().toJSON();
    } else {
      role.refineryId = Number(this._srvUserInfo.refineryId);
      role.active = true;
      role.createdBy = this._srvUserInfo.userName;
      role.createdDateTime = new Date().toJSON();
    }

    return new Promise<string>((resolve, reject) => {
      try {
        const roleServiceMethod = existing
          ? this._srvRole.UpdateNewRoleAndPermissionsAsync(role, permissionNumbers)
          : this._srvRole.AddNewRoleAndPermissionsAsync(role, permissionNumbers);

        roleServiceMethod
          .pipe(
            catchError(err => {
              return of(err.error.Message);
            })
          )
          .subscribe(allRoles => {
            resolve(allRoles.toString());
          });
      } catch (err) {
        reject(Error(err));
      }
    });
  }
}
