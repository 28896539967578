import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { WeldLog } from '../../../../core/models';
import { DropdownList } from '../../../../data/models';
import {
  DropdownService,
  KeypressValidationService,
  LoadingIndicatorService,
  WeldLogService,
  UserInfoService,
  RefineryService
} from '../../../../data/services';
import { NotificationToast, NotificationService } from '../../../shared/notification';

@Component({
  selector: 'app-edit-multiple-logs',
  templateUrl: './edit-multiple-logs.component.html',
  styleUrls: ['./edit-multiple-logs.component.scss']
})
export class EditMulitpleLogsComponent implements OnInit {
  //#region 'Variables'
  @Input() public userPermissions: any;
  @Input() public hideActionBtns: boolean;
  @Input() public selectedLogs: WeldLog[];
  @Input() public requiredFieldsLog: number; // 0 = defaultFields, 1 = MCBU Fields
  @Output() public onCancel: EventEmitter<any> = new EventEmitter<any>();
  @Output() public onSuccess: EventEmitter<string> = new EventEmitter<string>();

  public fieldDropDownList = new DropdownList();
  public hideForMCBU = false;
  public showForMCBU = false;

  public logForm = new UntypedFormGroup({
    fieldsArray: new UntypedFormArray([])
  });

  //setup fields used for multiple editing
  public editableFields = [
    { name: 'Date Welded', id: 'dateWelded' },
    { name: 'Drawing', id: 'drawing' },
    { name: 'EWO Number', id: 'ewonumber' },
    { name: 'Group', id: 'weldGroupId' },
    { name: 'Joint Type', id: 'jointTypeId' },
    { name: 'Pipe Spec', id: 'spec' },
    { name: 'Project/PO#', id: 'projectOrPo' },
    { name: 'RT Percentage', id: 'rtpercentageId' },
    { name: 'WPS', id: 'wpsid' }
  ];
  //#endregion

  //#region 'Angular Life Cycle'
  constructor(
    public _srvDropDown: DropdownService,
    private _srvWeldLog: WeldLogService,
    private _srvNotify: NotificationService,
    private _loader: LoadingIndicatorService,
    public keyValidation: KeypressValidationService,
    private readonly _srvUserInfo: UserInfoService,
    private _srvRefinery: RefineryService
  ) {}

  ngOnInit(): void {
    this.logForm.enable();
    this.addAllocationRow();
    this.fillFieldTypeDropDown();
    this.hideRequiredMarker();
  }
  //#endregion

  //#region 'General Methods'
  public submitNewLog() {
    this._loader.show();
    this.selectedLogs.forEach(log => {
      Object.assign(log, log.original);
      this.logForm.value['fieldsArray'].forEach(fieldType => {
        Object.assign(log, fieldType);
      });
    });
    if (this.verifyValidLog()) {
      this._srvWeldLog.UpdateMultipleWeldLogs(this.selectedLogs).then(res => {
        this.multipleLogsSuccessOrFail(res);
      });
    } else {
      this._loader.hide();
    }
  }

  //add items to "Field" dropdown
  public fillFieldTypeDropDown() {
    this._srvDropDown.fillDropdown(this.fieldDropDownList, this.editableFields, 'name', 'id', 'Field Type');
  }

  public async hideRequiredMarker() {
    //hide the required * icon based on user's refinery
    if (this.requiredFieldsLog === 1) {
      this.hideForMCBU = true;
      this.showForMCBU = true;
    }
  }

  //get the FormArray which holds the FormGroups which hold FormControls of each row
  public getFieldRows(): UntypedFormArray {
    return this.logForm.get('fieldsArray') as UntypedFormArray;
  }

  //add control to row based on selected field
  public onFieldSelect(event: any, index: number) {
    this.enableDisableFieldTypes();
    let formRow = this.getFieldRows().at(index) as UntypedFormGroup;

    let toDelete = Object.keys(formRow.value).filter(key => key != 'fieldType');
    for (let item of toDelete) {
      formRow.removeControl(item);
    }
    formRow.addControl(event.value, new UntypedFormControl());
  }

  //add row to form
  public addAllocationRow() {
    let temp = new UntypedFormGroup({
      fieldType: new UntypedFormControl()
    });
    this.getFieldRows().push(temp);
  }

  //remove row from form
  public removeAllocationRow(index?: number) {
    this.getFieldRows().removeAt(index);
    this.enableDisableFieldTypes();
  }

  //enable/disable items in 'Field' dropdown
  public enableDisableFieldTypes() {
    // dynamically enable or disable Field Types based on if they are already selected (so we can't have duplicates)
    for (const dt of this.fieldDropDownList.items) {
      for (let j = 0; j < this.getFieldRows().length; j++) {
        if (dt.id === this.getFieldRows().at(j).value['fieldType']) {
          dt.disabled = true;
          break;
        }
        dt.disabled = false;
      }
    }
  }
  //#endregion 'General Methods'

  //#region 'Validations'
  private verifyValidLog(): boolean {
    let flag = true;
    this.clearErrors();
    let index = 0;
    for (let rowObj of this.getFieldRows().value) {
      const formRow = this.getFieldRows().controls[index] as UntypedFormGroup;
      let updatedFieldKey = Object.keys(rowObj).filter(key => key != 'fieldType');

      if (formRow.value['drawing'] != null) {
        formRow.controls['drawing'].setValue(formRow.value['drawing'].trim());
      }
      if (formRow.value['projectOrPo'] != null) {
        formRow.controls['projectOrPo'].setValue(formRow.value['projectOrPo'].trim());
      }
      if (formRow.value['fieldType'] == null) {
        formRow.controls['fieldType'].setErrors({ incorrect: true });
        formRow.controls['fieldType'].markAsDirty();
        flag = false;
      } else {
        if (formRow.value[updatedFieldKey[0]] == null || formRow.value[updatedFieldKey[0]] == '') {
          formRow.controls[updatedFieldKey[0]].setErrors({ incorrect: true });
          formRow.controls[updatedFieldKey[0]].markAsDirty();
          flag = false;
        }
      }
      index++;
    }
    if (!flag) {
      this.showNotification(
        new NotificationToast('error', 'Log is missing some fields, please check the form.', '', 'error', 4000)
      );
    }
    return flag;
  }

  private clearErrors(): void {
    for (let formRow of this.getFieldRows().controls) {
      Object.keys(formRow['controls']).forEach(key => {
        formRow['controls'][key].setErrors({ incorrect: null });
        formRow['controls'][key].updateValueAndValidity();
      });
    }
  }
  //#endregion

  //#region 'Notification'
  private showNotification(msg: NotificationToast) {
    this._srvNotify.sendNotification(msg);
  }
  //#endregion 'Notification'

  //#region 'Events'
  //emit to parent component to close form
  public cancel(): void {
    this.onCancel.emit();
  }

  //emit results from submit to parent component
  public multipleLogsSuccessOrFail(result: string): void {
    this.onSuccess.emit(result);
  }
  //#endregion
}
