import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { WeldLog } from '../../../../core/models';
import { DropdownService } from '../../../../data/services';
import { DropdownList } from 'src/app/data/models';

@Component({
  selector: 'app-edit-multiple-processes',
  templateUrl: './edit-multiple-processes.component.html',
  styleUrls: ['./edit-multiple-processes.component.scss']
})
export class EditMultipleProcessesComponent implements OnInit {
  //#region 'Variables'
  @Input() public action: number; // 0 = New, 1 = Edit, 2 = View
  @Input() public currentLog: WeldLog = new WeldLog();
  @Input() public showAcceptReject: number; // 0 = Add Stamp & Process, 1 = Add Accept/Reject
  @Output() childEvent = new EventEmitter<string[]>();

  public formDisabled = false;
  public processForm = new UntypedFormGroup({
    processes: new UntypedFormArray([])
  });

  public acceptedRejectedDTO = [
    { label: 'Accepted', value: true },
    { label: 'Rejected', value: false }
  ];
  public stampProcessInformation = [];
  //#endregion*/

  constructor(public _srvDropDown: DropdownService) {}

  ngOnInit(): void {
    this.newLog();
  }

  public newLog() {
    this.addStampProcessRow();
    this.enableAllProcesses();
    this.enableForm();
  }

  public getProcesses(): UntypedFormArray {
    return this.processForm.get('processes') as UntypedFormArray;
  }

  public getProcessByIndex(index: number): UntypedFormGroup {
    return this.getProcesses().at(index) as UntypedFormGroup;
  }

  public enableDisableWelders() {
    // dynamically enable or disable welders based on if they are already selected (so we can't have duplicates)
    for (const dt of this._srvDropDown.stampAllocation.items) {
      for (let j = 0; j < this.getProcesses().length; j++) {
        if (dt.id === this.getProcesses().at(j).value['stampid']) {
          dt.disabled = true;
          break;
        }
        dt.disabled = false;
      }
    }
  }

  public enableAllProcesses() {
    for (let item of this._srvDropDown.stampAllocation.items) {
      item.disabled = false;
    }
  }

  public addStampProcessRow() {
    let temp = new UntypedFormGroup({
      stampid: new UntypedFormControl(),
      processids: new UntypedFormControl([]),
      rtacceptedRejected: new UntypedFormControl()
    });

    this.getProcesses().push(temp);
  }

  async sendData() {
    for (let i = 0; i < this.getProcesses().length; i++) {
      let processContentArray = [...this.getProcesses().value];
      this.stampProcessInformation = processContentArray;
      this.childEvent.emit(this.stampProcessInformation);
    }
  }

  private enableForm() {
    this.formDisabled = false;
    for (let control of this.processForm.controls['processes']['controls']) {
      control.enable();
    }
  }
}
