import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { QualificationLog } from '../models';
import { BaseApiService } from '../shared/base-api.service';

@Injectable({
  providedIn: 'root'
})
export class QualificationLogApiService extends BaseApiService<QualificationLog> {
  //#region 'Angular Life Cycle'
  constructor(protected _http: HttpClient) {
    super(_http, 'qualificationlog');
  }
  //#endregion

  //#region 'Get'
  public GetQualificationLogAsync(refineryId: number, stampNumber: string): Observable<any[]> {
    const options = { refineryId, stampNumber };
    return this.getAllById(options, '/GetQualificationLogAsync');
  }

  public GetQualificationLogFileByIdAsync(weldLogFileId: number): Observable<any[]> {
    const options = { weldLogFileId };
    return this.getAllById(options, '/GetQualificationLogFileByIdAsync');
  }

  //#endregion

  //#region 'Create'
  public AddQualificationLogAsync(dto: QualificationLog): Observable<boolean> {
    return this.createAny(dto, '/AddQualificationLogAsync');
  }
  //#endregion

  //#region 'Update'
  public UpdateQualificationLogAsync(dto: QualificationLog): Observable<boolean> {
    return this.createAny(dto, '/UpdateQualificationLogAsync');
  }
  //#endregion

  //#region 'Delete'
  //#endregion

  //#region 'Other'
  //#endregion
}
