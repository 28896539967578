<main>
  <div class="container">
    <div class="row">
      <!-- #region Message -->
      <div class="col-12 align-self-center center-error">
        <h1>UH OH! You're Not Authorized.</h1>
        <h6>
          Sorry but you don't have access to the system. Please request access to the application in the following link
          <a [href]="_link" target="_blank" rel="noopener">Chevron App Support</a> .
        </h6>
      </div>
      <!-- #endregion -->
    </div>
  </div>
</main>
