import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  QualificationLogApiService,
  RefineryApiService,
  WelderApiService,
  WeldManagerUserApiService,
  WeldProcessApiService
} from 'src/app/core/api';
import { WelderCertification } from 'src/app/core/models';
import { UserInfoService } from './user-info.service';

@Injectable()
export class WeldCertificationService {
  //#region 'Variables'
  private refineries: any[];
  public get Refineries(): any[] {
    return this.refineries;
  }

  private weldCertData: WelderCertification[];
  public get WeldCertData(): WelderCertification[] {
    return this.weldCertData;
  }

  private time01: number;
  public get Time01(): number {
    return this.time01;
  }

  private time02: number;
  public get Time02(): number {
    return this.time02;
  }

  private time03: number;
  public get Time03(): number {
    return this.time03;
  }

  private daysUntilExpired: number;
  public get DaysUntilExpired(): number {
    return this.daysUntilExpired;
  }
  //#endregion 'Variables'

  //#region 'Angular Life Cycle'
  constructor(
    private readonly _srvWelder: WelderApiService,
    private readonly _srvWeldProc: WeldProcessApiService,
    private readonly _srvRefineries: RefineryApiService,
    private readonly _srvWeldRole: WeldManagerUserApiService,
    private readonly _srvUserInfo: UserInfoService,
    private readonly _srvQualLog: QualificationLogApiService
  ) {}
  //#endregion 'Angular Life Cycle'

  //#region 'Load'
  public loadRefineries(): Observable<any> {
    return this._srvWeldRole.GetUserRolesByUserAsync(this._srvUserInfo.userId);
  }

  public loadWeldCertData(id: number): Observable<any> {
    return this._srvWelder.GetWeldersCertificaionAsync(id.toString());
  }

  public loadWeldProcesses(): Observable<any> {
    return this._srvWeldProc.GetWeldProcessesAsync();
  }

  public loadTimes(id: number) {
    this._srvRefineries.GetRefineriesAsyncById(id).subscribe(dt => {
      this.time01 = dt[0].reminderEmail1;
      this.time02 = dt[0].reminderEmail2;
      this.time03 = dt[0].reminderEmail3;
      this.daysUntilExpired = dt[0].certValidityDays;
    });
  }
  //#endregion 'Load'

  //#region 'Validations'
  //#endregion

  //#region 'CRUD'
  //#endregion
}
