<app-header></app-header>
<body>
  <app-loading-indicator></app-loading-indicator>
  <div class="container-fluid">
    <router-outlet></router-outlet>
    <!--this is where the components are rendered-->
  </div>
</body>
<app-footer></app-footer>

<p-toast [style]="{ marginTop: '40px !important' }" key="success" [baseZIndex]="6000"></p-toast>
<p-toast [style]="{ marginTop: '40px !important' }" key="error" [baseZIndex]="6000" position="top-center"></p-toast>
