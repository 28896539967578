import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Size } from '../models';
import { BaseApiService } from '../shared/base-api.service';

@Injectable({
  providedIn: 'root'
})
export class SizeApiService extends BaseApiService<Size> {
  //#region 'Angular Life Cycle'
  constructor(protected _http: HttpClient) {
    super(_http, 'size');
  }
  //#endregion

  //#region 'Get'
  public GetActiveSizeAsync(): Observable<Size[]> {
    return this.getAll('/GetSizeAsync');
  }

  public GetAllSizeAsync(active: boolean): Observable<Size[]> {
    const options = { active };
    return this.getAllById(options, '/GetSizeAsync');
  }

  public GetSizeAsyncById(FilltermaterialId: number): Observable<Size> {
    const options = { FilltermaterialId };
    return this.getById(options, '/GetSizeAsync');
  }
  //#endregion

  //#region 'Create'
  public UpdateSizeAsync(fillMat: Size): Observable<boolean> {
    return this.createAny(fillMat, '/UpdateSizeAsync');
  }
  //#endregion

  //#region 'Update'
  public AddSizeAsync(fillMat: Size): Observable<boolean> {
    return this.createAny(fillMat, '/AddSizeAsync');
  }
  //#endregion

  //#region 'Delete'
  //#endregion

  //#region 'Other'
  //#endregion
}
