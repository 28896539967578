import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Material } from '../models';
import { BaseApiService } from '../shared/base-api.service';

@Injectable({
  providedIn: 'root'
})
export class MaterialApiService extends BaseApiService<Material> {
  //#region 'Angular Life Cycle'
  constructor(protected _http: HttpClient) {
    super(_http, 'material');
  }
  //#endregion

  //#region 'Get'
  public GetActiveMaterialAsync(): Observable<Material[]> {
    return this.getAll('/GetMaterialAsync');
  }

  public GetAllMaterialAsync(active: boolean): Observable<Material[]> {
    const options = { active };
    return this.getAllById(options, '/GetMaterialAsync');
  }

  public GetMaterialAsyncById(FilltermaterialId: number): Observable<Material> {
    const options = { FilltermaterialId };
    return this.getById(options, '/GetMaterialAsync');
  }
  //#endregion

  //#region 'Create'
  public UpdateMaterialAsync(fillMat: Material): Observable<boolean> {
    return this.createAny(fillMat, '/UpdateMaterialAsync');
  }
  //#endregion

  //#region 'Update'
  public AddMaterialAsync(fillMat: Material): Observable<boolean> {
    return this.createAny(fillMat, '/AddMaterialAsync');
  }
  //#endregion

  //#region 'Delete'
  //#endregion

  //#region 'Other'
  //#endregion
}
