import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { WeldGroup } from '../models';
import { BaseApiService } from '../shared/base-api.service';

@Injectable({
  providedIn: 'root'
})
export class WeldGroupApiService extends BaseApiService<WeldGroup> {
  //#region 'Angular Life Cycle'
  constructor(protected _http: HttpClient) {
    super(_http, 'weldgroup');
  }
  //#endregion

  //#region 'Get'
  public GetWeldGroupsAsync(): Observable<WeldGroup[]> {
    return this.getAll('/GetWeldGroupsAsync');
  }

  public GetWeldGroupsAsyncById(weldgroupid: number): Observable<WeldGroup> {
    const options = { weldgroupid };
    return this.getById(options, '/GetWeldGroupsAsync');
  }
  //#endregion

  public GetActiveWeldGroupsByRefineryAsync(refineryid: number): Observable<WeldGroup[]> {
    const options = { refineryid };
    return this.getAllById(options, '/GetWeldGroupByRefineryAsync');
  }

  public GetAllWeldGroupsByRefineryAsync(refineryid: number, active: boolean): Observable<WeldGroup[]> {
    const options = { refineryid, active };
    return this.getAllById(options, '/GetWeldGroupByRefineryAsync');
  }

  //#region 'Create'
  public AddWeldGroupAsync(group: WeldGroup): Observable<boolean> {
    return this.createAny(group, '/AddWeldGroupAsync');
  }
  //#endregion

  //#region 'Update'
  public UpdateWeldGroupAsync(group: WeldGroup): Observable<boolean> {
    return this.createAny(group, '/UpdateWeldGroupAsync');
  }
  //#endregion

  //#region 'Delete'
  //#endregion

  //#region 'Other'
  //#endregion
}
