import { Injectable } from '@angular/core';
import moment from 'moment';
import { catchError, of } from 'rxjs';
import { RefineryApiService } from 'src/app/core/api';
import { Refinery } from 'src/app/core/models';
import { UserInfoService } from '.';

@Injectable({
  providedIn: 'root'
})
export class RefineryService {

  constructor(private _srvRefinery: RefineryApiService, private _srvUserInfo: UserInfoService) { }

  public GetRefineriesAsyncById(refineryId: number): Promise<Refinery> {
    return new Promise<Refinery>((resolve, reject) => {
      try {
        this._srvRefinery.GetRefineriesAsyncById(refineryId).subscribe(res => {
          res[0].updatedDateTime = res[0].updatedDateTime
                ? `${moment(res[0].updatedDateTime).format('MMMM DD, YYYY')}`
                : null;
          res[0]['emailsListArray'] = res[0].reminderEmailCopyTo.length === 0 ? [] : res[0].reminderEmailCopyTo.split(',');
          resolve(res);
        });
      } catch (err) {
        reject(Error(err));
      }
    });
  }
  
  public UpdateRefineryEmailSettingsAsync(refinery: Refinery): Promise<string> {
    refinery.updatedBy = this._srvUserInfo.userName;
    refinery.updatedDateTime = new Date().toJSON();
    return new Promise<string>((resolve, reject) => {
      try {
        this._srvRefinery
          .UpdateRefineryEmailSettingsAsync(refinery)
          .pipe(
            catchError(err => {
              return of(err.error.Message);
            })
          )
          .subscribe(res => {
            resolve(res.toString());
          });
      } catch (err) {
        reject(Error(err));
      }
    });
  }

}
