import { Injectable } from '@angular/core';
import moment from 'moment';
import { catchError, of } from 'rxjs';
import { SizeApiService } from 'src/app/core/api';
import { Size } from 'src/app/core/models';
import { UserInfoService } from '.';

@Injectable({
  providedIn: 'root'
})
export class SizeService {
  constructor(private _srvSize: SizeApiService, private _srvUserInfo: UserInfoService) {}
  public GetSizeAsync(active?: boolean): Promise<Size[]> {
    return new Promise<Size[]>((resolve, reject) => {
      try {
        // Define a common function to process the results
        const processResults = (res: Size[]) => {
          res.sort((a, b) => (Number(a.sizeName) < Number(b.sizeName) ? -1 : 1));
          res.forEach(size => {
            size['status'] = size.active ? 'Active' : 'Inactive';
            size.createdDateTime = `${moment(size.createdDateTime).format('MMMM DD, YYYY')}`;
            size.updatedDateTime = size.updatedDateTime
              ? `${moment(size.updatedDateTime).format('MMMM DD, YYYY')}`
              : null;
          });
          resolve(res);
        };

        // Use the appropriate service call based on the 'active' parameter
        const serviceCall = active ? this._srvSize.GetActiveSizeAsync() : this._srvSize.GetAllSizeAsync(active);

        // Subscribe to the service call and process results
        serviceCall.subscribe(processResults);
      } catch (err) {
        reject(Error(err));
      }
    });
  }

  public AddorUpdaterSizeAsync(size: Size, existing: boolean): Promise<string> {
    // Common updates for both existing and new size
    size.updatedBy = this._srvUserInfo.userName;
    size.updatedDateTime = new Date().toJSON();

    if (!existing) {
      // Additional setup for new size
      size.active = true;
      size.createdBy = this._srvUserInfo.userName;
      size.createdDateTime = new Date().toJSON();
    }

    return new Promise<string>((resolve, reject) => {
      try {
        // Choose the appropriate service method
        const sizeServiceMethod = existing ? this._srvSize.UpdateSizeAsync(size) : this._srvSize.AddSizeAsync(size);

        // Handle the response
        sizeServiceMethod
          .pipe(
            catchError(err => {
              return of(err.error.Message);
            })
          )
          .subscribe(res => {
            resolve(res);
          });
      } catch (err) {
        reject(Error(err));
      }
    });
  }
}
