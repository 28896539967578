import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { GuardService } from './guard.service';

@Injectable()
export class UserPermissionsGuard  {
  constructor(private readonly _srvGuard: GuardService) {}

  canActivate(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): boolean {
    return this._srvGuard.validateUserPermissions(route.routeConfig.path);
  }
}
