<!-- #region Title -->
<div class="row">
  <div class="col-12 d-flex justify-content-center">
    <h2>{{ this.dataType.title }}</h2>
  </div>
</div>
<!-- #endregion -->

<!-- #region 'Action Row' -->
<div class="row">
  <!-- #region 'Global Field vs By Refinery Notification' -->
  <div *ngIf="this.dataType.globalField" style="color: #ff0000" class="col-2">
    <label>* {{ this.dataType.title }} is a Global Field. Changes will impact all users. </label>
  </div>
  <div *ngIf="this.router.url == '/admin/switchsite'" style="color: green" class="col-2">
    <label>* Right click on {{ this.dataType.label }} below to switch refinery. </label>
  </div>
  <!-- #endregion 'Global Field vs By Refinery Notification' -->

  <div class="col-3">
    <!-- #region 'Welder Filter by Welder Type' -->
    <p-dropdown
      *ngIf="this.router.url == '/admin/welder'"
      styleClass="mt-2"
      appendTo="body"
      optionValue="name"
      optionLabel="name"
      [(ngModel)]="welderTypeFilter"
      placeholder="Select a Welder Type"
      [options]="_srvDropDown.welderType.items"
      (onChange)="filterWelderTable()"
      [filter]="true"
      [showClear]="true"
      (onClear)="filterWelderTable()"
    ></p-dropdown>
    <!-- #endregion 'Welder Filter by Welder Type' -->

    <!-- #region 'Contract Welder Photo Filter by Compnay' -->
    <p-dropdown
      *ngIf="this.router.url == '/admin/contract-welder'"
      styleClass="mt-2"
      appendTo="body"
      optionValue="name"
      optionLabel="name"
      [(ngModel)]="companyFilter"
      placeholder="Select a Contract Company"
      [options]="_srvDropDown.contractCompanies.items"
      (onChange)="filterContractWelderTable()"
      [filter]="true"
      [showClear]="true"
      (onClear)="filterContractWelderTable()"
    ></p-dropdown>
    <!-- #endregion 'Contract Welder Photo Filter by Compnay' -->
  </div>

  <div *ngIf="this.router.url != '/admin/email' && this.router.url != '/admin/switchsite'" class="col-7 alignRight">
    <!-- #region 'Show/Hide Inactive button'  -->
    <p-toggleButton styleClass="p-button-m mt-2 mr-2" [(ngModel)]="activeData" [onLabel]="'Show Inactive'" [offLabel]="'Hide Inactive'" (onChange)="getData()"></p-toggleButton>
    <!-- #endregion 'Show/Hide Inactive button' -->

    <!-- #region Add Button -->
    <p-button *ngIf="router.url != '/admin/contract-welder'" class="green-btn" styleClass="p-button-m mt-2" (onClick)="openCloseForm('New')">
      <em class="pi pi-plus-circle mr-2"></em> Add {{ this.dataType.label }}
    </p-button>
    <!-- #endregion Add Button -->
  </div>
</div>
<!-- #endregion 'Action Row' -->

<!-- #region 'Dynamic Data Table' -->
<p-contextMenu #cm [model]="contextMenuItems" appendTo="body"></p-contextMenu>
<p-table
  #adminTable
  [columns]="tableCols"
  [value]="tableData"
  [scrollable]="true"
  [paginator]="true"
  paginatorPosition="both"
  paginatorDropdownAppendTo="body"
  [rows]="50"
  [rowsPerPageOptions]="[10, 25, 50, 100]"
  selectionMode="single"
  [(selection)]="selectedRow"
  [contextMenu]="cm"
  (onContextMenuSelect)="setContextMenuItems($event)"
  contextMenuSelectionMode="joint"
  [dataKey]="dataType.field"
>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th *ngFor="let col of columns" id="head{{ col.header }}" class="header" [pSortableColumn]="col.field">
        {{ col.header }}
        <p-sortIcon [field]="col.field"></p-sortIcon>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-user let-columns="columns">
    <tr [pSelectableRow]="user" [pContextMenuRow]="user">
      <td *ngFor="let col of columns">
        <div *ngIf="col.header == 'Email Copy To'">
          <ng-container *ngFor="let email of tableData[0].emailsListArray; index as i">
            <p-tag value="{{ tableData[0].emailsListArray[i] }}" icon="pi pi-user" styleClass="mr-2 mt-1 mb-1" class="email-tag"></p-tag>
          </ng-container>
        </div>
        <div *ngIf="col.header == 'RT Percentage'">{{ user[col.field] }}%</div>
        <div *ngIf="col.header != 'Email Copy To' && col.header != 'RT Percentage' && col.header != 'Photo'">
          <span *ngIf="col.header == 'Created Date' || col.header == 'Updated Date'; else defaultContent">
            {{ datePipe.transform(user[col.field], 'yyyy-MM-dd hh:mm a') }}
          </span>
          <ng-template #defaultContent>{{ user[col.field] }}</ng-template>
        </div>
        <div *ngIf="col.header == 'Photo'">
          <ng-container *ngIf="user[col.field]; else noPhoto">
            <p-image #imageElement *ngIf="user[col.field]" src="data:image/jpeg;base64,{{ user[col.field] }}" alt="Image" height="100px" [preview]="true"></p-image>
          </ng-container>
          <ng-template #noPhoto> N/A </ng-template>
        </div>
      </td>
    </tr>
  </ng-template>
</p-table>
<!-- #endregion 'Dynamic Data Table' -->

<!-- #region 'Add / View Form' -->
<p-dialog
  header="{{ formHeader + ' ' + dataType.label }}"
  [style]="{ width: 'auto', 'min-height': '0px' }"
  [(visible)]="displayForm"
  [modal]="true"
  [closeOnEscape]="true"
  [draggable]="false"
  [resizable]="false"
  [blockScroll]="false"
>
  <app-add-edit-admin *ngIf="displayForm" [existing]="existing" [selectedRow]="selectedRow" [dataType]="dataType" (onCancel)="openCloseForm()" (onSuccess)="onSuccessOrFail($event)">
  </app-add-edit-admin>
</p-dialog>
<!-- #endregion 'Add / View Form' -->

<!-- #region Delete user/welder dialog-->
<p-dialog
  [header]="'Delete ' + dataType.label"
  [(visible)]="displayDeleteDialog"
  [modal]="true"
  [closeOnEscape]="true"
  [draggable]="false"
  [resizable]="true"
  [style]="{ 'min-height': '0px', width: '20%' }"
>
  <div class="row">
    <div class="col-12">
      <label for="deleteReason">Reason for deletion</label>
      <textarea name="deleteReason" id="deleteReason" type="text" pInputText maxlength="500" rows="2" [(ngModel)]="reasonForDeletion" class="formInput form-control"> </textarea>
    </div>
  </div>

  <div class="row">
    <div class="col-12 alignRight">
      <p-button (onClick)="deleteRow(selectedRow)" class="mr-2 red-btn"><em class="pi pi-trash mr-2"></em>Delete</p-button>
      <p-button (onClick)="openCloseDeleteDialog()" id="cancelButton"><em class="pi pi-times-circle mr-2"></em>Cancel</p-button>
    </div>
  </div>
</p-dialog>
<!-- #endregion Delete user/welder dialog-->

<!-- #region Activate Welder dialog-->
<p-dialog
  header="Activate Welder"
  [(visible)]="displayActivateWelderDialog"
  [modal]="true"
  [closeOnEscape]="true"
  [draggable]="false"
  [resizable]="true"
  [style]="{ 'min-height': '0px', width: '20%' }"
>
  <div class="row">
    <!-- #region 'Chevron Stamp' -->
    <div class="col-12">
      <label for="activateChevronStampNumber">Chevron Stamp</label><br />
      <input
        name="activateChevronStampNumber"
        id="activateChevronStampNumber"
        type="text"
        pInputText
        [(ngModel)]="activateChevronStampNumber"
        class="formInput"
        [ngClass]="{ missingField: !isValidFlags.chevronStampNumber }"
        (blur)="clearValidFlags(['chevronStampNumber'])"
      />
    </div>
    <!-- #endregion 'Chevron Stamp' -->

    <!-- #region 'Contractor Stamp' -->
    <div *ngIf="isContractWelder" class="col-12">
      <label for="activateContractorStampNumber">Contractor Stamp</label><br />
      <input
        name="activateContractorStampNumber"
        id="activateContractorStampNumber"
        type="text"
        pInputText
        [(ngModel)]="activateContractorStampNumber"
        class="formInput"
        [ngClass]="{ missingField: !isValidFlags.contractorStampNumber }"
        (blur)="clearValidFlags(['contractorStampNumber'])"
      />
    </div>
    <!-- #endregion 'Contractor Stamp' -->

    <!-- #region 'Contractor Company' -->
    <div *ngIf="isContractWelder" class="col-12">
      <label for="contractCompanyId">Contract Company <span style="color: red">*</span></label
      ><br />
      <p-dropdown
        appendTo="body"
        optionValue="id"
        optionLabel="name"
        name="contractCompanyId"
        [(ngModel)]="activateContractCompanyId"
        placeholder="Select a Contract Company"
        [options]="_srvDropDown.contractCompanies.items"
        [filter]="true"
        [ngClass]="{ missingInput: !isValidFlags.contractCompanyId }"
        (onBlur)="clearValidFlags(['contractCompanyId'])"
      ></p-dropdown>
    </div>
    <!-- #endregion 'Contractor Company' -->
  </div>

  <!-- #region 'Update/Canel Buttons' -->
  <div class="row">
    <div class="col-12 alignRight">
      <p-button (onClick)="ActivateWelder(selectedRow)" class="mr-2">Update Welder</p-button>
      <p-button (onClick)="openCloseActivateWelderDialog()" id="cancelButton" class="red-btn"><em class="pi pi-times-circle mr-2"></em>Cancel</p-button>
    </div>
  </div>
  <!-- #endregion 'Update/Canel Buttons' -->
</p-dialog>
<!-- #endregion Activate Welder dialog-->

<!-- #region 'Custom Confirm Notification' -->
<p-toast [style]="{ marginTop: '40px !important' }" key="confirm" [baseZIndex]="6000" position="center">
  <ng-template let-message pTemplate="message">
    <div class="flex flex-column" style="flex: 1">
      <div class="text-center">
        <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
        <h4>{{ message.summary }}</h4>
        <p>{{ message.detail }}</p>
      </div>
      <div class="grid p-fluid">
        <div class="col-6">
          <button type="button" pButton (click)="onConfirm(selectedRow)" label="Yes" class="p-button-success"></button>
        </div>
        <div class="col-6">
          <button type="button" pButton (click)="onReject()" label="No" class="p-button-secondary"></button>
        </div>
      </div>
    </div>
  </ng-template>
</p-toast>
<!-- #endregion 'Custom Confirm Notification' -->

<!-- #region 'Photo Dialog' -->
<p-dialog
  header="Contract Welder Photo"
  [style]="{ width: 'auto', 'min-height': '0px' }"
  [(visible)]="displayPhotoDialog"
  [modal]="true"
  [closeOnEscape]="true"
  [draggable]="false"
  [resizable]="false"
  [blockScroll]="false"
>
  <app-contract-welder-photo *ngIf="displayPhotoDialog" (onSavePhoto)="savePhoto($event)" (onCancel)="openClosePhotoDialog()"></app-contract-welder-photo>
</p-dialog>
<!-- #endregion 'Photo Dialog' -->
