import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { LoadingIndicatorService } from 'src/app/data/services/loading-indicator.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  //#region 'Variables'
  public PERMISSIONS: any;
  public userName = '';
  public refineryName = '';
  menuItems: MenuItem[];
  adminMenuItems: MenuItem[];
  reportingMenuItems: MenuItem[];
  //#endregion

  //#region 'Angular Life Cycle'
  constructor(private router: Router, private _loader: LoadingIndicatorService) {}

  ngOnInit(): void {
    this._loader.show();
    this.getPermissionsData();
    this.executeHelpMenuItems();
    this.executeAdminMenuItems();
    this.executeReportingMenuItems();
  }
  //#endregion

  //#region 'General Methods'
  private getPermissionsData(): void {
    this.PERMISSIONS = JSON.parse(localStorage.getItem('user'));
    if (this.PERMISSIONS) {
      this.userName = this.PERMISSIONS.userName;
      this.refineryName = this.PERMISSIONS.refineryName;
      this._loader.hide();
    } else {
      setTimeout(() => {
        this.getPermissionsData();
      }, 2000);
    }
  }
  /* tslint:disable-next-line */
  public validatePermissions(type: string): boolean {
    if (this.PERMISSIONS) {
      if (this.PERMISSIONS.permissions.includes('system admin')) {
        return true;
      } else if (this.PERMISSIONS.permissions.includes(type)) {
        return true;
      } else {
        return false;
      }
    } else {
      this.getPermissionsData();
      return false;
    }
  }

  routePage(path: string) {
    this.router.navigateByUrl(path);
  }

  public showEnvironment(): string {
    if (window.location.origin.includes('dev')) {
      return '[Dev]';
    } else if (window.location.origin.includes('test')) {
      return '[Test]';
    } else if (window.location.origin.includes('localhost')) {
      return '[LocalHost]';
    } else {
      return '';
    }
  }

  public executeHelpMenuItems() {
    this.menuItems = [
      {
        label: 'Training Guide',
        url: '../../../../assets/docs/Weld Manager Training_R3.pdf',
        target: '_blank'
      },
      {
        label: 'Bulk Upload Template',
        url: '../../../../assets/docs/BulkUploadTemplate_v2.xlsx',
        target: '_top'
      },
      {
        label: 'Submit Request',
        url: 'https://chevron.service-now.com/itserviceportal?id=sc_cat_item_guide&sys_id=9c08a21edb126450182e1dca48961981&sysparm_u_appid=5054022',
        target: '_blank'
      },
      {
        label: 'Switch Site',
        routerLink: ['/admin/switchsite']
      }
    ];
  }

  public executeAdminMenuItems() {
    this.adminMenuItems = [
      {
        label: 'Email Settings', //email data is managed in the Refinery table
        routerLink: ['/admin/email']
      },
      {
        label: 'Divisions',
        routerLink: ['/admin/division']
      },
      {
        label: 'Filler Materials',
        routerLink: ['/admin/filler-materials']
      },
      {
        label: 'Groups',
        routerLink: ['/admin/group']
      },
      {
        label: 'Materials',
        routerLink: ['/admin/materials']
      },
      {
        label: 'Pipe Sizes',
        routerLink: ['/admin/pipe-sizes']
      },
      {
        label: 'Role Permissions',
        routerLink: ['/admin/rolepermission']
      },
      {
        label: 'RT Percentage',
        routerLink: ['/admin/rtpercentage']
      },
      {
        label: 'Users',
        routerLink: ['/admin/user']
      },
      {
        label: 'Units',
        routerLink: ['/admin/unit']
      },
      {
        label: 'Welder Info',
        items: [
          {
            label: 'Welders',
            routerLink: ['/admin/welder']
          },
          {
            label: 'Contract Company',
            routerLink: ['/admin/contract-company']
          },
          {
            label: 'Contract Welder Photo',
            routerLink: ['/admin/contract-welder']
          }
        ]
      },
      {
        label: 'Weld Process',
        routerLink: ['/admin/weldprocess']
      },
      {
        label: 'WPS',
        routerLink: ['/admin/wps']
      }
    ];
  }

  public executeReportingMenuItems() {
    this.reportingMenuItems = [
      {
        label: 'Weld Stats',
        routerLink: ['/reporting/weld-stats']
      },
      {
        label: 'Welder RT Reporting',
        routerLink: ['/reporting/welder-stats']
      },
      {
        label: 'Welder Stamp ID History',
        routerLink: ['/reporting/welder-stamp-id-history']
      }
    ];
  }
  //#endregion
}
