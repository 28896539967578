import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Welder, WelderCertification, WelderStamp } from '../models';
import { BaseApiService } from '../shared/base-api.service';

@Injectable({
  providedIn: 'root'
})
export class WelderApiService extends BaseApiService<any> {
  //#region 'Angular Life Cycle'
  constructor(protected _http: HttpClient) {
    super(_http, 'welder');
  }
  //#endregion

  //#region 'Get'
  public GetWeldersAsync(): Observable<Welder[]> {
    return this.getAll('/GetWeldersAsync');
  }

  public GetWeldersAsyncById(welderid: string): Observable<Welder> {
    const options = { welderid };
    return this.getById(options, '/GetWeldersAsync');
  }

  public GetActiveWeldersByRefineryAsync(refineryId: number): Observable<Welder[]> {
    const options = { refineryId };
    return this.getAllById(options, '/GetWeldersByRefineryAsync');
  }

  public GetAllWeldersByRefineryAsync(refineryId: number, active: boolean): Observable<Welder[]> {
    const options = { refineryId, active };
    return this.getAllById(options, '/GetWeldersByRefineryAsync');
  }

  public GetWeldersCertificaionAsync(refineryid: string): Observable<WelderCertification> {
    const options = { refineryid };
    return this.getById(options, '/GetWeldersCertificaionAsync');
  }

  public GetStampAllocationsByWelderAsync(welderId: string): Observable<WelderStamp[]> {
    const options = { welderId };
    return this.getById(options, '/GetStampAllocationsByWelderAsync');
  }

  public GetActiveWelderStampsByRefineryAsync(refineryId: number): Observable<WelderStamp[]> {
    const options = { refineryId };
    return this.getById(options, '/GetWelderStampsByRefineryAsync');
  }

  public GetAllWelderStampsByRefineryAsync(refineryId: number, active: boolean): Observable<WelderStamp[]> {
    const options = { refineryId, active };
    return this.getById(options, '/GetWelderStampsByRefineryAsync');
  }
  //#endregion

  //#region 'Create'
  public AddWelderAsync(dto: any): Observable<boolean> {
    return this.createAny(dto, '/AddWelderAsync');
  }
  //#endregion

  //#region 'Update'
  public UpdateWelderAsync(dto: any): Observable<boolean> {
    return this.createAny(dto, '/UpdateWelderAsync');
  }

  public AssignWelderStampAsync(dto: any): Observable<boolean> {
    return this.createAny(dto, '/AssignWelderStampAsync');
  }
  //#endregion

  //#region 'Delete'
  //#endregion

  //#region 'Other'
  //#endregion
}
