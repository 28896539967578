<div class="overlay" style="z-index: 99999" *ngIf="this._srvLoading.isVisible()">
  <div id="loader-wrapper">
    <div class="loaderCenter">
      <div id="loader"></div>
    </div>
    <div class="loadText">
      <span>Getting Information, please wait.</span>
    </div>
  </div>
</div>
