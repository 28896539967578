import { Base } from './Base.model';

export class WeldProcess extends Base {
  constructor() {
    super();
  }

  public weldProcessId: number;
  public weldProcessName: string;
  public weldLogWeldProcesses: any[];
}
