<form [formGroup]="qaForm" id="qaForm">
  <div formArrayName="qaTypesAndLabels">
    <div *ngFor="let process of getQATypeAndLabelRows().controls; index as i">
      <div [formGroupName]="i" class="row">
        <!-- #region QA Types -->
        <div class="col-3">
          <label>QA Types <span style="color: red">*</span></label>
          <p-dropdown
            #qaTypeId
            [maxlength]="50"
            optionValue="id"
            optionLabel="name"
            formControlName="qaTypeID"
            placeholder="Select a QA Type"
            [options]="itemList"
            (onChange)="enableDisableQATypes(); clearQATypeLabel(i); _srvDropDown.GetQATypeLabels(qaTypeId.value, i, showCalendar);"
            appendTo="body"
            [filter]="true"
          ></p-dropdown>
        </div>
        <!-- #endregion QA Types -->

        <!-- #region QA Type Labels -->
        <div class="col-4" *ngIf="_srvDropDown.qaTypeLabelList[i]">
          <label>QA Type Labels <span style="color: red">*</span></label>
          <p-dropdown
            #selectedQATypeLabel
            optionValue="id"
            optionLabel="name"
            formControlName="qaTypeLabelID"
            placeholder="Select a QA Type label"
            [options]="_srvDropDown.qaTypeLabelList[i].items"
            appendTo="body"
            (onChange)="sendData()"
            [filter]="true"
          ></p-dropdown>
        </div>
        <!-- #endregion QA Type Labels -->

        <!--#region QA Dates-->
        <div class="col-3" formArrayName="qaDate" *ngIf="showCalendar == 1 && _srvDropDown.qaTypeLabelList[i]">
          <div *ngFor="let calendar of getQADatesByIndex(i).controls; let j = index">
            <div [formGroupName]="j" *ngIf="_srvDropDown.splitQATypeLabelValues[i][j]">
              <label for="qaDate">{{ _srvDropDown.splitQATypeLabelValues[i][j] }} <span style="color: red">*</span></label>
              <p-calendar
                class="formCalendar"
                name="qaDate"
                formControlName="date"
                dataType="string"
                dateFormat="yy-mm-dd"
                placeholder="Enter Date"
                appendTo="body"
                [readonlyInput]="true"
                [showButtonBar]="true"
                (ngModelChange)="sendData()"
              ></p-calendar>
            </div>
          </div>
        </div>
        <!--#endregion QA Dates-->
      </div>
    </div>
  </div>
</form>
<!-- #endregion QA Completion Dates -->
