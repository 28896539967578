<form [formGroup]="adminForm">
  <ng-content></ng-content>
  <!-- #region 'Users & Welders' -->
  <div class="row" *ngIf="!existing && (router.url === '/admin/user' || router.url === '/admin/welder')">
    <!-- #region 'Chevron/Contractor Switch' -->
    <div *ngIf="router.url === '/admin/welder'" class="row mb10">
      <p-toggleButton
        [onLabel]="'Contract Welder'"
        [offLabel]="'Chevron Employee'"
        [style]="{ width: '100%' }"
        [(ngModel)]="this.isContractWelder"
        [ngModelOptions]="{ standalone: true }"
      ></p-toggleButton>
    </div>
    <!-- #endregion 'Chevron/Contractor Switch' -->

    <!-- #region 'Azure Active Directory Lookup' -->
    <div *ngIf="!isContractWelder">
      <label [for]="dataType.formControlName">{{ dataType.label }} <span style="color: red">*</span></label
      ><br />
      <p-autoComplete
        styleClass="mb-3"
        appendTo="body"
        [ngClass]="{ invalidForm: aadEmployeeValidation }"
        [(ngModel)]="selectedEmployee"
        [ngModelOptions]="{ standalone: true }"
        [suggestions]="suggestions$ | async"
        (completeMethod)="searchEmployee($event.query)"
        (onSelect)="this.clearEmpoyeeError()"
        placeholder="Search User/Welder"
        field="fullName"
        [forceSelection]="true"
        [autoHighlight]="true"
        #employee
      >
        <ng-template let-result pTemplate="item">
          <div>
            {{ result.fullName }}
          </div>
          <div>
            {{ result.emailId }}
          </div>
        </ng-template>
      </p-autoComplete>
    </div>
    <!-- #endregion 'Azure Active Directory Lookup' -->
  </div>
  <!-- #endregion 'Users & Welders' -->

  <!-- #region 'Contract Welder, Dynamic FormControl, Welder Photo, Role, & RT%' -->
  <div class="row">
    <!-- #region 'Contract Welder' -->
    <div *ngIf="isContractWelder && !this.existing">
      <div class="mb10">
        <label for="firstName">First Name <span style="color: red">*</span></label
        ><br />
        <input pInputText class="formInput" type="text" name="firstName" formControlName="firstName" />
      </div>
      <div class="mb10">
        <label for="lastName">Last Name <span style="color: red">*</span></label
        ><br />
        <input pInputText class="formInput" type="text" name="lastName" formControlName="lastName" />
      </div>
      <div class="mb10">
        <label for="emailId">Email</label><br />
        <input pInputText class="formInput" type="text" name="emailId" formControlName="emailId" />
      </div>
      <div class="mb10">
        <label for="contractCompanyId">Contract Company <span style="color: red">*</span></label
        ><br />
        <p-dropdown
          #contractCompany
          appendTo="body"
          optionValue="id"
          optionLabel="name"
          name="contractCompanyId"
          formControlName="contractCompanyId"
          placeholder="Select a Contract Company"
          [options]="_srvDropDown.contractCompanies.items"
          [filter]="true"
        ></p-dropdown>
      </div>
      <div class="mb10">
        <label for="contractorStampNumber">Contractor Stamp <span style="color: red">*</span></label
        ><br />
        <input pInputText class="formInput" type="text" name="contractorStampNumber" formControlName="contractorStampNumber" />
      </div>
    </div>
    <!-- #endregion 'Contract Welder' -->

    <!-- #region 'Dynamic FormControl' -->
    <div *ngIf="router.url !== '/admin/user' && router.url !== '/admin/email' && router.url !== '/admin/rtpercentage' && router.url !== '/admin/switchsite'" class="mb15">
      <div class="mb10">
        <label [for]="dataType.formControlName">{{ dataType.formControlLabel }} <span style="color: red">*</span></label
        ><br />
        <input pInputText class="formInput" type="text" [name]="dataType.formControlName" [formControlName]="dataType.formControlName" />
      </div>

      <!-- #if welder is a contractor -->
      <div *ngIf="router.url === '/admin/welder' && this.isContractWelder && this.existing" class="mb15">
        <div class="mb10">
          <label>First Name <span style="color: red">*</span></label>
          <input pInputText class="nameInput" type="text" [name]="'firstName'" [formControlName]="'firstName'" />

          <label>Last Name <span style="color: red">*</span></label>
          <input pInputText class="nameInput" type="text" [name]="'lastName'" [formControlName]="'lastName'" />
        </div>

        <div class="mb10">
          <label for="contractCompanyID">Contract Company <span style="color: red">*</span></label>
          <p-dropdown
            #contractCompany
            appendTo="body"
            optionValue="id"
            optionLabel="name"
            name="contractCompanyID"
            formControlName="contractCompanyID"
            placeholder="Select a Contract Company"
            [options]="_srvDropDown.contractCompanies.items"
            [filter]="true"
          ></p-dropdown>
        </div>
      </div>
    </div>
    <!-- #endregion 'Dynamic FormControl' -->

    <!-- #region 'Contract Welder Photo' -->

    <!-- #region Photo Thumbnail & Action Buttons -->
    <div *ngIf="isContractWelder && !this.existing" class="row">
      <div class="photoBtns">
        <p-button *ngIf="!photoString" (onClick)="addPhoto()" class="mr-2 green-btn"><em class="pi pi-image mr-2"></em> Add Photo </p-button>
        <img *ngIf="photoString" src="data:image/jpeg;base64,{{ photoString }}" alt="Image" class="thumbnail" />
        <p-button *ngIf="isContractWelder && photoString" (onClick)="editPhoto()" class="mr-2 ml-2"><em class="pi pi-image mr-2"></em> Edit </p-button>
        <p-button *ngIf="photoString" class="red-btn mr-2 ml-2" (onClick)="removeUploadImage()"><em class="pi pi-times mr-2"></em> Remove </p-button>
      </div>
    </div>
    <!-- #endregion Photo Thumbnail & Action Buttons -->

    <!-- #region 'Photo Dialog' -->
    <p-dialog
      header="Contract Welder Photo"
      [style]="{ width: 'auto', 'min-height': '0px' }"
      [(visible)]="displayPhotoDialog"
      [modal]="true"
      [closeOnEscape]="true"
      [draggable]="false"
      [resizable]="false"
      [blockScroll]="false"
    >
      <app-contract-welder-photo
        [photoString]="photoString"
        (onSavePhoto)="savePhoto($event)"
        (onCancel)="openClosePhotoDialog()"
        (onRemoveUploadImage)="removeUploadImage()"
      ></app-contract-welder-photo>
    </p-dialog>
    <!-- #endregion 'Photo Dialog' -->

    <!-- #endregion 'Contract Welder Photo' -->

    <!-- #region 'Role' -->
    <div *ngIf="router.url == '/admin/user'">
      <label for="roleId">Role <span style="color: red">*</span></label
      ><br />
      <p-dropdown
        #role
        appendTo="body"
        optionValue="id"
        optionLabel="name"
        name="roleId"
        formControlName="roleId"
        placeholder="Select a Role"
        [options]="_srvDropDown.roles.items"
        [filter]="true"
      ></p-dropdown>
    </div>
    <!-- #endregion 'Role' -->

    <!-- #region 'User Role Refinery' -->
    <div *ngIf="router.url == '/admin/switchsite'">
      <label for="refineryId">New Refinery <span style="color: red">*</span></label
      ><br />
      <p-dropdown
        #role
        appendTo="body"
        optionValue="id"
        optionLabel="name"
        name="refineryId"
        formControlName="refineryId"
        placeholder="Select a Site"
        [options]="_srvDropDown.userRoles.items"
        [filter]="true"
      ></p-dropdown>
    </div>
    <!-- #endregion 'User Role Refinery' -->

    <!-- #region 'RT Percentage' -->
    <div *ngIf="router.url === '/admin/rtpercentage'">
      <label [for]="dataType.formControlName">{{ dataType.formControlLabel }} <span style="color: red">*</span></label
      ><br />
      <p-inputNumber class="formInput" name="rtPercentage" formControlName="rtPercentage" [maxlength]="3" placeholder="Enter a number [0-100]"></p-inputNumber>
    </div>
    <!-- #endregion 'RT Percentage' -->
  </div>
  <!-- #endregion 'Contract Welder, Dynamic FormControl, Welder Photo, Role, & RT%' -->

  <!-- #region 'Dynamic FormControl' -->
  <div class="row mb10">
    <div *ngIf="dataType.formControlName2">
      <label [for]="dataType.formControlName2">{{ dataType.formControlLabel2 }} <span style="color: red">*</span></label
      ><br />
      <input pInputText class="formInput" type="text" [name]="dataType.formControlName2" [formControlName]="dataType.formControlName2" />
    </div>
  </div>
  <!-- #endregion 'Dynamic FormControl' -->

  <!-- #region 'Division' -->
  <div class="row">
    <div *ngIf="router.url == '/admin/unit'">
      <label for="divisionId">Division <span style="color: red">*</span></label
      ><br />
      <p-dropdown
        #division
        appendTo="body"
        optionValue="id"
        optionLabel="name"
        name="divisionId"
        formControlName="divisionId"
        placeholder="Select a Division"
        [options]="_srvDropDown.divisions.items"
        [filter]="true"
      ></p-dropdown>
    </div>
  </div>
  <!-- #endregion 'Division' -->

  <!-- #region 'Certification Validity Time -->
  <div class="row mb10" *ngIf="router.url == '/admin/email'">
    <div>
      <label for="certValidityDays"> Certification Validity Time <span style="color: red">*</span></label
      ><br />
      <p-inputNumber class="formInput" name="certValidityDays" formControlName="certValidityDays"></p-inputNumber>
    </div>
  </div>
  <!-- #endregion 'Certification Validity Time -->

  <!-- #region 'Reminder Email' -->
  <div class="row mb10" *ngIf="router.url == '/admin/email'">
    <div>
      <label for="reminderEmail1"> Reminder Email 1<span style="color: red">*</span></label
      ><br />
      <p-inputNumber class="formInput" name="reminderEmail1" formControlName="reminderEmail1"></p-inputNumber>
    </div>
  </div>

  <div class="row mb10" *ngIf="router.url == '/admin/email'">
    <div>
      <label for="reminderEmail2"> Reminder Email 2<span style="color: red">*</span></label
      ><br />
      <p-inputNumber class="formInput" name="reminderEmail2" formControlName="reminderEmail2"></p-inputNumber>
    </div>
  </div>

  <div class="row mb10" *ngIf="router.url == '/admin/email'">
    <div>
      <label for="reminderEmail3"> Reminder Email 3<span style="color: red">*</span></label
      ><br />
      <p-inputNumber class="formInput" name="reminderEmail3" formControlName="reminderEmail3"></p-inputNumber>
    </div>
  </div>
  <!-- #endregion 'Reminder Email' -->

  <!-- #region 'Email Copty To' -->
  <div class="row" *ngIf="router.url == '/admin/email'">
    <div>
      <label for="emailsListArray"> Email Copy To</label><br />
      <p-chips
        (onAdd)="isValidEmail($event)"
        (onClear)="clearEmailArray()"
        formControlName="emailsListArray"
        separator=","
        [allowDuplicate]="false"
        [showClear]="this.adminForm.value.emailsListArray.length > 1"
      ></p-chips>
      <p-message severity="error" *ngIf="!adminForm.get('emailValidator').valid" [text]="'Invalid email address'"></p-message>
    </div>
  </div>
  <!-- #endregion 'Email Copty To' -->

  <!-- #region 'Permissions' -->
  <div class="row">
    <div *ngIf="router.url == '/admin/rolepermission'">
      <label for="rolePermissionIdArray">Permissions <span style="color: red">*</span></label
      ><br />
      <p-multiSelect
        appendTo="body"
        optionValue="id"
        optionLabel="name"
        name="rolePermissionIdArray"
        formControlName="rolePermissionIdArray"
        placeholder="Select a Permission"
        [options]="_srvDropDown.permissions.items"
        [filter]="true"
      ></p-multiSelect>
    </div>
  </div>
  <!-- #endregion 'Permissions' -->

  <!-- #region Action Buttons -->
  <div class="row">
    <div class="alignRight">
      <!-- #region Cancel -->
      <p-button (onClick)="cancel()" class="mr-2 mt-4 red-btn action-btn"><em class="pi pi-times-circle mr-2"></em> Cancel </p-button>
      <!-- #endregion Cancel -->

      <!-- #region Submit -->
      <p-button *ngIf="isSubmitButtonDisabled()" class="mr-2 mt-4 action-btn" type="submit" [disabled]="isSubmitButtonDisabled()" pTooltip="Value has not been changed"
        >{{ addUpdateButtonLabel }} {{ dataType.label }}
      </p-button>
      <p-button *ngIf="!isSubmitButtonDisabled()" class="mr-2 mt-4 action-btn" type="submit" (onClick)="submitForm()">{{ addUpdateButtonLabel }} {{ dataType.label }} </p-button>
      <!-- #endregion Submit-->
    </div>
  </div>
  <!-- #endregion Action Buttons-->
</form>
