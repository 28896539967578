import { Base } from './Base.model';

export class WeldLogFillerMaterial extends Base {
  constructor() {
    super();
  }

  public weldLogFillerMaterialId: number;
  public weldLogId: number;
  public fillerMaterialId: number;
  public fillerMaterialName: string;
  public fillerMaterialIssuedDate: string;
  public weldMetalThickness: string;
  public rowSequence: number;
  public qualifiedThickness: string;
}
