import { Injectable } from '@angular/core';
import { ContractorIdentification } from '../models/ContractorIdentification.model';
import { BaseApiService } from '../shared/base-api.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContractorIdentificationApiService extends BaseApiService<ContractorIdentification> {
  //#region 'Angular Life Cycle'
  constructor(protected _http: HttpClient) {
    super(_http, 'contractorIdentification');
  }
  //#endregion 'Angular Life Cycle'

  //#region 'Get'
  public GetContractorIdentificationById(welderId: string): Observable<ContractorIdentification> {
    const options = { welderId };
    return this.getById(options, '/GetContractorIdentificationById');
  }

  public GetActiveContractorIdentificationByRefineryAsync(refineryId: number): Observable<ContractorIdentification[]> {
    const options = { refineryId };
    return this.getAllById(options, '/GetContractorIdentificationByRefineryAsync');
  }

  public GetAllContractorIdentificationByRefineryAsync(
    refineryId: number,
    active: boolean
  ): Observable<ContractorIdentification[]> {
    const options = { refineryId, active };
    return this.getAllById(options, '/GetContractorIdentificationByRefineryAsync');
  }
  //#endregion 'Get'

  //#region 'Update'
  public UpdateContractorPhotoByIdAsync(contractorIdentification: ContractorIdentification): Observable<boolean> {
    return this.createAny(contractorIdentification, '/UpdateContractorPhotoByIdAsync');
  }
  //#endregion 'Update
}
