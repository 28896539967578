export class NotificationToast {
  public key: string;
  public severity: string;
  public summary: string;
  public detail: string;
  public life: number;

  constructor(severity: string, summary: string, detail: string, key: string = 'success', life: number = 3000) {
    this.key = key;
    this.severity = severity;
    this.summary = summary;
    this.detail = detail;
    this.life = life;
  }
}
