import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { WelderStampIdHistory, WelderStampIdHistoryParams } from '../models';
import { BaseApiService } from '../shared/base-api.service';

@Injectable({
  providedIn: 'root'
})
export class WelderStampIdHistoryApiService extends BaseApiService<WelderStampIdHistory[]> {
  constructor(protected _http: HttpClient) {
    super(_http, 'welderstampidhistory');
  }

  //#region 'Get'
  public GetWelderStampIdHistoryAsync(stsParams: WelderStampIdHistoryParams): Observable<WelderStampIdHistory[]> {
    return this.getById(stsParams, '/GetWelderStampIdHistoryAsync');
  }
  //#endregion
}
