import { Injectable } from '@angular/core';
import { WeldLogViewApiService } from '../../core/api/weld-log-view-api.service';
import { WeldLogView } from '../../core/models';
import { UserInfoService } from './user-info.service';

@Injectable()
export class WeldLogViewService {
  constructor(private _srvUSerInfo: UserInfoService, private logViewService: WeldLogViewApiService) {}

  private weldLogViewColumns: string;
  private logView: WeldLogView;

  public get WeldLogView(): string {
    return this.weldLogViewColumns;
  }

  public GetWeldLogView() {
    return new Promise((resolve, reject) => {
      try {
        this.logViewService.GetWeldLogViewsByUserIdAsync(this._srvUSerInfo.userId).subscribe(res => {
          if (res[0]) {
            this.weldLogViewColumns = res[0].additionalColumns;
            resolve(this.weldLogViewColumns);
          } else {
            resolve(null);
          }
        });
      } catch (err) {
        reject(Error(err));
      }
    });
  }

  public UpdateWeldLogView(additionalColumns: string) {
    return new Promise((resolve, reject) => {
      try {
        this.logView = new WeldLogView();
        const tempDate = new Date();
        this.logView.userId = this._srvUSerInfo.userId;
        this.logView.additionalColumns = additionalColumns;
        this.logView.active = true;
        this.logView.reasonForDeletion = 'NULL';
        this.logView.createdBy = this._srvUSerInfo.userId;
        this.logView.createdDateTime = tempDate.toJSON();
        this.logView.updatedBy = this._srvUSerInfo.userId;
        this.logView.updatedDateTime = tempDate.toJSON();
        this.logViewService.WeldLogUpdate(this.logView).subscribe(res => {
          resolve(res);
        });
      } catch (err) {
        reject(Error(err));
      }
    });
  }
}
