import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseApiService } from '../shared/base-api.service';
import { TableHeaders } from './../models/TableHeaders.model';

@Injectable({
  providedIn: 'root'
})
export class TableHeadersApiService extends BaseApiService<TableHeaders> {
  //#region 'Angular Life Cycle'
  constructor(protected _http: HttpClient) {
    super(_http, 'TableHeaders');
  }
  //#endregion

  //#region 'Get'
  public GetTableHeadersAsync(): Observable<TableHeaders[]> {
    return this.getAll('/GetTableHeadersAsync');
  }
  //#endregion
}
