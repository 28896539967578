import { Injectable } from '@angular/core';
import { DropdownApiService } from 'src/app/core/api';
import { DropdownList } from '../models/dropdown-list.model';
import { Dropdown } from '../models/dropdown.model';
import { UserInfoService } from './user-info.service';

@Injectable()
export class DropdownService {
  public weldType = new DropdownList();
  public divisions = new DropdownList();
  public units = new DropdownList();
  public WeldGroups = new DropdownList();
  public WeldProcesses = new DropdownList();
  public wps = new DropdownList();
  public fillerMat = new DropdownList();
  public rtPercent = new DropdownList();
  public jointTypes = new DropdownList();
  public stampAllocation = new DropdownList();
  public userRefinery: number;
  public userId: string;
  public activeOnly = false;
  public materials = new DropdownList();
  public materialForms = new DropdownList();
  public roles = new DropdownList();
  public userRoles = new DropdownList();
  public size = new DropdownList();
  public material = new DropdownList();
  public schedule = new DropdownList();
  public qaType = new DropdownList();
  public qaTypeLabelList = [];
  public splitQATypeLabelValues = [];
  public splitIndividualValues = [];
  public defectType = new DropdownList();
  public hasDefectTypeList = [];
  public permissions = new DropdownList();
  public contractCompanies = new DropdownList();
  public weldTypeIdentifier = new DropdownList();
  public welderType = new DropdownList();

  constructor(private _srvDropdownApi: DropdownApiService, private _srvUserInfo: UserInfoService) {
    this.setRefineryId();
  }

  public setRefineryId(): void {
    if (this._srvUserInfo.refineryId) {
      this.userRefinery = Number(this._srvUserInfo.refineryId);
      this.userId = this._srvUserInfo.userId;
    } else {
      this._srvUserInfo.loadUser();
    }
  }

  public setparams(): void {
    const USER = JSON.parse(localStorage.getItem('user'));
    if (USER) {
      this.userId = USER.userId;
      this.userRefinery = USER.refineryId;
    }
  }

  public fillDropdown(list: DropdownList, res: any, name: string, id: string, type: string) {
    let tempRes = new DropdownList();
    list.data = type;
    list.items = [];
    for (let i = 0; i < res.length; i++) {
      tempRes = res[i];
      list.items[i] = new Dropdown();
      list.items[i].name = tempRes[name];
      list.items[i].id = tempRes[id];
    }
  }

  public loadWeldType(): void {
    this.weldType.data = 'WeldType';
    this.weldType.items = [];
    this.weldType.items[1] = new Dropdown();
    this.weldType.items[1].name = 'Production';
    this.weldType.items[1].id = '1';
    this.weldType.items[0] = new Dropdown();
    this.weldType.items[0].name = 'Continuity';
    this.weldType.items[0].id = '2';
  }

  public loadWelderType(): void {
    this.welderType.data = 'WelderType';
    this.welderType.items = [];
    this.welderType.items[1] = new Dropdown();
    this.welderType.items[1].name = 'Welder';
    this.welderType.items[1].id = '1';
    this.welderType.items[0] = new Dropdown();
    this.welderType.items[0].name = 'Contract Welder';
    this.welderType.items[0].id = '2';
  }

  public GetContractCompanies(): Promise<DropdownList> {
    this.setparams();
    return new Promise((resolve, reject) => {
      try {
        this._srvDropdownApi.GetContractCompanyByRefineryAsync(this.userRefinery).subscribe(res => {
          res.sort((a, b) => (a.contractCompanyName < b.contractCompanyName ? -1 : 1));
          this.fillDropdown(this.contractCompanies, res, 'contractCompanyName', 'contractCompanyId', 'ContractCompany');
        });
        resolve(this.contractCompanies);
      } catch (err) {
        reject(Error(err));
      }
    });
  }

  public GetDivisions(): Promise<DropdownList> {
    this.setparams();
    return new Promise((resolve, reject) => {
      try {
        this._srvDropdownApi.GetDivisionsByRefineryAsync(this.userRefinery).subscribe(res => {
          res.sort((a, b) => (a.divisionDescription < b.divisionDescription ? -1 : 1));
          this.fillDropdown(this.divisions, res, 'divisionDescription', 'divisionId', 'Division');
          resolve(this.divisions);
        });
      } catch (err) {
        reject(Error(err));
      }
    });
  }

  public GetWeldTypeIdentifier(): Promise<DropdownList> {
    return new Promise((resolve, reject) => {
      try {
        this._srvDropdownApi.GetWeldTypeIdentifierAsync().subscribe(res => {
          res.sort((a, b) => (a.weldTypeIdentifierName < b.weldTypeIdentifierName ? -1 : 1));
          this.fillDropdown(
            this.weldTypeIdentifier,
            res,
            'weldTypeIdentifierName',
            'weldTypeIdentifierId',
            'Weld Type'
          );
          resolve(this.weldTypeIdentifier);
        });
      } catch (err) {
        reject(Error(err));
      }
    });
  }

  public GetFillerMat(): Promise<DropdownList> {
    return new Promise((resolve, reject) => {
      try {
        this._srvDropdownApi.GetFillerMaterialsAsync().subscribe(res => {
          this.fillDropdown(this.fillerMat, res, 'fillerMaterialName', 'fillerMaterialId', 'FillerMaterial');
        });
        resolve(this.fillerMat);
      } catch (err) {
        reject(Error(err));
      }
    });
  }

  public GetJointTypes(): Promise<DropdownList> {
    return new Promise((resolve, reject) => {
      try {
        this._srvDropdownApi.GetJointTypesAsync().subscribe(res => {
          res.sort((a, b) => (a.jointTypeName < b.jointTypeName ? -1 : 1));
          this.fillDropdown(this.jointTypes, res, 'jointTypeName', 'jointTypeId', 'JointType');
        });
        resolve(this.jointTypes);
      } catch (err) {
        reject(Error(err));
      }
    });
  }

  public GetMaterial(): Promise<DropdownList> {
    return new Promise((resolve, reject) => {
      try {
        this._srvDropdownApi.GetMaterialAsync().subscribe(res => {
          res.sort((a, b) => a.materialId - b.materialId);
          this.fillDropdown(this.material, res, 'materialName', 'materialId', 'Material');
        });
        resolve(this.material);
      } catch (err) {
        reject(Error(err));
      }
    });
  }

  public GetMaterials(): Promise<DropdownList> {
    return new Promise((resolve, reject) => {
      try {
        this._srvDropdownApi.GetMaterialAsync().subscribe(res => {
          this.fillDropdown(this.materials, res, 'materialName', 'materialId', 'Material');
        });
        resolve(this.materials);
      } catch (err) {
        reject(Error(err));
      }
    });
  }

  public GetMaterialForms(): Promise<DropdownList> {
    return new Promise((resolve, reject) => {
      try {
        this._srvDropdownApi.GetMaterialFormAsync().subscribe(res => {
          this.fillDropdown(this.materialForms, res, 'materialFormName', 'materialFormId', 'MaterialForm');
        });
        resolve(this.materialForms);
      } catch (err) {
        reject(Error(err));
      }
    });
  }

  public GetQAType(): Promise<DropdownList> {
    return new Promise((resolve, reject) => {
      try {
        this._srvDropdownApi.GetQATypeAsync().subscribe(res => {
          this.qaTypeLabelList.push(new DropdownList());

          res.sort((a, b) => (a.qaTypeName < b.qaTypeName ? -1 : 1));
          this.fillDropdown(this.qaType, res, 'qaTypeName', 'qaTypeID', 'QA Type');
        });

        resolve(this.qaType);
      } catch (err) {
        reject(Error(err));
      }
    });
  }

  public GetQATypeLabels(qaTypeID: any, index: any, showCalendar?): Promise<DropdownList> {
    this.splitQATypeLabelValues[index] = [];
    this.hasDefectTypeList[index] = [];
    let qaLabelHasDefectTypeList = [];
    return new Promise((resolve, reject) => {
      try {
        this._srvDropdownApi.GetQATypeLabelAsync(qaTypeID).subscribe(res => {
          res.sort((a, b) => a.qaTypeLabelID - b.qaTypeLabelID);
          res = showCalendar == 1 ? res.filter(obj => obj.noofLabels == 1) : res;

          if (this.qaTypeLabelList[index] == null) {
            this.qaTypeLabelList.splice(index, 0, new DropdownList());
          }
          this.fillDropdown(this.qaTypeLabelList[index], res, 'labelNames', 'qaTypeLabelID', 'QA Type Labels');

          for (let i = 0; i < res.length; i++) {
            qaLabelHasDefectTypeList[i] = res[i].hasDefectType;
            this.hasDefectTypeList[index] = qaLabelHasDefectTypeList;
          }

          resolve(this.qaTypeLabelList[index]);
        });
      } catch (err) {
        reject(Error(err));
      }
    });
  }

  public GetQATypeLabelsPlain(qaTypeID: any) {
    return new Promise((resolve, reject) => {
      try {
        this._srvDropdownApi.GetQATypeLabelAsync(qaTypeID).subscribe(res => {
          resolve(res);
        });
      } catch (err) {
        reject(Error(err));
      }
    });
  }

  public GetDefectType() {
    return new Promise((resolve, reject) => {
      try {
        this._srvDropdownApi.GetDefectTypeAsync().subscribe(res => {
          res.sort((a, b) => (a.defectTypeName < b.defectTypeName ? -1 : 1));
          this.fillDropdown(this.defectType, res, 'defectTypeName', 'defectTypeID', 'Defect Type');
          resolve(this.defectType);
        });
      } catch (err) {
        reject(Error(err));
      }
    });
  }

  public GetRoles(): Promise<DropdownList> {
    return new Promise((resolve, reject) => {
      try {
        this._srvDropdownApi.GetRolesAsync().subscribe(res => {
          res = res.filter(role => role.refineryId === this.userRefinery);
          res.sort((a, b) => (a.roleId < b.roleId ? -1 : 1));
          this.fillDropdown(this.roles, res, 'roleName', 'roleId', 'Role');
        });
        resolve(this.roles);
      } catch (err) {
        reject(Error(err));
      }
    });
  }

  public GetUserRoles(): Promise<DropdownList> {
    this.setparams();
    return new Promise((resolve, reject) => {
      try {
        this._srvDropdownApi.GetAvailableUserRolesByUserAsync(this.userId, this.userRefinery).subscribe(res => {
          this.fillDropdown(this.userRoles, res, 'refineryName', 'refineryId', 'userrole');
        });
        resolve(this.userRoles);
      } catch (err) {
        reject(Error(err));
      }
    });
  }

  public GetRTPercent(): Promise<DropdownList> {
    return new Promise((resolve, reject) => {
      try {
        this._srvDropdownApi.GetRTPercentageByRefineryAsync(this.userRefinery).subscribe(res => {
          res.forEach(rt => (rt['rtPercentageLabel'] = `${rt.rtPercentage}%`));
          res.sort((a, b) => (a.rtPercentage < b.rtPercentage ? -1 : 1));
          this.fillDropdown(this.rtPercent, res, 'rtPercentageLabel', 'rtpercentageId', 'RTPercentage');
        });
        resolve(this.rtPercent);
      } catch (err) {
        reject(Error(err));
      }
    });
  }

  public GetSchedule(): Promise<DropdownList> {
    return new Promise((resolve, reject) => {
      try {
        this._srvDropdownApi.GetScheduleAsync().subscribe(res => {
          res.sort((a, b) => a.sortOrder - b.sortOrder);
          this.fillDropdown(this.schedule, res, 'scheduleName', 'scheduleID', 'Schedule');
        });

        resolve(this.schedule);
      } catch (err) {
        reject(Error(err));
      }
    });
  }

  public GetSize(): Promise<DropdownList> {
    return new Promise((resolve, reject) => {
      try {
        this._srvDropdownApi.GetSizeAsync().subscribe(res => {
          res.sort((a, b) => Number(a.sizeName) - Number(b.sizeName));
          this.fillDropdown(this.size, res, 'sizeName', 'sizeId', 'Size');
        });
        resolve(this.size);
      } catch (err) {
        reject(Error(err));
      }
    });
  }

  public GetStampAllocations(): Promise<DropdownList> {
    return new Promise((resolve, reject) => {
      try {
        this._srvDropdownApi.GetWelderStampsByRefineryAsync(this.userRefinery).subscribe(res => {
          this.stampAllocation.data = 'StampAllocation';
          this.stampAllocation.items = [];
          for (let i in res) {
            if (res[i] != null) {
              const DATA: Dropdown = {
                name: `${res[i].welderName} (${res[i].stampNumber})`,
                id: res[i].stampAllocationId,
                disabled: false
              };
              this.stampAllocation.items.push(DATA);
            }
          }
          resolve(this.stampAllocation);
        });
      } catch (err) {
        reject(Error(err));
      }
    });
  }

  public GetAllStampAllocations(): Promise<DropdownList> {
    return new Promise((resolve, reject) => {
      try {
        this._srvDropdownApi.GetAllWelderStampsByRefineryAsync(this.userRefinery, this.activeOnly).subscribe(res => {
          this.stampAllocation.data = 'StampAllocation';
          this.stampAllocation.items = [];
          for (let i in res) {
            if (res[i] != null) {
              const DATA: Dropdown = {
                name: `${res[i].welderName} (${res[i].stampNumber})`,
                id: res[i].stampAllocationId,
                disabled: false
              };
              this.stampAllocation.items.push(DATA);
            }
          }
          resolve(this.stampAllocation);
        });
      } catch (err) {
        reject(Error(err));
      }
    });
  }

  public GetUnits(): Promise<DropdownList> {
    return new Promise((resolve, reject) => {
      try {
        this._srvDropdownApi.GetUnitsByRefineryAsync(this.userRefinery).subscribe(res => {
          res.sort((a, b) => (a.unitDescription < b.unitDescription ? -1 : 1));
          this.fillDropdown(this.units, res, 'unitDescription', 'unitId', 'Unit');
          resolve(this.units);
        });
      } catch (err) {
        reject(Error(err));
      }
    });
  }

  public GetUnitsByDivisionAsync(divisionId: number): Promise<DropdownList> {
    return new Promise((resolve, reject) => {
      this._srvDropdownApi.GetUnitsByRefineryAndDivisionAsync(this.userRefinery, divisionId).subscribe({
        next: res => {
          res.sort((a, b) => (a.unitDescription < b.unitDescription ? -1 : 1));
          this.fillDropdown(this.units, res, 'unitDescription', 'unitId', 'Unit');
          resolve(this.units);
        },
        error: err => {
          reject(err);
        }
      });
    });
  }

  public GetWeldGroups(): Promise<DropdownList> {
    return new Promise((resolve, reject) => {
      this._srvDropdownApi.GetWeldGroupByRefineryAsync(this.userRefinery).subscribe({
        next: res => {
          res.sort((a, b) => (a.weldGroupName < b.weldGroupName ? -1 : 1));
          this.fillDropdown(this.WeldGroups, res, 'weldGroupName', 'weldGroupId', 'WeldGroups');
          resolve(this.WeldGroups);
        },
        error: err => {
          reject(Error(err));
        }
      });
    });
  }

  public GetWeldProcesses(): Promise<DropdownList> {
    return new Promise((resolve, reject) => {
      try {
        this._srvDropdownApi.GetWeldProcessesAsync().subscribe(res => {
          this.fillDropdown(this.WeldProcesses, res, 'weldProcessName', 'weldProcessId', 'WeldProcess');
        });
        resolve(this.WeldProcesses);
      } catch (err) {
        reject(Error(err));
      }
    });
  }

  public GetWPS(): Promise<DropdownList> {
    return new Promise((resolve, reject) => {
      try {
        this._srvDropdownApi.GetWPsByRefineryAsync(this.userRefinery).subscribe(res => {
          this.fillDropdown(this.wps, res, 'wpsname', 'wpsid', 'WPS');
        });
        resolve(this.wps);
      } catch (err) {
        reject(Error(err));
      }
    });
  }

  //method that splits QA Type Labels into an array based on comma
  public getSplitValues(qaTypeLabelValue: any, index: any) {
    if (qaTypeLabelValue?.name) {
      this.splitQATypeLabelValues.splice(index, 1, qaTypeLabelValue.name.split(','));
    } else {
      this.splitQATypeLabelValues.splice(index, 1, qaTypeLabelValue.selectedOption.name.split(','));
    }
  }

  public addDummy() {
    this.qaTypeLabelList.push(new DropdownList());
  }

  public removeDummy(index: any) {
    this.qaTypeLabelList.splice(index, 1);
    this.splitQATypeLabelValues.splice(index, 1);
  }

  public getAllPermissions(): Promise<DropdownList> {
    return new Promise((resolve, reject) => {
      try {
        this._srvDropdownApi.GetPermissionsAsync().subscribe(res => {
          this.fillDropdown(this.permissions, res, 'permissionName', 'permissionId', 'Permission');
        });
        resolve(this.permissions);
      } catch (err) {
        reject(Error(err));
      }
    });
  }
}
