import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ContractCompany } from '../models';
import { BaseApiService } from '../shared/base-api.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContractCompanyApiService extends BaseApiService<ContractCompany> {
  //#region 'Angular Life Cycle'
  constructor(protected _http: HttpClient) {
    super(_http, 'contractcompany');
  }
  //#endregion 'Angular Life Cycle'

  //#region 'Get'
  public GetContractCompaniesAsync(): Observable<ContractCompany[]> {
    return this.getAll('/GetContractCompanyById');
  }

  public GetContractCompanyByIdAsync(contractCompanyId: number): Observable<ContractCompany> {
    const options = { contractCompanyId };
    return this.getById(options, '/GetContractCompanyById');
  }

  public GetActiveContractCompaniesByRefineryAsync(refineryId: number): Observable<ContractCompany[]> {
    const options = { refineryId };
    return this.getAllById(options, '/GetContractCompanyByRefineryAsync');
  }

  public GetAllContractCompaniesByRefineryAsync(refineryId: number, active: boolean): Observable<ContractCompany[]> {
    const options = { refineryId, active };
    return this.getAllById(options, '/GetContractCompanyByRefineryAsync');
  }
  //#endregion 'Get'

  //#region 'Create'
  public AddContractCompanyAsync(contractCompany: ContractCompany) {
    return this.createAny(contractCompany, '/AddContractCompany');
  }
  //#endregion 'Create'

  //#region 'Update'
  public UpdateContractCompanyAsync(contractCompany: ContractCompany) {
    return this.createAny(contractCompany, '/UpdateContractCompany');
  }
  //#endregion 'Update'
}
