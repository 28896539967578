import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-customize-table-view',
  templateUrl: './customize-table-view.component.html',
  styleUrls: ['./customize-table-view.component.scss']
})
export class CustomizeTableViewComponent implements OnInit {
  @Input() itemsLeft: any[];
  @Input() itemsRight: any[];

  @Output() updateLeft = new EventEmitter<any[]>();
  @Output() updateRight = new EventEmitter<any[]>();
  @Output() closeDialog = new EventEmitter<boolean>();

  private initialLeftCol: any[];
  private initialRightCol: any[];

  ngOnInit(): void {
    this.sortAvailableColumns();
    this.captureInitialSize();
  }

  public captureInitialSize(): void {
    this.initialLeftCol = [...this.itemsLeft];
    this.initialRightCol = [...this.itemsRight];
  }

  public sendUpdates() {
    this.updateLeft.emit(this.itemsLeft);
    this.updateRight.emit(this.itemsRight);
    this.closeDialog.emit(true);
  }

  public sortAvailableColumns() {
    this.itemsLeft.sort((a, b) => (a.header < b.header ? -1 : 1));
  }

  public changeApplyChangesBtnState(): boolean {
    let compareLeftSideLength: boolean = this.itemsLeft.length != this.initialLeftCol.length;
    let compareRightSideLength: boolean = this.itemsRight.length != this.initialRightCol.length;

    if (compareLeftSideLength && compareRightSideLength) {
      return false;
    }

    // Lengths are same
    for (let i = 0; i < this.itemsRight.length; i++) {
      if (this.itemsRight[i].header != this.initialRightCol[i].header) {
        return false;
      }
    }
    return true;
  }

  public cancel(): void {
    this.itemsLeft = [...this.initialLeftCol]
    this.itemsRight = [...this.initialRightCol]
    this.updateLeft.emit(this.itemsLeft);
    this.updateRight.emit(this.itemsRight);
    this.closeDialog.emit(false);
  }
}
