import { Base } from './Base.model';

export class FillerMaterial extends Base {
  constructor() {
    super();
  }

  public fillerMaterialId: number;
  public fillerMaterialName: string;
}
