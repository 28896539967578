import { Injectable } from '@angular/core';
import { catchError, of } from 'rxjs';
import { UserInfoService } from './user-info.service';
import { ContractCompanyApiService } from 'src/app/core/api';
import { ContractCompany } from 'src/app/core/models';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class ContractCompanyService {
  constructor(private _srvContractCompany: ContractCompanyApiService, private _srvUserInfo: UserInfoService) {}

  public GetContractCompanyByIdAsync(contractCompanyId: number): Promise<ContractCompany> {
    return new Promise<ContractCompany>((resolve, reject) => {
      try {
        this._srvContractCompany.GetContractCompanyByIdAsync(contractCompanyId).subscribe(res => {
          resolve(res);
        });
      } catch (err) {
        reject(Error(err));
      }
    });
  }

  public GetContractCompanies(): Promise<ContractCompany[]> {
    return new Promise<ContractCompany[]>((resolve, reject) => {
      try {
        this._srvContractCompany.GetContractCompaniesAsync().subscribe(res => {
          resolve(res);
        });
      } catch (err) {
        reject(Error(err));
      }
    });
  }
  public GetContractCompanyByRefineryAsync(refineryId: number, active?: boolean): Promise<ContractCompany[]> {
    return new Promise<ContractCompany[]>((resolve, reject) => {
      try {
        // Common function to process results
        const processResults = (res: ContractCompany[]) => {
          res.sort((a, b) =>
            a.contractCompanyName.toLocaleLowerCase() < b.contractCompanyName.toLocaleLowerCase() ? -1 : 1
          );
          res.forEach(contractCompany => {
            contractCompany['status'] = contractCompany.active ? 'Active' : 'Inactive';
            contractCompany['contractDates'] = contractCompany.contractEndDate
              ? `${moment(contractCompany.contractStartDate).format('MMMM DD, YYYY')} - ${moment(
                  contractCompany.contractEndDate
                ).format('MMMM DD, YYYY')}`
              : `${moment(contractCompany.contractStartDate).format('MMMM DD, YYYY')} - Current`;
            contractCompany.createdDateTime = `${moment(contractCompany.createdDateTime).format('MMMM DD, YYYY')}`;
            contractCompany.updatedDateTime = contractCompany.updatedDateTime
              ? `${moment(contractCompany.updatedDateTime).format('MMMM DD, YYYY')}`
              : null;
          });
          resolve(res);
        };

        // Choose the service call based on 'active' parameter
        const serviceCall = active
          ? this._srvContractCompany.GetActiveContractCompaniesByRefineryAsync(refineryId)
          : this._srvContractCompany.GetAllContractCompaniesByRefineryAsync(refineryId, active);

        // Subscribe to the service call and process results
        serviceCall.subscribe(processResults);
      } catch (err) {
        reject(Error(err));
      }
    });
  }

  public AddorUpdateContractCompanyAsync(contractCompany: ContractCompany, existing: boolean): Promise<string> {
    contractCompany.contractEndDate = contractCompany.active ? null : new Date().toJSON();
    if (existing) {
      contractCompany.updatedBy = this._srvUserInfo.userName;
      contractCompany.updatedDateTime = new Date().toJSON();
    } else {
      contractCompany.active = true;
      contractCompany.refineryId = Number(this._srvUserInfo.refineryId);
      contractCompany.contractStartDate = new Date().toJSON();
      contractCompany.createdBy = this._srvUserInfo.userName;
      contractCompany.createdDateTime = new Date().toJSON();
    }

    return new Promise<string>((resolve, reject) => {
      try {
        // Select the appropriate service method based on 'existing'
        const serviceMethod = existing
          ? this._srvContractCompany.UpdateContractCompanyAsync(contractCompany)
          : this._srvContractCompany.AddContractCompanyAsync(contractCompany);

        // Common error handling and subscription logic
        serviceMethod
          .pipe(
            catchError(err => {
              return of(err.error.Message);
            })
          )
          .subscribe(res => {
            resolve(res.toString());
          });
      } catch (err) {
        reject(Error(err));
      }
    });
  }
}
