<!-- #region Form Content -->
<form [formGroup]="logForm" id="logForm">
  <!-- #region Action Buttons -->
  <div class="row">
    <div class="col-12 divCenter" style="display: table">
      <!-- #region Cancel -->
      <p-button (onClick)="cancel()" class="red-btn mt-4 action-btn"><em class="pi pi-times-circle mr-2"></em> Cancel</p-button>
      <!-- #endregion Cancel-->

      <!-- #region Submit -->
      <p-button
        class="mt-4 mr-2 action-btn"
        (onClick)="submitNewLog()"
        *ngIf="userPermissions && (userPermissions.permissions.includes('system admin') || userPermissions.permissions.includes('update weld log'))"
        >Update Weld Logs</p-button
      >
      <!-- #endregion Submit -->
    </div>
  </div>
  <!-- #endregion Action Buttons -->

  <!-- #region Field Row -->
  <div formArrayName="fieldsArray">
    <div *ngFor="let field of getFieldRows().controls; index as i">
      <div [formGroupName]="i" class="row" [ngSwitch]="getFieldRows().at(i).value['fieldType']">
        <!-- #region 'Field Type' -->
        <div class="col-4">
          <label for="fieldType">Field</label>
          <p-dropdown
            name="fieldType"
            formControlName="fieldType"
            optionValue="id"
            optionLabel="name"
            placeholder="Select a field"
            appendTo="body"
            [required]="true"
            [options]="fieldDropDownList.items"
            [filter]="true"
            (onChange)="onFieldSelect($event, i)"
          ></p-dropdown>
        </div>
        <!-- #endregion 'Field Type' -->

        <!-- #region EWO -->
        <div class="col-4" *ngSwitchCase="'ewonumber'">
          <label for="ewonumber">EWO <span style="color: red">*</span></label>
          <input
            #ewonum
            pInputText
            type="text"
            class="formInput"
            name="ewonumber"
            formControlName="ewonumber"
            placeholder="Enter EWO"
            maxlength="25"
            oninput="if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
            (keypress)="keyValidation.alphanumericHyphen($event)"
          />
        </div>
        <!-- #endregion -->

        <!-- #region Project/PO # -->
        <div class="col-4" *ngSwitchCase="'projectOrPo'">
          <label for="projectOrPo">Project/PO #</label>
          <input pInputText type="text" class="formInput" name="projectOrPo" formControlName="projectOrPo" placeholder="Enter Project/PO #" maxlength="25" />
        </div>
        <!-- #endregion -->

        <!-- #region Group -->
        <div class="col-4" *ngSwitchCase="'weldGroupId'">
          <label for="weldGroupId">Group <span style="color: red">*</span></label>
          <p-dropdown
            optionValue="id"
            optionLabel="name"
            name="weldGroupId"
            formControlName="weldGroupId"
            placeholder="Select a Group"
            appendTo="body"
            [options]="_srvDropDown.WeldGroups.items"
            [filter]="true"
          ></p-dropdown>
        </div>
        <!-- #endregion Group -->

        <!-- #region WPS -->
        <div class="col-4" *ngSwitchCase="'wpsid'">
          <label for="wpsid">WPS</label>
          <p-dropdown
            name="wpsid"
            formControlName="wpsid"
            optionValue="id"
            optionLabel="name"
            placeholder="Select a WPS"
            appendTo="body"
            [options]="_srvDropDown.wps.items"
            [filter]="true"
          ></p-dropdown>
        </div>
        <!-- #endregion WPS -->

        <!-- #region Pipe Spec -->
        <div class="col-4" *ngSwitchCase="'spec'">
          <label for="spec">Pipe Spec <span style="color: red">*</span></label>
          <input
            pInputText
            class="formInput"
            type="text"
            name="spec"
            formControlName="spec"
            [ngModel]="this.logForm.value.spec"
            (ngModelChange)="this.logForm.value.spec ? (this.logForm.value.spec = $event.toUpperCase()) : null"
            placeholder="Enter Spec"
            maxlength="25"
            (keypress)="keyValidation.alphanumeric($event)"
          />
        </div>
        <!-- #endregion Pipe Spec -->

        <!-- #region RT Percentage -->
        <div class="col-4" *ngSwitchCase="'rtpercentageId'">
          <label for="rtpercentageId">RT Percentage <span style="color: red">*</span></label>
          <p-dropdown
            name="rtpercentageId"
            formControlName="rtpercentageId"
            optionValue="id"
            optionLabel="name"
            placeholder="Select a RT Percent"
            appendTo="body"
            [options]="_srvDropDown.rtPercent.items"
            [filter]="true"
          ></p-dropdown>
        </div>
        <!-- #endregion RT Percentage -->

        <!-- #region Drawing -->
        <div class="col-6" *ngSwitchCase="'drawing'">
          <label for="drawing">Drawing <span style="color: red" [hidden]="hideForMCBU">*</span></label>
          <input pInputText class="formInput" type="text" name="drawing" formControlName="drawing" placeholder="Enter Drawing" maxlength="50" />
        </div>
        <!-- #endregion -->

        <!-- #region Joint Type -->
        <div class="col-4" *ngSwitchCase="'jointTypeId'">
          <label for="jointTypeId">Joint Type <span style="color: red">*</span></label>
          <p-dropdown
            #jointType
            optionValue="id"
            optionLabel="name"
            name="jointTypeId"
            formControlName="jointTypeId"
            placeholder="Select a Joint Type"
            appendTo="body"
            [options]="_srvDropDown.jointTypes.items"
            [filter]="true"
          ></p-dropdown>
        </div>
        <!-- #endregion -->

        <!-- #region Weld Date -->
        <div class="col-4 calendarDiv" *ngSwitchCase="'dateWelded'">
          <label for="dateWelded">Weld Date <span style="color: red">*</span></label>
          <p-calendar
            class="formCalendar"
            name="dateWelded"
            formControlName="dateWelded"
            dataType="string"
            dateFormat="yy-mm-dd"
            placeholder="Enter Date"
            appendTo="body"
            [readonlyInput]="true"
            [showButtonBar]="true"
          ></p-calendar>
        </div>
        <!-- #endregion -->

        <!-- #region Add, Delete Buttons -->
        <div class="col-2 bottom">
          <!-- #region Add -->
          <p-button
            *ngIf="this.getFieldRows().getRawValue().length < 9"
            class="green-btn"
            icon="pi pi-plus"
            pTooltip="Add new row"
            tooltipPosition="right"
            [disabled]="false"
            (onClick)="addAllocationRow()"
          ></p-button>
          <!-- #endregion -->

          <!-- #region Delete -->
          <p-button
            *ngIf="this.getFieldRows().getRawValue().length > 1"
            class="red-btn ml-2"
            icon="pi pi-trash"
            pTooltip="Delete row"
            tooltipPosition="right"
            [disabled]="false"
            (onClick)="removeAllocationRow(i)"
          ></p-button>
          <!-- #endregion -->
        </div>
        <!-- #endregion Add, Delete Buttons -->
      </div>
    </div>
  </div>
  <!-- #endregion Field Row -->
</form>
<!-- #endregion Form Content -->
