import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import {
  TableHeaders,
  WeldStats,
  WeldStatsParams,
  WelderStampIdHistory,
  WelderStampIdHistoryParams,
  WelderStats,
  WelderStatsParams
} from '../../../core/models';
import { DropdownList } from '../../../data/models';
import {
  DropdownService,
  ExportToExcelService,
  LoadingIndicatorService,
  KeypressValidationService,
  ReportingService
} from '../../../data/services';
import { NotificationService, NotificationToast } from '../../shared/notification';

@Component({
  selector: 'app-reporting',
  templateUrl: './reporting.component.html',
  styleUrls: ['./reporting.component.scss'],
  providers: [ReportingService]
})
export class ReportingComponent implements OnInit {
  //#region 'Variables'

  // dropdowns for filters
  public divisions = new DropdownList();
  public units = new DropdownList();
  public groups = new DropdownList();
  public welders = new DropdownList();
  public allwelders = new DropdownList();

  public weldStatsParams = new WeldStatsParams();
  public activeWeldFilterParams = new WeldStatsParams(); //holds the active filters; only updates on page initialization and submit filters
  public welderStatsParams = new WelderStatsParams();
  public welderStampIdHistoryParams = new WelderStampIdHistoryParams();
  public activeWelderFilterParams = new WelderStatsParams(); //holds the active filters; only updates on page initialization and submit filters
  public activeWelderStampIdFilterParams = new WelderStampIdHistoryParams(); //holds the active filters; only updates on page initialization and submit filters
  public inactiveFilters = false; //used for custom styling with inactive filters

  // table data
  public stats = new WeldStats();
  public welderStats = new Array<WelderStats>();
  public welderStampIdHistory = new Array<WelderStampIdHistory>();

  // excel export
  public exportColumns: any[];
  private excelWeldStatsHeaders = [];
  private excelJointTypeHeaders = [];
  private excelQATypeHeaders = [];
  private excelWelderStatsHeaders = [];
  private excelWelderStampAllocIdHeaders = [];
  //#endregion 'Variables'

  //#region 'Angular Life Cycle'
  constructor(
    private dropdownService: DropdownService,
    private _reportServ: ReportingService,
    private _loader: LoadingIndicatorService,
    private _notifSrv: NotificationService,
    public keyValidation: KeypressValidationService,
    private _expExcel: ExportToExcelService,
    public router: Router
  ) {}

  ngOnInit() {
    this._loader.asyncShow(2);
    let tempDate = new Date();
    let headers: TableHeaders[] = JSON.parse(localStorage.getItem('tableHeaders'));

    this.weldStatsParams.weldtodate = moment(tempDate).format('YYYY-MM-DD');
    this.weldStatsParams.weldfromdate = moment(tempDate).subtract(5, 'M').format('YYYY-MM-DD');
    this.activeWeldFilterParams = JSON.parse(JSON.stringify(this.weldStatsParams)); //storing default (active) filters

    this.welderStatsParams.todate = moment(tempDate).format('YYYY-MM-DD');
    this.welderStatsParams.fromdate = moment(tempDate).subtract(5, 'M').format('YYYY-MM-DD');
    this.activeWelderFilterParams = JSON.parse(JSON.stringify(this.welderStatsParams)); //storing default (active) filters

    const baselineYear: number = new Date(1990, 0, 1).getFullYear();
    const todayYear: number = new Date().getFullYear();
    const yearDifference = Math.abs(todayYear - baselineYear);
    this.welderStampIdHistoryParams.allocationIdToDate = moment(tempDate).format('YYYY-MM-DD');
    this.welderStampIdHistoryParams.allocationIdFromDate = moment(tempDate)
      .subtract(yearDifference, 'years')
      .format('YYYY-MM-DD');
    this.activeWelderStampIdFilterParams = JSON.parse(JSON.stringify(this.welderStampIdHistoryParams)); //storing default (active) filters

    this.getDropdowns();
    this.getStats();
    this.loadHeaders(headers);
  }
  //#endregion 'Angular Life Cycle'

  //#region 'Load Headers'
  private loadHeaders(headers: TableHeaders[]) {
    this.loadHeaderArray(headers, 'excelWeldStatsHeaders');
    this.loadHeaderArray(headers, 'excelJointTypeHeaders');
    this.loadHeaderArray(headers, 'excelQATypeHeaders');
    this.loadHeaderArray(headers, 'excelWelderStatsHeaders');
    this.loadHeaderArray(headers, 'excelWelderStampAllocIdHeaders');
  }

  private loadHeaderArray(headers: TableHeaders[], arrayName: string) {
    let filteredResponse = headers.filter(x => x.componentName == 'reporting' && x.arrayName == arrayName);

    for (let i = 0; i < filteredResponse.length; i++) {
      this[arrayName].push({
        field: filteredResponse[i].field,
        header: filteredResponse[i].header,
        excelWidth: filteredResponse[i].field.length + 5
      });
    }
  }
  //#endregion

  //#region 'Load'
  private async getStats() {
    switch (this.router.url) {
      case '/reporting/weld-stats':
        this.stats = await this._reportServ.GetWeldStats(this.weldStatsParams);
        break;
      case '/reporting/welder-stats':
        this.welderStats = await this._reportServ.GetWelderStats(this.welderStatsParams);
        break;
      case '/reporting/welder-stamp-id-history':
        this.welderStampIdHistory = await this._reportServ.GetWelderStampIdHistory(this.welderStampIdHistoryParams);
        break;
    }

    setTimeout(() => {
      this._loader.asyncHide();
    }, 1000);
  }

  private async getDropdowns() {
    this.divisions = await this.dropdownService.GetDivisions();
    this.units = await this.dropdownService.GetUnits();
    this.groups = await this.dropdownService.GetWeldGroups();
    this.welders = await this.dropdownService.GetStampAllocations();
    this.allwelders = await this.dropdownService.GetAllStampAllocations();
    this._loader.asyncHide();
  }

  public async getUnits() {
    this._loader.show();
    if (this.weldStatsParams.divisionid === 0) {
      this.units = await this.dropdownService.GetUnits();
      setTimeout(() => {
        this._loader.hide();
      }, 200);
    } else {
      this.weldStatsParams.unitid = null;
      this.units = await this.dropdownService.GetUnitsByDivisionAsync(this.weldStatsParams.divisionid);
      setTimeout(() => {
        this._loader.hide();
      }, 200);
    }
  }
  //#endregion 'Load'

  //#region 'Notifications'
  private showNotification(msg: NotificationToast) {
    this._notifSrv.sendNotification(msg);
  }
  //#endregion 'Notifications'

  //#region 'General Methods'
  public submitFilter() {
    switch (this.router.url) {
      case '/reporting/weld-stats':
        for (const filter in this.weldStatsParams) {
          if (this.weldStatsParams[filter] != this.activeWeldFilterParams[filter]) {
            this.inactiveFilters = true;
            break;
          }
        }
        break;
      case '/reporting/welder-stats':
        for (const filter in this.welderStatsParams) {
          if (this.welderStatsParams[filter] != this.activeWelderFilterParams[filter]) {
            this.inactiveFilters = true;
            break;
          }
        }
        break;
      case '/reporting/welder-stamp-id-history':
        for (const filter in this.welderStampIdHistoryParams) {
          if (this.welderStampIdHistoryParams[filter] != this.activeWelderStampIdFilterParams[filter]) {
            this.inactiveFilters = true;
            break;
          }
        }
        break;
    }
    if (
      Date.parse(this.weldStatsParams.weldfromdate) > Date.parse(this.weldStatsParams.weldtodate) ||
      Date.parse(this.welderStatsParams.fromdate) > Date.parse(this.welderStatsParams.todate) ||
      Date.parse(this.welderStampIdHistoryParams.allocationIdFromDate) >
        Date.parse(this.welderStampIdHistoryParams.allocationIdToDate) ||
      Date.parse(this.welderStatsParams.fromdate) > Date.parse(this.welderStatsParams.todate)
    ) {
      this.showNotification(
        new NotificationToast('error', 'Error: cannot fetch logs, invalid date selection', '', 'error', 6000)
      );
    } else {
      this._loader.show();
      this.getStats();
      this.checkForInactiveFilters();
    }
  }

  //weldStatsParams is tied to the dropdowns.. checking for changes against the stored active params
  public checkForInactiveFilters() {
    this.inactiveFilters = false;

    switch (this.router.url) {
      case '/reporting/weld-stats':
        for (const filter in this.weldStatsParams) {
          if (this.weldStatsParams[filter] != this.activeWeldFilterParams[filter]) {
            this.inactiveFilters = true;
            break;
          }
        }
        break;
      case '/reporting/welder-stats':
        for (const filter in this.welderStatsParams) {
          if (this.welderStatsParams[filter] != this.activeWelderFilterParams[filter]) {
            this.inactiveFilters = true;
            break;
          }
        }
        break;
      case '/reporting/welder-stamp-id-history':
        this.inactiveFilters = false;
        for (const filter in this.welderStampIdHistoryParams) {
          if (this.welderStampIdHistoryParams[filter] != this.activeWelderStampIdFilterParams[filter]) {
            this.inactiveFilters = true;
            break;
          }
        }
        break;
    }
  }
  //#endregion 'General Methods'

  //#region 'Export to Excel'
  public exportToExcel() {
    switch (this.router.url) {
      case '/reporting/weld-stats':
        this._expExcel.weldStatsTablesToExcel(
          this.excelWeldStatsHeaders,
          this.excelJointTypeHeaders,
          this.excelQATypeHeaders,
          this.stats,
          this.activeWeldFilterParams
        );
        break;
      case '/reporting/welder-stats':
        this._expExcel.welderStatsTablesToExcel(
          this.excelWelderStatsHeaders,
          this.welderStats,
          this.activeWelderFilterParams
        );
        break;
      case '/reporting/welder-stamp-id-history':
        this._expExcel.welderStampAllocationIdTablesToExcel(
          this.excelWelderStampAllocIdHeaders,
          this.welderStampIdHistory,
          this.activeWelderStampIdFilterParams
        );
        break;
    }
  }
  //#endregion 'Export to Excel'
}
