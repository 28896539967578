import { QAType } from './../../../../core/models/QAType.model';
import { Component, EventEmitter, Output, Input } from '@angular/core';
import { WeldLog } from 'src/app/core/models';
import { LoadingIndicatorService, WeldLogService } from 'src/app/data/services';
import * as XLSX from 'xlsx';
import { BulkUploadValidationApiService } from 'src/app/core/api/bulk-upload-api.service';

@Component({
  selector: 'app-bulk-upload',
  templateUrl: './bulk-upload.component.html',
  styleUrls: ['./bulk-upload.component.scss']
})
export class BulkUploadComponent {
  public displayBulkUploadForm = false;
  public bulkUploadRows: any[];
  public weldLogEntries: any[];
  public spreadsheetCols: any[];
  public errors: any[] = [];
  public rowNumbers = [];
  public highlightedCells: any[] = [];
  public fieldIdsArray: any[] = [];
  public containsAllWeldLogsArray: WeldLog[] = [];
  public fileUploadValue: any;
  public faultyRows: any[] = [];
  public faultyWeldLogEntries: any[] = [];
  public showErrorRows: boolean = false;

  @Input() public requiredFieldsLog: number; // 0 = defaultFields, 1 = MCBU Fields
  @Output() public onCancel: EventEmitter<any> = new EventEmitter<any>();
  @Output() public onSuccess: EventEmitter<any> = new EventEmitter<any>();
  @Output() public onFail: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private _srvBulkUpload: BulkUploadValidationApiService,
    private _loader: LoadingIndicatorService,
    private _srvWeldLog: WeldLogService
  ) {}

  private initColumns(optionalHeaders: string[]) {
    this.bulkUploadRows[0].forEach((colHeading, index) => {
      const colWidth = colHeading.length * 15.5 + 'px';
      const col = {
        header: colHeading,
        width: colWidth,
        index: index,
        required: true,
        type: 'string'
      };

      if (col.header == 'Weld No.' || col.header == 'Size' || col.header == 'RT %') {
        col.type = 'number';
      } else if (col.header == 'Filler Mat. Issued Date' || col.header == 'Date Welded' || col.header == 'QA Date') {
        col.type = 'date';
      }

      if (optionalHeaders.includes(col.header)) {
        col.required = false;
      }
      this.spreadsheetCols.push(col);
    });
  }

  //#region 'file Upload'
  public onFileChange(evt: any, fileInput: any) {
    const target: DataTransfer = <DataTransfer>evt.target;
    const optionalBulkUploadHeadersDefault = [
      'Weld Group',
      'Weld Modifier',
      'Comments',
      'Project/PO #',
      'Material From',
      'Material To',
      'Size',
      'Schedule',
      'Weld Repair',
      'Filler Mat. Needed',
      'Filler Mat. Issued Date',
      'QA Type',
      'QA Type Label',
      'QA Date',
      'Defect Type',
      'Wall Thickness',
      'Weld Type' // Weld Type Identifier in the database
    ];

    const optionalBulkUploadHeadersMCBU = [
      'Weld Group',
      'Drawing',
      'Weld Modifier',
      'Comments',
      'Project/PO #',
      'Material From',
      'Material To',
      'Size',
      'Schedule',
      'Weld Repair',
      'Filler Mat. Needed',
      'Filler Mat. Issued Date',
      'QA Type',
      'QA Type Label',
      'QA Date',
      'Defect Type',
      'Wall Thickness'
    ];

    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      this.fileUploadValue = fileInput;
      this.clearData();
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary', sheetStubs: true });
      const wsname = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      const data = XLSX.utils.sheet_to_json(ws, { raw: false, header: 1, defval: '' });
      this.bulkUploadRows = data;
      this.spreadsheetCols = [];

      if (this.requiredFieldsLog === 1) {
        this.initColumns(optionalBulkUploadHeadersMCBU);
      } else {
        this.initColumns(optionalBulkUploadHeadersDefault);
      }

      const specificRows = [];
      const validColIndices = [];

      this.bulkUploadRows.forEach((row, index) => {
        // skip the first row (headers)
        if (index == 0) return;

        //check if all the cells are empty, if so, then we skip the row
        if (row.every(cell => cell == '')) return;

        specificRows.push(row);

        validColIndices.push(index);
      });

      for (let i = 0; i < specificRows.length; i++) {
        this.rowNumbers.push(validColIndices[i]);
      }

      this.verifyTableHeaders(this.spreadsheetCols);

      const combineWelders = this.combinedWelderTable(specificRows);

      //if the table is not valid, then we return. The error will be displayed on the screen
      if (!combineWelders) {
        return;
      }

      //loop thru specificRows, if the index matches that of Weld Processes in the spreadsheetCols,
      //then take the string, split it by space, create a new comma separated string, and replace the old string
      for (const row of specificRows) {
        //if the index matches that of Weld Processes or Filler Mat. Needed in the spreadsheetCols,
        const weldProcessIndex = this.spreadsheetCols.find(o => o.header === 'Weld Process');
        const fillerMatIndex = this.spreadsheetCols.find(o => o.header === 'Filler Mat. Needed');

        if (!weldProcessIndex || !fillerMatIndex) {
          this.errors.push('The spreadsheet does not match the template.');
          return;
        }

        this.commaSeparateValues(row, weldProcessIndex.index);
        this.commaSeparateValues(row, fillerMatIndex.index);
      }

      this.validateTable(specificRows);
      this.weldLogEntries = specificRows;
      this.addExcelRowNumbers();
      this.performBackendValidation();
    };

    reader.readAsBinaryString(target.files[0]);
  }

  //#endregion

  private addExcelRowNumbers() {
    // for all weldLogEntries, at the 0th index, add the corresponding row number
    this.weldLogEntries.forEach((row, i) => {
      // why i + 2? Because in excel, the first row = header, the second row = first row of data, and array index starts at 0
      row.splice(0, 0, i + 2);
    });
  }

  private commaSeparateValues(row, correctIdx) {
    if (row[correctIdx] != '') {
      const splitStr = row[correctIdx].trim().split(/\s+/);
      const result = splitStr.join(', ');
      row[correctIdx] = result;
    }
  }

  private compareRows(prevRow: any[], currentRow: any[], ind1: number, ind2: number, ind3: number): boolean {
    // comparison logic
    if (prevRow[ind1] !== currentRow[ind1]) return false;
    if (prevRow[ind2] !== currentRow[ind2]) return false;
    if (prevRow[ind3] !== currentRow[ind3]) return false;
    return true;
  }

  private combineRows(prevRow: any[], currentRow: any[], ind1: number, ind2: number, ind3: number) {
    // update current row
    for (let j = 0; j < this.spreadsheetCols.length; j++) {
      if (j != ind1 && j != ind2 && j != ind3) {
        currentRow[j] = prevRow[j];
      }
    }
  }
  private combinedWelderTable(tableData: any[]) {
    // Add EWO Number and Weld Modifier to headers
    const headers = ['EWO Number', 'Weld No.', 'Weld Modifier', 'Contractor Stamp', 'Weld Process', 'Accept/Reject'];
    const [ewoNumber, weldNumber, weldModifier, contractorStamp, weldProcess, acceptedRejected] = headers.map(header =>
      this.spreadsheetCols.find(o => o.header === header)
    );

    if (!ewoNumber || !weldNumber || !weldModifier || !contractorStamp || !weldProcess || !acceptedRejected) {
      this.errors.push('The spreadsheet does not match the template.');
      return false;
    }

    const ewoNumberIndex = ewoNumber.index;
    const weldNumberIndex = weldNumber.index;
    const weldModifierIndex = weldModifier.index;
    const contractorStampIndex = contractorStamp.index;
    const weldProcessIndex = weldProcess.index;
    const acceptedRejectedIndex = acceptedRejected.index;

    // Compare rows based on EWO Number, Weld No., and Weld Modifier
    tableData.forEach((currentRow, i) => {
      if (i === 0) return; // Skip the first row (no previous row)

      const prevRow = tableData[i - 1];

      // compare rows
      if (this.compareRows(prevRow, currentRow, ewoNumberIndex, weldNumberIndex, weldModifierIndex)) {
        this.combineRows(prevRow, currentRow, contractorStampIndex, weldProcessIndex, acceptedRejectedIndex);
      }
    });

    return true;
  }

  private validateTable(tableData: any[]) {
    //1: The table headers match the expected headers
    //2: The table headers do not exceed the expected headers
    //3: The table has at least 1 row of data
    //4: Each cell matches its type
    this._loader.show();
    this.verifyTableData(tableData);
  }

  private convertDate(cell: string, i: number, j: number): string {
    // blank technically isn't invalid
    if (!cell) return '';

    if (!this.isDateValid(cell)) {
      this.highlightedCells.push({ row: i, column: j });
      // pass the value back so it shows the user what's invalid.
      return cell;
    }

    //convert to date
    const date = new Date(cell);
    return date.toISOString().split('T')[0];
  }

  private convertNumber(cell: string, i: number, j: number): string {
    if (!cell) return '';
    //expecting a number and not empty
    const resultantNumber = Number(cell);
    if (isNaN(resultantNumber)) {
      this.highlightedCells.push({ row: i, column: j });
    }

    return cell;
  }

  private convertAcceptReject(cell: string, i: number, j: number): string {
    // Blank isn't invalid
    if (!cell) return '';

    //convert to lower case
    cell = cell.toLowerCase().trim();
    if (!cell.startsWith('a') && !cell.startsWith('r')) {
      this.highlightedCells.push({ row: i, column: j });
      // pass the value back so it shows the user what's invalid.
      return cell;
    }
    return cell.startsWith('a') ? 'Accept' : 'Reject';
  }

  private checkForEmpty(cell: string, i: number, j: number) {
    //mandatory and empty
    if (this.spreadsheetCols[j].required && !cell) {
      this.highlightedCells.push({ row: i, column: j });
    }
  }

  //specificRows is the table data
  private verifyTableData(tableData: any[]) {
    //3: The table has at least 1 row of data: NO HIGHLIGHTING THE CELLS
    if (tableData.length == 0) {
      this.errors.push('The table has no data.');
      return;
    }

    //4. The table columns are limited to 32: NO HIGHLIGHTING THE CELLS
    for (const data of tableData) {
      if (data.length != 32) {
        this.errors.push('The table has more than 32 columns.');
        return;
      }
    }

    //4: Each cell matches its type
    //fix the types
    for (let i = 0; i < tableData.length; i++) {
      const row = tableData[i];

      for (let j = 0; j < row.length; j++) {
        const cell = row[j];
        const headerEntry = this.spreadsheetCols[j];
        //Conversion to date
        if (headerEntry.type == 'date') {
          row[j] = this.convertDate(cell, i, j);
        }

        //Conversion to number
        if (headerEntry.type == 'number') {
          row[j] = this.convertNumber(cell, i, j);
        }

        if (headerEntry.header == 'Accept/Reject') {
          row[j] = this.convertAcceptReject(cell, i, j);
        }

        this.checkForEmpty(cell, i, j);
      }
    }
  }

  private isDateValid(stringDate) {
    const date = new Date(stringDate);
    if (isNaN(date.getTime())) {
      return false;
    }
    return true;
  }

  public isRowHighlighted(row: number): boolean {
    // why -2? Because in excel, the first row = header, the second row = first row of data, and array index starts at 0
    row -= 2;
    return this.highlightedCells.some(cell => cell.row === row);
  }

  public isCellHighlighted(row: number, col: number): boolean {
    row -= 2;
    col -= 1;
    return this.highlightedCells.some(cell => cell.row === row && cell.column === col);
  }

  private verifyTableHeaders(tableHeaders: any[]) {
    let expectedHeaders = [
      'Business Unit',
      'Facility',
      'Unit',
      'Contract Company',
      'Weld Group',
      'EWO Number',
      'Pipe Spec',
      'RT %',
      'Drawing',
      'Joint Type',
      'WPS',
      'Weld No.',
      'Weld Modifier',
      'Date Welded',
      'Comments',
      'Project/PO #',
      'Material From',
      'Material To',
      'Size',
      'Schedule',
      'Weld Repair',
      'Contractor Stamp',
      'Weld Process',
      'Accept/Reject',
      'Filler Mat. Needed',
      'Filler Mat. Issued Date',
      'QA Type',
      'QA Type Label',
      'QA Date',
      'Defect Type',
      'Wall Thickness',
      'Weld Type' // Weld Type Identifier in the database
    ];

    if (tableHeaders.length != expectedHeaders.length) {
      //add the error to the error list
      this.errors.push('The table headers do not match the expected headers.');
      return false;
    }

    for (let i = 0; i < tableHeaders.length; i++) {
      if (tableHeaders[i].header != expectedHeaders[i]) {
        //add the error to the error list
        this.errors.push('The table headers do not match the expected headers.');
        return false;
      }
    }

    return true;
  }

  public clearData() {
    this.weldLogEntries = [];
    this.spreadsheetCols = [];
    this.bulkUploadRows = [];
    this.errors = [];
    this.weldLogEntries = [];
    this.errors = [];
    this.highlightedCells = [];
    this.containsAllWeldLogsArray = [];
    this.faultyRows = [];
    this.faultyWeldLogEntries = [];
  }

  public cancel() {
    if (this.fileUploadValue?.value !== undefined) {
      this.fileUploadValue.value = '';
    }

    this.clearData();
    this.onCancel.emit();
  }

  public upload() {
    this.getIDsForWeldLog();
    this._loader.show();
  }

  private performBackendValidation() {
    // Why slicing? Because the first element is the row number and the backend doesn't need it (returns an error otherwise)
    let weldLogEntries = this.weldLogEntries.map(entry => entry.slice(1));
    this._srvBulkUpload.GetInvalidRowsAndColumnsAsync(weldLogEntries).subscribe(res => {
      this.backendValidationRowsCols(res);
      this._loader.hide();
    });
  }

  private backendValidationRowsCols(res: any) {
    let tableEntries = res;
    for (let currSubArray of tableEntries) {
      //check if this.highlightedCells already has this row and column
      if (this.highlightedCells.some(cell => cell.row === currSubArray[0] && cell.column === currSubArray[1])) {
        continue;
      }

      this.highlightedCells.push({ row: currSubArray[0], column: currSubArray[1] });
    }
    this.filterFaultyWeldLogEntries();
  }

  private getIDsForWeldLog() {
    // Why slicing? Because the first element is the row number and the backend doesn't need it (returns an error otherwise)
    let weldLogEntries = this.weldLogEntries.map(entry => entry.slice(1));
    this._srvBulkUpload.GetBulkUploadIdsFromTable(weldLogEntries).subscribe(res => {
      this.fieldIdsArray = res;
      this.prepareIndividualWeldLog(this.fieldIdsArray);
    });
  }

  private prepareIndividualWeldLog(tableIds: string[][]) {
    // Add EWO Number and Weld Modifier to headers
    const headers = ['EWO Number', 'Weld No.', 'Weld Modifier', 'Contractor Stamp', 'Weld Process', 'Accept/Reject'];
    const [ewoNumber, weldNumber, weldModifier, contractorStamp, weldProcess, acceptedRejected] = headers.map(header =>
      this.spreadsheetCols.find(o => o.header === header)
    );

    const ewoNumberIndex = ewoNumber.index;
    const weldNumberIndex = weldNumber.index;
    const weldModifierIndex = weldModifier.index;
    const contractorStampIndex = contractorStamp.index;
    const weldProcessIndex = weldProcess.index;
    const acceptedRejectedIndex = acceptedRejected.index;

    let map = new Map();

    // Iterate over each array and create a unique key based on EWO Number, Weld No., and Weld Modifier
    for (let row of tableIds) {
      let uniqueKey = `${row[ewoNumberIndex]}-${row[weldNumberIndex]}-${row[weldModifierIndex]}`;

      let rtacceptedRejected: boolean | null = null;
      const acceptedRejectedValue = row[acceptedRejectedIndex].toLowerCase();
      if (acceptedRejectedValue.startsWith('a')) {
        rtacceptedRejected = true;
      } else if (acceptedRejectedValue.startsWith('r')) {
        rtacceptedRejected = false;
      }

      let stampProcessAcceptReject = {
        stampid: Number(row[contractorStampIndex]),
        processids: row[weldProcessIndex].split(',').map(Number),
        rtacceptedRejected: rtacceptedRejected
      };

      // Group based on the unique key
      if (!map.has(uniqueKey)) {
        map.set(uniqueKey, [stampProcessAcceptReject]);
      } else {
        let existingAcceptRejectArray = map.get(uniqueKey);
        existingAcceptRejectArray.push(stampProcessAcceptReject);
      }
    }

    // Modify the logic for removing duplicates and populating the weld log
    let removeDuplicateMap = new Map();
    const result: any[][] = [];
    for (let id of tableIds) {
      const key = `${id[ewoNumberIndex]}-${id[weldNumberIndex]}-${id[weldModifierIndex]}`;
      if (!removeDuplicateMap.has(key)) {
        removeDuplicateMap.set(key, true);
        result.push(id);
      }
    }

    for (let eachLogCombined of result) {
      let uniqueKey = `${eachLogCombined[ewoNumberIndex]}-${eachLogCombined[weldNumberIndex]}-${eachLogCombined[weldModifierIndex]}`;
      this.populateWeldLog(eachLogCombined, map.get(uniqueKey)); // Populates the weld log and adds to the array for sending
    }

    this.submitLogs(this.containsAllWeldLogsArray);
  }

  // Problem: when empty filler mat & filler mat date, error
  populateWeldLog(tableIds: string[], processArray: any[]) {
    let currentLog: WeldLog = new WeldLog();
    const getIndex = (header: string) => this.getIndexFromHeader(header).index;

    let fillerMatDate = tableIds[getIndex('Filler Mat. Issued Date')];
    let fillerMatNeeded = tableIds[getIndex('Filler Mat. Needed')]?.split(',').map(Number) ?? '';

    currentLog.comments = tableIds[getIndex('Comments')];
    currentLog.dateWelded = tableIds[getIndex('Date Welded')];
    currentLog.divisionId = Number(tableIds[getIndex('Facility')]);
    currentLog.drawing = tableIds[getIndex('Drawing')];
    currentLog.ewonumber = tableIds[getIndex('EWO Number')].trim();
    currentLog.jointTypeId = Number(tableIds[getIndex('Joint Type')]);
    currentLog.materialFromId = Number(tableIds[getIndex('Material From')]);
    currentLog.materialToId = Number(tableIds[getIndex('Material To')]);
    currentLog.projectOrPo = tableIds[getIndex('Project/PO #')];
    currentLog.rtpercentageId = Number(tableIds[getIndex('RT %')]);
    currentLog.scheduleId = Number(tableIds[getIndex('Schedule')]);
    currentLog.sizeId = Number(tableIds[getIndex('Size')]);
    currentLog.spec = tableIds[getIndex('Pipe Spec')];
    currentLog.unitId = Number(tableIds[getIndex('Unit')]);
    currentLog.weldGroupId = Number(tableIds[getIndex('Weld Group')]);
    currentLog.weldModifier = tableIds[getIndex('Weld Modifier')];
    currentLog.weldNo = Number(tableIds[getIndex('Weld No.')]);
    currentLog.weldRepair = Boolean(tableIds[getIndex('Weld Repair')].toLowerCase());
    currentLog.weldType = 1;
    currentLog.wpsid = Number(tableIds[getIndex('WPS')]);
    currentLog['weldTypeIdentifierId'] = Number(tableIds[getIndex('Weld Type')]);

    //Why this? Finding the row to put the ditto value the user put in the spreadsheet.
    const rowIndex = this.findRowIndex(tableIds);
    if (rowIndex !== -1) {
      const wallThicknessIndex = this.spreadsheetCols.findIndex(o => o.header === 'Wall Thickness');
      currentLog.wallThickness = this.weldLogEntries[rowIndex][wallThicknessIndex + 1];
    }

    const qaArray = this.prepareWeldLogQA(tableIds, currentLog, rowIndex);
    this.addAdditionalFields(currentLog, processArray, tableIds);
    this.prepareForSending(currentLog, fillerMatDate, fillerMatNeeded, processArray, qaArray);

    return currentLog;
  }

  findRowIndex(tableIds: string[]) {
    const getIndex = (header: string) => this.getIndexFromHeader(header).index;
    return this.weldLogEntries.findIndex(
      row =>
        row[getIndex('EWO Number') + 1] === tableIds[getIndex('EWO Number')] &&
        row[getIndex('Weld No.') + 1] === tableIds[getIndex('Weld No.')] &&
        row[getIndex('Weld Modifier') + 1] === tableIds[getIndex('Weld Modifier')]
    );
  }

  prepareWeldLogQA(tableIds: string[], currentLog: WeldLog, rowIndex: number) {
    const getIndex = (header: string) => this.getIndexFromHeader(header).index;

    let qaType = tableIds[getIndex('QA Type')];
    let qaTypeLabel = tableIds[getIndex('QA Type Label')];
    let defectType = tableIds[getIndex('Defect Type')];
    let qaDate =
      rowIndex !== -1
        ? this.weldLogEntries[rowIndex][this.spreadsheetCols.findIndex(o => o.header === 'QA Date') + 1]
        : undefined;

    return [
      {
        defectTypeID: defectType ? Number(defectType) : -1,
        qaDate: [{ date: qaDate }],
        qaTypeID: Number(qaType),
        qaTypeLabelID: Number(qaTypeLabel)
      }
    ];
  }

  getIndexFromHeader(header: string) {
    return this.spreadsheetCols.find(o => o.header === header);
  }

  addAdditionalFields(currentLog: any, stampProcesses: any[], tableIds: any) {
    currentLog['weldTypeDesc'] = 'Production'; //fine
    currentLog['additionalNde'] = null;
    currentLog['qmtrackingNumber'] = null;
    currentLog['ewopostring'] = '';
    // Something currentLog['materialFromTo'] like 'P2 to P2'; //needs to be worked on

    currentLog['processString'] = tableIds[this.getIndexFromHeader('Filler Mat. Needed').index];
    currentLog['continuityWeld'] = null;
    currentLog['original'] = {};
    currentLog['processes'] = stampProcesses;

    currentLog['weldLogStampProcesses'] = [];
    this._srvWeldLog.processStamp(stampProcesses, currentLog, false);

    return currentLog;
  }

  prepareForSending(currentLog: any, fillerMatDate: any, fillerMatNeeded: any, PCS: any, qaArray: any[]) {
    let fillerMatDateChanged = fillerMatNeeded != '' ? fillerMatDate : null;
    let completedWeldLogObject = this._srvWeldLog.prepareLogsWithEntries(
      false,
      currentLog,
      fillerMatDateChanged,
      fillerMatNeeded,
      PCS, //processes
      qaArray
    );

    this.containsAllWeldLogsArray.push(completedWeldLogObject);
  }

  submitLogs(logs: WeldLog[]) {
    this._srvWeldLog.AddMultipleWeldLogs(logs).then(res => {
      this.multipleLogsSuccessOrFail(res);
    });
  }

  public multipleLogsSuccessOrFail(result: string): void {
    this.fileUploadValue.value = '';
    this.onSuccess.emit(result);
  }

  public filterFaultyWeldLogEntries() {
    const highlightedRows = new Set(this.highlightedCells.map(cell => cell.row));
    highlightedRows.forEach(row => {
      if (!this.faultyRows.includes(row)) {
        this.faultyRows.push(row);
      }
    });

    // Filter weldLogEntries based on faultyRows
    this.faultyWeldLogEntries = this.weldLogEntries.filter((_, index) => this.faultyRows.includes(index));
  }
}
