import { Base } from './Base.model';

export class WeldLogStampProcess extends Base {
  constructor() {
    super();
  }

  public weldLogStampProcessID: number = 0;
  public weldLogId: number = 0;
  public stampAllocationId: number;
  public stampNumber: string = '';
  public weldProcessId: number;
  public weldProcessName: string = '';
  public rtacceptedRejected: boolean = true;
  public rowSequence: number;
}
