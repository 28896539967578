import { Injectable } from '@angular/core';
import { catchError, of } from 'rxjs';
import { UserRole, WeldManagerUser } from 'src/app/core/models';
import { NotificationService } from 'src/app/layout/shared/notification';
import { UserInfoService } from './user-info.service';
import { WeldManagerUserApiService } from '../../core/api/weld-manager-user-api.service';

@Injectable()
export class WeldManagerUserService {
  public refineryId: number;
  public userId: string;
  constructor(
    private _srvWeldUsers: WeldManagerUserApiService,
    private _srvUserInfo: UserInfoService,
    private _srvNotify: NotificationService
  ) {}

  public returnName(userId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this._srvWeldUsers.GetUser(userId).subscribe({
        next: res => {
          resolve(res);
        },
        error: err => {
          reject(Error(err));
        }
      });
    });
  }

  public setparams(): void {
    const USER = JSON.parse(localStorage.getItem('user'));
    if (USER) {
      this.userId = USER.userId;
      this.refineryId = USER.refineryId;
    }
  }

  public getAllUsers(): void {
    this._srvWeldUsers.GetAllUsers().subscribe(res => {
      localStorage.removeItem('AllUsers');
      localStorage.setItem('AllUsers', JSON.stringify(res));
    });
  }

  public getUserName(userId: string): string {
    const DTO: any[] = JSON.parse(localStorage.getItem('AllUsers'));
    const user = DTO.find(u => u.userId === userId);
    return user ? user.firstName + ' ' + user.lastName : '';
  }

  public GetUserAsync(): Promise<WeldManagerUser[]> {
    return new Promise<WeldManagerUser[]>((resolve, reject) => {
      try {
        this._srvWeldUsers.GetUserAsync().subscribe(res => {
          resolve(res);
        });
      } catch (err) {
        reject(Error(err));
      }
    });
  }

  //the optional active param is only passed when false and both active and inactive users are pulled
  public GetUsersByRefineryAsync(refineryId: number, active?: boolean): Promise<WeldManagerUser[]> {
    return new Promise<WeldManagerUser[]>((resolve, reject) => {
      try {
        if (active) {
          this._srvWeldUsers.GetActiveUsersByRefineryAsync(refineryId).subscribe(res => {
            resolve(res);
          });
        } else {
          this._srvWeldUsers.GetAllUsersByRefineryAsync(refineryId, active).subscribe(res => {
            resolve(res);
          });
        }
      } catch (err) {
        reject(Error(err));
      }
    });
  }

  public GetUserRolesByUserAsync(userId: string): Promise<UserRole> {
    return new Promise<UserRole>((resolve, reject) => {
      try {
        this._srvWeldUsers.GetUserRolesByUserAsync(userId).subscribe(res => {
          resolve(res);
        });
      } catch (err) {
        reject(Error(err));
      }
    });
  }

  public GetUserRolesByRefineryAsync(refineryId: number, active?: boolean): Promise<UserRole[]> {
    return new Promise<UserRole[]>((resolve, reject) => {
      try {
        if (active == false) {
          this._srvWeldUsers.GetAllUserRolesByRefineryAsync(refineryId, active).subscribe(res => {
            resolve(res);
          });
        } else {
          this._srvWeldUsers.GetActiveUserRolesByRefineryAsync(refineryId).subscribe(res => {
            resolve(res);
          });
        }
      } catch (err) {
        reject(Error(err));
      }
    });
  }

  public GetAvailableUserRolesByUserAsync(): Promise<UserRole[]> {
    this.setparams();
    return new Promise<UserRole[]>((resolve, reject) => {
      try {
        this._srvWeldUsers.GetAvailableUserRolesByUserAsync(this.userId, this.refineryId).subscribe(res => {
          resolve(res);
        });
      } catch (err) {
        reject(Error(err));
      }
    });
  }

  public AddorUpdateUserAsync(dto: any, existing: boolean): Promise<string> {
    dto['cai'] = dto.cai ? dto.cai : '';
    dto['refineryId'] = dto.refineryId ? dto.refineryId : this._srvUserInfo.refineryId;
    dto['active'] = dto.active ?? true;
    dto['reasonForDeletion'] = dto.reasonForDeletion ? dto.reasonForDeletion : null;

    if (existing) {
      dto['updatedDateTime'] = new Date().toJSON();
      dto['updatedBy'] = this._srvUserInfo.userName;
    } else {
      dto['createdDateTime'] = new Date().toJSON();
      dto['createdBy'] = this._srvUserInfo.userName;
    }

    return new Promise<string>((resolve, reject) => {
      try {
        if (existing) {
          this._srvWeldUsers.UpdateUserAsync(dto).subscribe(res => {
            resolve(res.toString());
          });
        } else {
          this._srvWeldUsers
            .AddUserAsync(dto)
            .pipe(
              catchError(err => {
                return of(err.error.Message);
              })
            )
            .subscribe(res => {
              resolve(res.toString());
            });
        }
      } catch (err) {
        reject(Error(err));
      }
    });
  }
}
