export class WeldStats {
  public totalWelds: number;
  public totalAccepted: number;
  public totalInspected: number;
  public totalRejected: number;
  public totalRejectRate: number;
  public weldStatsByJointType: WeldStatsByJointType[];
  public weldStatsByQAType: WeldStatsByQAType[];
}

export class WeldStatsByJointType {
  public jointTypeId: number;
  public jointTypeName: string;
  public refineryId: number;
  public divisionId: number;
  public weldGroupId: number;
  public unitId: number;
  public statsTotalWelds: number;
  public statsAccepted: number;
  public statsRejected: number;
  public statsRepaired: number;
}
export class WeldStatsByQAType {
  public qaTypeId: number;
  public qaTypeName: string;
  public refineryId: number;
  public divisionId: number;
  public weldGroupId: number;
  public unitId: number;
  public statsTotalWelds: number;
  public statsAccepted: number;
  public statsRejected: number;
  public statsRepaired: number;
}

export class WeldStatsParams {
  public refineryid: number;
  public weldfromdate: string;
  public weldtodate: string;
  public divisionid?: number = null;
  public unitid?: number = null;
  public groupid?: number = null;
}
