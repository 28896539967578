import { CommonModule, HashLocationStrategy, LocationStrategy, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MessageService } from 'primeng/api';
import { MessageModule } from 'primeng/message';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { InputNumberModule } from 'primeng/inputnumber';
import { ListboxModule } from 'primeng/listbox';
import { PickListModule } from 'primeng/picklist';
import { MultiSelectModule } from 'primeng/multiselect';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { DataModule } from './data/data.module';
import { UserPermissionsGuard } from './guards/app-routing.guard';
import { GuardService } from './guards/guard.service';
import { HeaderComponent } from './layout/shared/header/header.component';
import { HomeComponent } from './layout/pages/home/home.component';
import { WeldCertificationComponent } from './layout/pages/weld-certification/weld-certification.component';
import { AddEditViewLogComponent } from './layout/pages/weld-log/add-edit-view-log/add-edit-view-log.component';
import { EditMulitpleLogsComponent } from './layout/pages/weld-log/edit-multiple-logs/edit-multiple-logs.component';
import { CustomizeTableViewComponent } from './layout/pages/weld-log/customize-table-view/customize-table-view.component';
import { WeldLogComponent } from './layout/pages/weld-log/weld-log.component';
import { ReportingComponent } from './layout/pages/reporting/reporting.component';
import { FooterComponent } from './layout/shared/footer/footer.component';
import { LoadingIndicatorComponent } from './layout/shared/loading-indicator/loading-indicator.component';
import { NotificationService } from './layout/shared/notification/notification.service';
import { MenubarModule } from 'primeng/menubar';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { QualificationLogComponent } from './layout/pages/weld-certification/qualification-log/qualification-log.component';
import { AddEditViewQualificationLogComponent } from './layout/pages/weld-certification/qualification-log/add-edit-view-qualification-log/add-edit-view-qualification-log.component';
import { FileUploadModule } from 'primeng/fileupload';
import { ContextMenuModule } from 'primeng/contextmenu';
import { AdminComponent } from './layout/pages/admin/admin.component';
import { AddEditAdminComponent } from './layout/pages/admin/add-edit-admin/add-edit-admin.component';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ChipsModule } from 'primeng/chips';
import { TagModule } from 'primeng/tag';
import { QaTypeComponent } from './layout/common/qa-type/qa-type.component';
import { EditMultipleQaTypesComponent } from './layout/pages/weld-log/edit-multiple-qa-types/edit-multiple-qa-types.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ContractWelderPhotoComponent } from './layout/pages/admin/add-edit-admin/contract-welder-photo/contract-welder-photo.component';
import { ImageModule } from 'primeng/image';
import { BulkUploadComponent } from './layout/pages/weld-log/bulk-upload/bulk-upload.component';
import { CreateNdeComponent } from './layout/pages/weld-log/create-nde/create-nde.component';
import { EditMultipleProcessesComponent } from './layout/pages/weld-log/edit-multiple-processes/edit-multiple-processes.component';

@NgModule({
  declarations: [
    AppComponent,
    WeldLogComponent,
    HeaderComponent,
    ReportingComponent,
    CustomizeTableViewComponent,
    WeldCertificationComponent,
    LoadingIndicatorComponent,
    FooterComponent,
    HomeComponent,
    AddEditViewLogComponent,
    QualificationLogComponent,
    AddEditViewQualificationLogComponent,
    EditMulitpleLogsComponent,
    AdminComponent,
    AddEditAdminComponent,
    QaTypeComponent,
    EditMultipleQaTypesComponent,
    ContractWelderPhotoComponent,
    BulkUploadComponent,
    CreateNdeComponent,
    EditMultipleProcessesComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    TableModule,
    DialogModule,
    ButtonModule,
    CoreModule,
    DropdownModule,
    MultiSelectModule,
    CommonModule,
    ListboxModule,
    PickListModule,
    CalendarModule,
    CheckboxModule,
    ToggleButtonModule,
    InputTextModule,
    InputNumberModule,
    DataModule,
    ToastModule,
    ReactiveFormsModule,
    TooltipModule,
    MenubarModule,
    TieredMenuModule,
    FileUploadModule,
    ContextMenuModule,
    AutoCompleteModule,
    ChipsModule,
    MessageModule,
    TagModule,
    ImageCropperModule,
    ImageModule
  ],
  providers: [
    DatePipe,
    MessageService,
    NotificationService,
    UserPermissionsGuard,
    GuardService,
    { provide: LocationStrategy, useClass: HashLocationStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
