import { Base } from './Base.model';

export class WPS extends Base {
  constructor() {
    super();
  }

  public wpsid: number;
  public wpsname: string;
  public refineryId: number;
}
