import { Injectable } from '@angular/core';
import moment from 'moment';
import { catchError, of } from 'rxjs';
import { DivisionApiService, UnitApiService } from 'src/app/core/api';
import { Division, Unit } from 'src/app/core/models';
import { UserInfoService } from '.';

@Injectable({
  providedIn: 'root'
})
export class UnitService {
  private divisionsList: Division[] = [];

  constructor(
    private _srvUnit: UnitApiService,
    private _srvDivision: DivisionApiService,
    private _srvUserInfo: UserInfoService
  ) {}

  public GetUnitsByRefineryAsync(refineryId: number, active?: boolean): Promise<Unit[]> {
    return new Promise<Unit[]>((resolve, reject) => {
      try {
        this.getDivisions();

        // Define a common function to process the results
        const processResults = (res: Unit[]) => {
          res.sort((a, b) => (a.divisionId < b.divisionId ? -1 : 1));
          res.forEach(unit => {
            unit['status'] = unit.active ? 'Active' : 'Inactive';
            unit.createdDateTime = `${moment(unit.createdDateTime).format('MMMM DD, YYYY')}`;
            unit.updatedDateTime = unit.updatedDateTime
              ? `${moment(unit.updatedDateTime).format('MMMM DD, YYYY')}`
              : null;
          });
          // Adding the division code to the data
          res = res.map(unit => {
            const division = this.divisionsList.find(div => div.divisionId === unit.divisionId);
            return {
              ...unit,
              divisionDescription: division ? division.divisionDescription : null
            };
          });
          resolve(res);
        };

        // Use the appropriate service call based on the 'active' parameter
        const serviceCall = active
          ? this._srvUnit.GetActiveUnitsByRefineryAsync(refineryId)
          : this._srvUnit.GetAllUnitsByRefineryAsync(refineryId, active);

        // Subscribe to the service call and process results
        serviceCall.subscribe(processResults);
      } catch (err) {
        reject(Error(err));
      }
    });
  }

  public AddorUpdateUnitAsync(unit: Unit, existing: boolean) {
    if (existing) {
      unit.updatedBy = this._srvUserInfo.userName;
      unit.updatedDateTime = new Date().toJSON();
    } else {
      unit.active = true;
      unit.createdBy = this._srvUserInfo.userName;
      unit.createdDateTime = new Date().toJSON();
    }
    return new Promise<string>((resolve, reject) => {
      try {
        // Choose the appropriate service method
        const unitServiceMethod = existing ? this._srvUnit.UpdateUnitAsync(unit) : this._srvUnit.AddUnitAsync(unit);

        // Handle the response
        unitServiceMethod
          .pipe(
            catchError(err => {
              return of(err.error.Message);
            })
          )
          .subscribe(res => {
            resolve(res.toString());
          });
      } catch (err) {
        reject(Error(err));
      }
    });
  }

  private getDivisions(): void {
    this._srvDivision.GetAllDivisionsByRefineryAsync(Number(this._srvUserInfo.refineryId), false).subscribe(res => {
      this.divisionsList = res;
    });
  }
}
