<!-- #region Form Content -->
<form [formGroup]="logForm" id="logForm">
  <!-- #region Title -->
  <div class="row">
    <!-- #region Id(s) -->
    <div class="col-3">
      <label>Id(s) <span style="color: red">*</span></label>
      <p-dropdown
        optionValue="id"
        optionLabel="name"
        formControlName="stampid"
        placeholder="Select a Welder"
        [options]="_srvDropDown.stampAllocation.items"
        [filter]="true"
      ></p-dropdown>
    </div>
    <!-- #endregion -->

    <div class="col-5"></div>

    <!-- #region Action Buttons -->
    <div class="col-4 divCenter" style="display: table">
      <!-- #region Cancel -->
      <p-button (onClick)="cancel()" class="red-btn mt-4 action-btn"
        ><em class="pi pi-times-circle mr-2"></em> Cancel</p-button
      >
      <!-- #endregion -->

      <!-- #region Submit -->
      <p-button
        class="mt-4 mr-2 action-btn"
        (onClick)="submitNewLog()"
        [disabled]="formDisabled"
        [hidden]="hideActionBtns"
        *ngIf="
          userPermissions &&
          (userPermissions.permissions.includes('system admin') ||
            userPermissions.permissions.includes('update weld log'))
        "
        >{{ dialogSubmit }}</p-button
      >
      <!-- #endregion Submit-->
    </div>
    <!-- #endregion Action Buttons-->
  </div>
  <!-- #endregion Title-->

  <!-- #region Material From/To & MaterialForm -->
  <div class="row">
    <!-- #region Material From -->
    <div class="col-2">
      <label for="materialFrom">Material <span style="color: red">*</span></label>
      <p-dropdown
        optionValue="id"
        optionLabel="name"
        name="materialFromId"
        formControlName="materialFromId"
        placeholder="Select From"
        [options]="_srvDropDown.materials.items"
        [filter]="true"
      ></p-dropdown>
    </div>
    <!-- #endregion Material From -->

    <!-- #region Material To -->
    <div class="col-2">
      <label for="materialTo"></label>
      <p-dropdown
        optionValue="id"
        optionLabel="name"
        name="materialToId"
        formControlName="materialToId"
        placeholder="Select To"
        [options]="_srvDropDown.materials.items"
        [filter]="true"
      ></p-dropdown>
    </div>
    <!-- #endregion Material To -->

    <!-- #region Material Form -->
    <div class="col-2">
      <label for="materialFormId">Material Form <span style="color: red">*</span></label>
      <p-dropdown
        optionValue="id"
        optionLabel="name"
        name="materialFormId"
        formControlName="materialFormId"
        placeholder="Select Material Form"
        [options]="_srvDropDown.materialForms.items"
        [filter]="true"
      ></p-dropdown>
    </div>
    <!-- #endregion Material Form -->
  </div>
  <!-- #endregion Material From/To & materialFormId -->

  <!-- #region Processes -->
  <div formArrayName="processes">
    <div *ngFor="let process of getProcesses().controls; index as i">
      <div [formGroupName]="i" class="row">
        <!-- #region Processes -->
        <div class="col-2">
          <label *ngIf="i == 0">Process <span style="color: red">*</span></label>
          <p-dropdown
            optionValue="id"
            optionLabel="name"
            name="processid"
            formControlName="processid"
            placeholder="Select a Process"
            [options]="_srvDropDown.WeldProcesses.items"
            (onChange)="enableDisableProcesses()"
            [filter]="true"
          ></p-dropdown>
        </div>
        <!-- #endregion -->

        <!-- #region Filler Material Needed -->
        <div class="col-4">
          <label for="fillerMatNeeded" *ngIf="i == 0">Filler Material Needed <span style="color: red">*</span></label>
          <p-multiSelect
            optionLabel="name"
            optionValue="id"
            name="fillerMatNeeded"
            formControlName="fillerMatNeeded"
            placeholder="Select Filler Material"
            [options]="_srvDropDown.fillerMat.items"
          ></p-multiSelect>
        </div>
        <!-- #endregion -->

        <!-- #region Weld Metal Thickness -->
        <div class="col-3">
          <label for="weldMetalThickness" *ngIf="i == 0"
            >Actual Thickness (in.)</label
          >
          <input
            pInputText
            class="formInput"
            type="text"
            name="weldMetalThickness"
            formControlName="weldMetalThickness"
            placeholder="Enter Weld Metal Thickness"
            maxlength="25"
            (keypress)="keyValidation.numericDecimal($event)"
          />
        </div>
        <!-- #endregion -->

        <!-- #region Qualified Thickness -->
        <div class="col-2">
          <label for="qualifiedThickness" *ngIf="i == 0"
            >Qualified Thickness (in.) <span style="color: red">*</span></label
          >
          <input
            pInputText
            class="formInput"
            type="text"
            name="qualifiedThickness"
            formControlName="qualifiedThickness"
            placeholder="Enter Qualified Thickness"
            maxlength="25"
            (keypress)="keyValidation.numericDecimal($event)"
          />
        </div>
        <!-- #endregion -->

        <!-- #region Add, Delete Buttons -->
        <div class="col-1 bottom">
          <!-- #region Add -->
          <p-button
            *ngIf="this.getProcesses().getRawValue().length < _srvDropDown.WeldProcesses.items.length"
            class="green-btn"
            icon="pi pi-plus"
            pTooltip="Add new row"
            tooltipPosition="right"
            [disabled]="formDisabled"
            (onClick)="addAllocationRow()"
          ></p-button>
          <!-- #endregion -->

          <!-- #region Delete -->
          <p-button
            *ngIf="this.getProcesses().getRawValue().length > 1"
            class="red-btn ml-2"
            icon="pi pi-trash"
            pTooltip="Delete row"
            tooltipPosition="right"
            [disabled]="formDisabled"
            (onClick)="removeAllocationRow(i)"
          ></p-button>
          <!-- #endregion -->
        </div>
        <!-- #endregion -->
      </div>
    </div>
  </div>
  <!-- #endregion -->

  <div class="row">
    <!-- #region WPS -->
    <div class="col-2">
      <label for="wpsid">WPS<span style="color: red">*</span></label>
      <p-dropdown
        name="wpsid"
        formControlName="wpsid"
        optionValue="id"
        optionLabel="name"
        placeholder="Select a WPS"
        [filter]="true"
        [options]="_srvDropDown.wps.items"
        appendTo="body"
      ></p-dropdown>
    </div>
    <!-- #endregion WPS-->

    <!-- #region Weld Date -->
    <div class="col-2 calendarDiv">
      <label for="dateWelded">Weld Date <span style="color: red">*</span></label>
      <p-calendar
        class="formCalendar"
        name="dateWelded"
        formControlName="dateWelded"
        dataType="string"
        dateFormat="yy-mm-dd"
        placeholder="Enter Date"
        appendTo="body"
        [readonlyInput]="true"
        [showButtonBar]="true"
      ></p-calendar>
    </div>
    <!-- #endregion Weld Date-->

    <!-- #region QW 484 File -->
    <div class="col-6">
      <label for="uploadedFileName">QW 484 File <span style="color: red">*</span></label>
      <input
        pInputText
        class="formInput"
        type="text"
        name="uploadedFileName"
        formControlName="uploadedFileName"
        placeholder="Select QW 484 PDF File"
        [disabled]="true"
      />
      <small *ngIf="qw484File" id="uploadedFileName-help" class="p-error block">QW 484 File is required.</small>
    </div>
    <div *ngIf="actionQualification !== 2" class="col-10 p-text-center">
      <!-- #region File Upload -->
      <p-fileUpload
        #fileUploader
        name="pdfFile"
        accept=".pdf"
        [multiple]="false"
        [showUploadButton]="false"
        [showCancelButton]="false"
        [files]="uploadedFiles"
        (onSelect)="handleOnSelect($event)"
        (onRemove)="handleOnRemove($event)"
        [maxFileSize]="5000000"
      >
        <!-- #region Files Info -->
        <ng-template let-file pTemplate="file" let-i="index">
          <div class="p-fileupload-row">
            <div>
              <i [ngClass]="'icon-primary pi  pi-file-pdf'"></i>
            </div>
            <div>{{ file.name }}</div>
            <div>{{ fileUploader.formatSize(file.size) }}</div>
            <div>
              <button
                type="button"
                icon="pi pi-times"
                pButton
                (click)="fileUploader.remove($event, i)"
                [disabled]="file.uploading"
              ></button>
            </div>
          </div>
        </ng-template>
        <!-- #endregion Files Info -->
      </p-fileUpload>
      <!-- #endregion File Upload -->
    </div>
    <!-- #endregion QW 484 File Upload -->
  </div>

  <!-- #region Comment -->
  <div class="row">
    <div class="col-10">
      <label for="comments">Comment</label>
      <textarea
        pInputText
        type="text"
        class="formInput"
        name="comments"
        rows="1"
        formControlName="comments"
        maxlength="500"
        oninput="if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
      >
      </textarea>
    </div>
  </div>
  <!-- #endregion Comment -->
</form>
<!-- #endregion -->
