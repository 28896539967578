<main>
  <div class="container">
    <div class="row">
      <!-- #region Message -->
      <div class="col-12 align-self-center center-error">
        <h1>UH OH! Internal Error.</h1>
        <h6>
          There was an error processing your request. Please try again later or contact helpdesk in the following link
          <a [href]="_link" target="_blank" rel="noopener">Chevron App Support</a> .
        </h6>
      </div>
      <!-- #endregion -->
    </div>
  </div>
</main>
