import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, switchMap, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class QMDBService {
  private qmdbApiUrl = `${environment.apiEndpointQMDB}/api/PostNDERequest`;
  private weldManagerApiUrl = `${environment.apiEndpoint}/weldlog/UpdateWeldLogNDEsAsync`;

  private userToken: any;
  private userSecret: string;

  constructor(private http: HttpClient) {}

  sendNDEs(data: any): Observable<any> {
    const token = this.getToken();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      'Request-Context': 'Accept-Encoding',
      Authorization: `Bearer ${token}`
    });
    return this.http.post<any>(this.qmdbApiUrl, data, { headers }).pipe(
      switchMap(qmdbResponse => {
        // Forward successful response to weld manager
        return this.http.post<any>(this.weldManagerApiUrl, qmdbResponse.createdNDEs, { headers });
      }),
      catchError(this.handleError)
    );
  }

  getToken() {
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key.includes('login.windows.net-idtoken')) {
        this.userToken = JSON.parse(localStorage.getItem(key));
        this.userSecret = this.userToken.secret;
      }
    }
    return this.userSecret;
  }

  private handleError(error: HttpErrorResponse) {
    let errMsgDetail = '';
    errMsgDetail = `Error code: ${error.status} , ${error.message}`;
    return throwError(() => new Error(`${errMsgDetail}`));
  }
}
