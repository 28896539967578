import { Injectable } from '@angular/core';
import moment from 'moment';
import { catchError, of } from 'rxjs';
import { DivisionApiService } from 'src/app/core/api';
import { Division } from 'src/app/core/models';
import { UserInfoService } from '.';

@Injectable({
  providedIn: 'root'
})
export class DivisionService {
  constructor(private _srvDivision: DivisionApiService, private _srvUserInfo: UserInfoService) {}

  public GetDivisionsByRefineryAsync(refineryId: number, active?: boolean): Promise<Division[]> {
    return new Promise<Division[]>((resolve, reject) => {
      try {
        const observable = active
          ? this._srvDivision.GetActiveDivisionsByRefineryAsync(refineryId)
          : this._srvDivision.GetAllDivisionsByRefineryAsync(refineryId, active);

        observable.subscribe(res => {
          resolve(this.processDivisionResults(res));
        });
      } catch (err) {
        reject(Error(err));
      }
    });
  }

  private processDivisionResults(res: Division[]): Division[] {
    res.sort((a, b) => (a.divisionCode.toLocaleLowerCase() < b.divisionCode.toLocaleLowerCase() ? -1 : 1));
    res.forEach(division => {
      division['status'] = division.active ? 'Active' : 'Inactive';
      division.createdDateTime = `${moment(division.createdDateTime).format('MMMM DD, YYYY')}`;
      division.updatedDateTime = division.updatedDateTime
        ? `${moment(division.updatedDateTime).format('MMMM DD, YYYY')}`
        : null;
    });

    return res;
  }

  public AddorUpdateDivisionAsync(division: Division, existing: boolean) {
    if (existing) {
      division.updatedBy = this._srvUserInfo.userName;
      division.updatedDateTime = new Date().toJSON();
    } else {
      division.active = true;
      division.refineryId = Number(this._srvUserInfo.refineryId);
      division.createdBy = this._srvUserInfo.userName;
      division.createdDateTime = new Date().toJSON();
    }

    return new Promise<string>((resolve, reject) => {
      try {
        // Select the appropriate service method based on 'existing'
        const serviceMethod = existing
          ? this._srvDivision.UpdateDivisionAsync(division)
          : this._srvDivision.AddDivisionAsync(division);

        // Common error handling and subscription logic
        serviceMethod
          .pipe(
            catchError(err => {
              return of(err.error.Message);
            })
          )
          .subscribe(res => {
            resolve(res.toString());
          });
      } catch (err) {
        reject(Error(err));
      }
    });
  }
}
