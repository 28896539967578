import { Injectable } from '@angular/core';
import { Workbook, Worksheet } from 'exceljs';
import { DropdownService } from '.';
import { WeldLogTable } from '../models';
import {
  WeldLogParams,
  WeldStats,
  WeldStatsParams,
  WelderStats,
  WelderStatsParams,
  WelderStampIdHistory,
  WelderStampIdHistoryParams
} from '../../core/models';
import * as fs from 'file-saver';
//import { filter } from 'rxjs';

@Injectable()
export class ExportToExcelService {
  constructor(private _dropdownService: DropdownService) {}

  //#region 'General Methods'
  private setupExcelHeaders(headers: any[]): any[] {
    return headers
      .filter(hdr => hdr.field != 'contractorStampNumber')
      .map(col => {
        const { field, qaTypeDate, header, excelWidth } = col;
        let key;
        if (field.includes('QAType')) {
          key = qaTypeDate;
        } else {
          key = field;
        }
        let mappedHeader = header;
        let mappedWidth = excelWidth;
        return { header: mappedHeader, key, width: mappedWidth };
      });
  }

  private setupWorksheet(headers: any[], data: any[], worksheet: Worksheet): Worksheet {
    let tempObject = {};
    worksheet.columns = this.setupExcelHeaders(headers);
    data.forEach(log => {
      this.setupExcelHeaders(headers).forEach(col => {
        tempObject[col.key] =
          col.key == 'weldNo'
            ? log['weldModifier']
              ? log[col.key] + '-' + log['weldModifier']
              : log[col.key]
            : log[col.key];
      });
      worksheet.addRow(tempObject, 'n');
    });
    return worksheet;
  }

  private setupWorksheetStampIdHistory(headers: any[], data: any[], worksheet: Worksheet): Worksheet {
    let tempObject = {};
    worksheet.columns = this.setupExcelHeaders(headers);
    data.forEach(log => {
      this.setupExcelHeaders(headers).forEach(col => {
        tempObject[col.key] = log[col.key];
      });
      worksheet.addRow(tempObject, 'n');
    });
    return worksheet;
  }

  private styleWorkSheet(sheet: Worksheet) {
    sheet.eachRow(function (row, rowNumber) {
      row.eachCell(cell => {
        if (rowNumber == 1) {
          cell.font = {
            color: { argb: '2563EB' },
            bold: true
          };
        }
        cell.alignment = { wrapText: true };
      });
      //Commit the changed row to the stream
      row.commit();
    });
  }

  private exportWorkbook(workbook: Workbook, title: string) {
    workbook.xlsx.writeBuffer().then(data => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, `${title}.xlsx`);
    });
  }
  //#endregion 'General Methods'

  //#region 'Weld Log Table'
  //add separate sheet to show active filters
  private setupWeldLogFilterSheet(workbook: Workbook, filters: WeldLogParams) {
    let worksheetFilters = workbook.addWorksheet('Active Filters');

    worksheetFilters.columns = [
      { header: 'Filtered by', key: 'filterParam', width: 32 },
      { header: 'Value', key: 'value', width: 32 }
    ];

    //The below code is neccessary so users can see what they filtered by and not a meaningless ID they don't understand
    for (const param in filters) {
      if (filters[param] != null) {
        switch (param) {
          case 'divisionid':
            let div = this._dropdownService.divisions.items.find(x => Number(x.id) == filters[param]);
            worksheetFilters.addRow({
              filterParam: 'Division',
              value: div.name
            });
            break;
          case 'unitid':
            let unit = this._dropdownService.units.items.find(x => Number(x.id) == filters[param]);
            worksheetFilters.addRow({
              filterParam: 'Unit',
              value: unit.name
            });
            break;
          case 'groupid':
            let group = this._dropdownService.WeldGroups.items.find(x => Number(x.id) == filters[param]);
            worksheetFilters.addRow({
              filterParam: 'Group',
              value: group.name
            });
            break;
          case 'stampids':
            worksheetFilters.addRow({
              filterParam: 'Stamp IDs',
              value: filters.stampids
            });
            break;
          case 'ewoNumbers':
            worksheetFilters.addRow({
              filterParam: 'EWO',
              value: filters.ewoNumbers
            });
            break;
          case 'weldfromdate':
            worksheetFilters.addRow({
              filterParam: 'Weld From Date',
              value: filters.weldfromdate
            });
            break;
          case 'weldtodate':
            worksheetFilters.addRow({
              filterParam: 'Weld To Date',
              value: filters.weldtodate
            });
        }
      }
    }

    this.styleWorkSheet(worksheetFilters);
  }

  public weldLogTableToExcel(headers: any[], weldLogTableData: WeldLogTable[], filters: WeldLogParams) {
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('WeldLogData');
    this.setupExcelHeaders(headers);

    worksheet = this.setupWorksheet(headers, weldLogTableData, worksheet);

    this.styleWorkSheet(worksheet);

    this.setupWeldLogFilterSheet(workbook, filters);

    this.exportWorkbook(workbook, 'Weld Log');
  }
  //#endregion 'Weld Log Table'

  //#region 'Welder Certification'
  public CertificationTableToExcel(headers: any[], tableData: any[], processes: any[]) {
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('CertificationTableData');
    this.setupExcelHeaders(headers);

    worksheet = this.setupWorksheet(headers, tableData, worksheet);

    this.styleworkSheetWelderContinuity(worksheet, processes);
    this.styleWorkSheet(worksheet);

    this.exportWorkbook(workbook, 'Welder Certification');
  }

  //add reminder color classes from Welder Certification table
  private styleworkSheetWelderContinuity(sheet: Worksheet, processes: any[]) {
    let colorCode = '';

    sheet.eachColumnKey(function (col) {
      col.eachCell(cell => {
        processes.forEach(process => {
          sheet.getColumn(`${process}Color`).hidden = true;
          if (Number(cell.row) > 1 && col.key === process && cell.value != '') {
            let color = sheet.getColumn(`${col.key}Color`).values[Number(cell.row)];
            switch (color) {
              case 'green':
                colorCode = '8df797';
                break;
              case 'yellow':
                colorCode = 'f4ff69';
                break;
              case 'orange':
                colorCode = 'ffc688';
                break;
              case 'red':
                colorCode = 'ff7b7b';
                break;
              case '':
                colorCode = '';
            }
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: {
                argb: colorCode
              }
            };
            cell.border = {
              top: { style: 'thin' },
              left: { style: 'thin' },
              bottom: { style: 'thin' },
              right: { style: 'thin' }
            };
          }
        });
      });
    });
  }
  //#endregion 'Welder Certification'

  // #region 'Weld Stats Tables'
  public weldStatsTablesToExcel(
    weldStatsHeaders: any[],
    jointTypeHeaders: any[],
    qaTypeHeaders: any[],
    weldStats: WeldStats,
    filters: WeldStatsParams
  ) {
    let workbook = new Workbook();
    let overallStatsSheet = workbook.addWorksheet('Overall Weld Stats');
    let jointTypeStatsSheet = workbook.addWorksheet('Welds by Joint Type');
    let QATypeStatsSheet = workbook.addWorksheet('Welds by Inspection Type');
    this.setupExcelHeaders(weldStatsHeaders);
    this.setupExcelHeaders(jointTypeHeaders);
    this.setupExcelHeaders(qaTypeHeaders);

    let tempStatsData = new Array(weldStats);
    overallStatsSheet = this.setupWorksheet(weldStatsHeaders, tempStatsData, overallStatsSheet);
    this.styleWorkSheet(overallStatsSheet);

    jointTypeStatsSheet = this.setupWorksheet(jointTypeHeaders, weldStats.weldStatsByJointType, jointTypeStatsSheet);
    this.styleWorkSheet(jointTypeStatsSheet);

    QATypeStatsSheet = this.setupWorksheet(qaTypeHeaders, weldStats.weldStatsByQAType, QATypeStatsSheet);
    this.styleWorkSheet(QATypeStatsSheet);

    this.setupWeldStatsFilterSheet(workbook, filters);

    this.exportWorkbook(workbook, 'Weld Log');
  }

  private setupWeldStatsFilterSheet(workbook: Workbook, filters: WeldStatsParams) {
    let worksheetFilters = workbook.addWorksheet('Active Filters');

    worksheetFilters.columns = [
      { header: 'Filtered by', key: 'filterParam', width: 32 },
      { header: 'Value', key: 'value', width: 32 }
    ];

    //The below code is neccessary so users can see what they filtered by and not a meaningless ID they don't understand
    for (const param in filters) {
      if (filters[param] != null) {
        switch (param) {
          case 'divisionid':
            let div = this._dropdownService.divisions.items.find(x => Number(x.id) == filters[param]);
            worksheetFilters.addRow({
              filterParam: 'Division',
              value: div.name
            });
            break;
          case 'unitid':
            let unit = this._dropdownService.units.items.find(x => Number(x.id) == filters[param]);
            worksheetFilters.addRow({
              filterParam: 'Unit',
              value: unit.name
            });
            break;
          case 'groupid':
            let group = this._dropdownService.WeldGroups.items.find(x => Number(x.id) == filters[param]);
            worksheetFilters.addRow({
              filterParam: 'Group',
              value: group.name
            });
            break;
          case 'weldfromdate':
            worksheetFilters.addRow({
              filterParam: 'Weld From Date',
              value: filters.weldfromdate
            });
            break;
          case 'weldtodate':
            worksheetFilters.addRow({
              filterParam: 'Weld To Date',
              value: filters.weldtodate
            });
        }
      }
    }

    this.styleWorkSheet(worksheetFilters);
  }
  // #endregion 'Weld Stats Tables'

  // #region 'Welder Stats Tables'
  public welderStatsTablesToExcel(welderStatsHeaders: any[], welderStats: WelderStats[], filters: WelderStatsParams) {
    let workbook = new Workbook();
    let overallStatsSheet = workbook.addWorksheet('Overall Welder Stats');
    this.setupExcelHeaders(welderStatsHeaders);

    let tempStatsData = new Array(welderStats);
    overallStatsSheet = this.setupWorksheet(welderStatsHeaders, tempStatsData, overallStatsSheet);
    this.styleWorkSheet(overallStatsSheet);

    this.setupWelderStatsFilterSheet(workbook, filters);

    this.exportWorkbook(workbook, 'Welder Stats');
  }

  private setupWelderStatsFilterSheet(workbook: Workbook, filters: WelderStatsParams) {
    let worksheetFilters = workbook.addWorksheet('Active Filters');

    worksheetFilters.columns = [
      { header: 'Filtered by', key: 'filterParam', width: 32 },
      { header: 'Value', key: 'value', width: 32 }
    ];

    //The below code is neccessary so users can see what they filtered by and not a meaningless ID they don't understand
    for (const param in filters) {
      if (filters[param] != null) {
        switch (param) {
          case 'fullname':
            let stamp = this._dropdownService.stampAllocation.items.find(x => Number(x.id) == filters[param]);
            worksheetFilters.addRow({
              filterParam: 'FullName',
              value: stamp.name
            });
            break;
          case 'fromdate':
            worksheetFilters.addRow({
              filterParam: 'From Date',
              value: filters.fromdate
            });
            break;
          case 'todate':
            worksheetFilters.addRow({
              filterParam: 'To Date',
              value: filters.todate
            });
        }
      }
    }

    this.styleWorkSheet(worksheetFilters);
  }
  // #endregion 'Weld Stats Tables'

  // #region 'Welder Stamp Allocation Id Tables'
  public welderStampAllocationIdTablesToExcel(
    welderStampAllocIdHeaders: any[],
    welderStampIdHistory: WelderStampIdHistory[],
    filters: WelderStampIdHistoryParams
  ) {
    let workbook = new Workbook();
    let overallStatsSheet = workbook.addWorksheet('Welder Stamp Id History');
    this.setupExcelHeaders(welderStampAllocIdHeaders);

    //let tempStatsData = new Array(welderStats);
    overallStatsSheet = this.setupWorksheetStampIdHistory(
      welderStampAllocIdHeaders,
      welderStampIdHistory,
      overallStatsSheet
    );
    this.styleWorkSheet(overallStatsSheet);

    this.setupWelderStampAllocationIdFilterSheet(workbook, filters);

    this.exportWorkbook(workbook, 'Welder Stamp Id History');
  }

  private setupWelderStampAllocationIdFilterSheet(workbook: Workbook, filters: WelderStampIdHistoryParams) {
    let worksheetFilters = workbook.addWorksheet('Active Filters');

    worksheetFilters.columns = [
      { header: 'Filtered by', key: 'filterParam', width: 32 },
      { header: 'Value', key: 'value', width: 32 }
    ];

    //The below code is neccessary so users can see what they filtered by and not a meaningless ID they don't understand
    for (const param in filters) {
      if (filters[param] != null) {
        switch (param) {
          case 'welderName':
            //let stamp = this._dropdownService.stampAllocation.items.find(x => Number(x.id) == filters[param]);
            worksheetFilters.addRow({
              filterParam: 'FullName',
              value: filters.welderName
            });
            break;
          case 'allocationIdToDate':
            worksheetFilters.addRow({
              filterParam: 'From Date',
              value: filters.allocationIdFromDate
            });
            break;
          case 'allocationIdFromDate':
            worksheetFilters.addRow({
              filterParam: 'To Date',
              value: filters.allocationIdToDate
            });
        }
      }
    }

    this.styleWorkSheet(worksheetFilters);
  }
  // #endregion 'Welder Stamp Allocation Id Tables'
}
