import { Component, OnInit } from '@angular/core';
import { LoadingIndicatorService } from 'src/app/data/services/loading-indicator.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-not-authorized',
  templateUrl: './not-authorized.component.html',
  styleUrls: ['./not-authorized.styles.scss']
})
export class NotAuthorizedComponent implements OnInit {
  public readonly _link = environment.supportLink;

  constructor(private _loader: LoadingIndicatorService) {}

  ngOnInit(): void {
    setTimeout(() => {
      this._loader.hide();
    }, 1000);
  }
}
