import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { WeldLog } from '../models';
import { WeldLogParams, WeldNoParams } from '../models/WeldLog.model';
import { BaseApiService } from '../shared/base-api.service';

@Injectable({
  providedIn: 'root'
})
export class WeldLogApiService extends BaseApiService<WeldLog> {
  //#region 'Angular Life Cycle'
  constructor(protected _http: HttpClient) {
    super(_http, 'weldlog');
  }
  //#endregion

  //#region 'Get'
  public GetWeldLogsAsync(wldparam: WeldLogParams): Observable<any[]> {
    return this.getAllById(wldparam, '/GetWeldLogsAsync');
  }

  public GetWeldLogByIdAsync(refineryid: number, weldlogid: number): Observable<WeldLog> {
    const options = { refineryid, weldlogid };
    return this.getById(options, '/GetWeldLogByIdAsync');
  }

  public GetLatestWeldLogforPrefillAsync(refineryid: number): Observable<WeldLog> {
    const options = { refineryid };
    return this.getById(options, '/GetLatestWeldLogforPrefillAsync');
  }

  public GetNextWeldNumberAsync(wldparam: WeldNoParams): Observable<number> {
    const URL = wldparam.EWONumber
      ? `${this.getEndpoint()}/GetNextWeldNumberAsync?RefineryId=${wldparam.RefineryId}&EWONumber=${
          wldparam.EWONumber
        }&WeldGroupId=${wldparam.WeldGroupId}`
      : `${this.getEndpoint()}/GetNextWeldNumberAsync?RefineryId=${wldparam.RefineryId}&DivisionId=${
          wldparam.DivisionId
        }`;
    return this._http.get<number>(URL);
  }

  //#endregion

  //#region 'Create'
  public AddWeldLog(logView: WeldLog): Observable<boolean> {
    return this.createAny(JSON.stringify(logView), '/AddWeldLogAsync');
  }

  public UpdateWeldLog(logView: WeldLog): Observable<boolean> {
    return this.createAny(logView, '/UpdateWeldLogAsync');
  }

  public UpdateMultipleWeldLogs(logViews: WeldLog[]): Observable<string> {
    return this.createAny(logViews, '/UpdateWeldLogsAsync');
  }

  public AddMultipleWeldLogs(logViews: WeldLog[]): Observable<string> {
    return this.createAny(logViews, '/AddWeldLogsAsync');
  }

  public CloneWeldLog(logView: any, NoofClones: any, keepWeldNoSame?: boolean): Observable<any> {
    let params;
    if (keepWeldNoSame) {
      params = new HttpParams().set('NoofClones', `${NoofClones}`).set('keepWeldNoSame', `${keepWeldNoSame}`);
    } else {
      params = new HttpParams().set('NoofClones', `${NoofClones}`);
    }

    return this._http.post(`${this._apiEndpoint}/CloneWeldLogsAsync`, logView, { params });
  }
  //#endregion
}
