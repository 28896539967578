import { Base } from './Base.model';

export class ContractCompany extends Base {
  constructor() {
    super();
  }

  public contractCompanyId: number;
  public contractCompanyName: string;
  public refineryId: number;
  public contractStartDate: string;
  public contractEndDate: string;
}
