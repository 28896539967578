import { Injectable } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { ClickAnalyticsPlugin } from '@microsoft/applicationinsights-clickanalytics-js';
import { Router } from '@angular/router';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class ApplicationInsightsService {
  private appInsights;

  constructor(private router: Router) {
    let angularPlugin = new AngularPlugin();
    let clickPluginInstance = new ClickAnalyticsPlugin();
    let clickPluginConfig = {
      autoCapture: true
    };

    try {
      this.appInsights = new ApplicationInsights({
        config: {
          connectionString: environment.appInsights.connectionString,
          extensions: [<any>angularPlugin, clickPluginInstance],
          extensionConfig: {
            [angularPlugin.identifier]: { router: this.router },
            [clickPluginInstance.identifier]: clickPluginConfig
          }
        }
      });

      this.appInsights.loadAppInsights();
    } catch (error) {
      console.error('Failed to initialize Application Insights, ', error);
    }
  }
  // log exceptions and severity
  logException(exception: Error, severityLevel?: number) {
    if (this.appInsights) {
      this.appInsights.trackException({ exception: exception, severityLevel: severityLevel });
    } else {
      console.error('Application Insights is not initialized, ', exception);
    }
  }

  // log user email via authenticated user ID property and custom userEmail property. userEmail is a custom property that is only set when the user logs in to allow for tracking of the number of users who use the application
  setUser(email) {
    var properties = { userEmail: email };
    this.appInsights.trackEvent({ name: 'User Login', properties: properties });
    this.appInsights.setAuthenticatedUserContext(email);
  }

  // log event with user email as a property. Each individual property should have the userEmail attached to it
  logEvent(name: string, email: string, properties?: { [key: string]: any }) {
    properties = { ...properties, userEmail: email };
    this.appInsights.trackEvent({ name: name }, properties);
  }
}
