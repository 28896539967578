import { Component, Input, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { QualificationLog, TableHeaders, WeldLogFiles } from '../../../../core/models';
import { QualificationLogTable } from '../../../../data/models';
import { DropdownService, LoadingIndicatorService, QualificationLogService } from '../../../../data/services';
import { NotificationToast, NotificationService } from '../../../shared/notification';

@Component({
  selector: 'qualification-log',
  templateUrl: './qualification-log.component.html',
  styleUrls: ['./qualification-log.component.scss']
})
export class QualificationLogComponent implements OnInit {
  //#region 'Variables'
  public readonly PERMISSIONS = JSON.parse(localStorage.getItem('user'));
  @Input() qualificationLogTableData: QualificationLogTable[];
  @Input() selectedWelder: string = '';

  //add/edit/view log form
  public actionLog = 0;
  public dialogHeader = '';
  public dialogSubmit = '';
  public hideActionBtns = false;
  public hideForContinuity = false;
  public displayLogForm = false;
  public formDisabled = false;
  public isViewEdit = false;
  // public isViewScreen: boolean = false;

  //delete
  public displayDeleteDialog = false;
  public reasonForDeletion = '';

  //Qualification Log Table
  public qualificationLogFile: WeldLogFiles[];
  public currentLog = new QualificationLog();
  public contextMenuItems: MenuItem[];
  public selectedLog: QualificationLog;
  public Headers = [];
  //#endregion

  //#region 'Angular Life Cycle'
  constructor(
    public dropdownService: DropdownService,
    private _notifSrv: NotificationService,
    private _loader: LoadingIndicatorService,
    public _srvQLS: QualificationLogService
  ) {}

  ngOnInit() {
    this.setContextMenuItems();
    let headers: TableHeaders[] = JSON.parse(localStorage.getItem('tableHeaders'));
    this.loadHeaders(headers);
  }
  //#endregion

  //#region 'Table Headers'
  private loadHeaders(headers: TableHeaders[]) {
    const WIDTH_COEFFICIENT = 190;
    let filteredResponse = headers.filter(x => x.componentName == 'qualification-log' && x.arrayName == 'Headers');
    for (let i = 0; i < filteredResponse.length; i++) {
      this.Headers.push({
        field: filteredResponse[i].field,
        header: filteredResponse[i].header,
        width: filteredResponse[i].field.length + WIDTH_COEFFICIENT + 'px'
      });
    }
  }

  //#region 'General Methods'
  private clearLogForm() {
    this.currentLog = new QualificationLog();
    this.selectedLog = null;
  }

  public closeLog() {
    this.displayLogForm = false;
  }

  public openDeleteDialog(log: QualificationLog) {
    this.reasonForDeletion = '';
    this.displayDeleteDialog = true;
    this.currentLog = log;
  }

  public closeDeleteDialog() {
    this.displayDeleteDialog = false;
  }

  private setContextMenuItems() {
    this.contextMenuItems = [
      { label: 'View', icon: 'pi pi-eye', command: () => this.viewLogOpen(this.selectedLog), disabled: false },
      {
        label: 'Edit',
        icon: 'pi pi-pencil',
        command: () => this.editLogOpen(this.selectedLog),
        disabled: !(
          this.PERMISSIONS &&
          (this.PERMISSIONS.permissions.includes('system admin') ||
            this.PERMISSIONS.permissions.includes('update weld log'))
        )
      },
      {
        label: 'Delete',
        icon: 'pi pi-trash',
        command: () => this.openDeleteDialog(this.selectedLog),
        disabled: !(
          this.PERMISSIONS &&
          (this.PERMISSIONS.permissions.includes('system admin') ||
            this.PERMISSIONS.permissions.includes('delete weld log'))
        )
      }
    ];
  }
  //#endregion 'General Methods'

  //#region 'CRUD Methods'
  public newLogOpen() {
    this.actionLog = 0;
    this.isViewEdit = false;
    this.hideActionBtns = false;
    this.dialogHeader = 'Add New Qualification Log';
    this.dialogSubmit = 'Submit Qualification Log';
    this.displayLogForm = true;
  }

  public editLogOpen(existingLog: QualificationLog) {
    this.isViewEdit = true;
    this.currentLog = existingLog;
    this.dialogHeader = 'Edit Qualification Log';
    this.dialogSubmit = 'Update Qualification Log';
    this.hideActionBtns = false;
    this.actionLog = 1;
    this.displayLogForm = true;
  }

  public viewLogOpen(existingLog: QualificationLog) {
    this.isViewEdit = true;
    this.currentLog = existingLog;
    this.dialogHeader = 'View Qualification Log';
    this.dialogSubmit = 'Update Qualification Log';
    this.hideActionBtns = true;
    this.actionLog = 2;
    this.displayLogForm = true;
  }

  public async GetFile(weldLogFileId: number) {
    this.qualificationLogFile = await this._srvQLS.GetQualificationLogFileByIdAsync(weldLogFileId);
    this.viewPdf(this.qualificationLogFile[0].fileContent);
  }

  public viewPdf(myBase64string: string) {
    let objbuilder = '';
    objbuilder += '<object width="100%" height="100%" data="data:application/pdf;base64,';
    objbuilder += myBase64string;
    objbuilder += '" type="application/pdf" class="internal">';
    objbuilder += '<embed src="data:application/pdf;base64,';
    objbuilder += myBase64string;
    objbuilder += '" type="application/pdf"  />';
    objbuilder += '</object>';

    let win = window.open('#', '_blank');
    let title = 'PDF File';
    win.document.write(
      '<html><title>' +
        title +
        '</title><body style="margin-top: 0px; margin-left: 0px; margin-right: 0px; margin-bottom: 0px;">'
    );
    win.document.write(objbuilder);
    win.document.write('</body></html>');
  }

  public async deleteLog() {
    if (this.reasonForDeletion == '' || this.reasonForDeletion == null) {
      this.showNotification(
        new NotificationToast('error', 'Error: reason for deletion is required', '', 'error', 6000)
      );
    } else {
      this._loader.show();
      let tempRefineryId = this.currentLog.original.refineryId;
      let tempStampNumber = this.currentLog.original.weldLogStampProcesses[0].stampNumber;
      const success = await this._srvQLS.DeleteQualificationLog(this.currentLog, this.reasonForDeletion);
      this.currentLog = undefined;
      if (success) {
        this.refreshQualificationLogTable(tempRefineryId, tempStampNumber);
        this.showNotification(new NotificationToast('success', 'Log successfully deleted', '', 'success'));
        setTimeout(() => {
          this.displayDeleteDialog = false;
          this.clearLogForm();
          this._loader.hide();
        }, 2000);
      } else {
        this.showNotification(new NotificationToast('error', 'Error: failed to delete log', '', 'error', 6000));
        this._loader.hide();
      }
    }
  }
  //#endregion 'CRUD Methods'

  //#region 'Notification / Table Refresh'
  private showNotification(msg: NotificationToast) {
    this._notifSrv.sendNotification(msg);
  }

  public logSuccessOrFail(success: boolean) {
    if (success) {
      this.refreshQualificationLogTable(
        this.currentLog.original.refineryId,
        this.currentLog.original.weldLogStampProcesses[0].stampNumber
      );
      this.clearLogForm();
      this.displayLogForm = false;
      this._loader.hide();
      this.showNotification(new NotificationToast('success', 'Log successfully submitted', '', 'success'));
    } else {
      this.showNotification(new NotificationToast('error', 'Error sending log', '', 'error', 6000));
      this._loader.hide();
    }
  }

  private async refreshQualificationLogTable(refineryId: number, stampNumber: string) {
    this.qualificationLogTableData = await this._srvQLS.loadQualificationLogs(refineryId, stampNumber);
  }
  //#endregion 'NotifNotification / Table Refresh'
}
