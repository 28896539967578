import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { WelderStats, WelderStatsParams } from '../models';
import { BaseApiService } from '../shared/base-api.service';

@Injectable({
  providedIn: 'root'
})
export class WelderStatsApiService extends BaseApiService<WelderStats[]> {
  constructor(protected _http: HttpClient) {
    super(_http, 'welderstats');
  }

  //#region 'Get'
  public GetWelderStatsAsync(stsParams: WelderStatsParams): Observable<WelderStats[]> {
    return this.getById(stsParams, '/GetWelderStatsAsync');
  }
  //#endregion

  //#region 'Create'
  //#endregion

  //#region 'Update'
  //#endregion

  //#region 'Delete'
  //#endregion

  //#region 'Other'
  //#endregion
}
