<h5>QA Completion Dates</h5>
<form [formGroup]="qaForm" id="qaForm">
  <div formArrayName="qaTypesAndLabels">
    <div *ngFor="let process of getQATypeAndLabelRows().controls; index as i">
      <div [formGroupName]="i" class="row">
        <!-- #region QA Types -->
        <div class="col-2">
          <label>QA Types <span style="color: red">*</span></label>
          <p-dropdown
            #qaTypeId
            optionValue="id"
            optionLabel="name"
            formControlName="qaTypeID"
            placeholder="Select a QA Type"
            [options]="_srvDropDown.qaType.items"
            (onChange)="enableDisableQATypes(); clearQATypeLabel(i); _srvDropDown.GetQATypeLabels(qaTypeId.value, i)"
            appendTo="body"
            [filter]="true"
          ></p-dropdown>
        </div>
        <!-- #endregion QA Types -->

        <!-- #region QA Type Labels -->
        <div class="col-2" *ngIf="_srvDropDown.qaTypeLabelList[i]">
          <label>QA Type Labels <span style="color: red">*</span></label>
          <p-dropdown
            #selectedQATypeLabel
            optionValue="id"
            optionLabel="name"
            formControlName="qaTypeLabelID"
            placeholder="Select a QA Type label"
            [options]="_srvDropDown.qaTypeLabelList[i].items"
            appendTo="body"
            (onChange)="setupQADates(selectedQATypeLabel, i); getDefectTypes(selectedQATypeLabel, i)"
            appendTo="body"
            [filter]="true"
          ></p-dropdown>
        </div>
        <!-- #endregion QA Type Labels -->

        <!--#region QA Dates-->
        <div class="col-2" formArrayName="qaDate" *ngIf="_srvDropDown.splitQATypeLabelValues[i] && _srvDropDown.splitQATypeLabelValues[i].length > 0">
          <div *ngFor="let calendar of getQADatesByIndex(i).controls; let j = index">
            <div [formGroupName]="j">
              <label for="qaDate">{{ _srvDropDown.splitQATypeLabelValues[i][j] }} </label>
              <p-calendar
                class="formCalendar"
                name="qaDate"
                formControlName="date"
                dataType="string"
                dateFormat="yy-mm-dd"
                placeholder="Enter Date"
                appendTo="body"
                [readonlyInput]="true"
                [showButtonBar]="true"
              ></p-calendar>
            </div>
          </div>
        </div>
        <!--#endregion QA Dates-->

        <!-- #region Defect Types -->
        <div class="col-2" *ngIf="showDefectType(_srvDropDown.splitQATypeLabelValues[i], i) && _srvDropDown.splitQATypeLabelValues[i].length > 0">
          <label>Defect Types</label>
          <p-dropdown optionValue="id" optionLabel="name" formControlName="defectTypeID" placeholder="Select a Defect Type" [options]="_srvDropDown.defectType.items" appendTo="body" [filter]="true" />
        </div>
        <!-- #endregion QA Types -->

        <!-- #region Add, Delete Buttons -->
        <div class="col-1 bottom qa-button-container">
          <div class="nde-link-cell">
            <p-button
              class="blue-btn"
              [style.visibility]="getNDETypeURL(i) ? 'visible' : 'hidden'"
              icon="pi pi-external-link"
              pTooltip="Open NDE Link"
              tooltipPosition="left"
              (onClick)="openNDELink(getNDETypeURL(i))"
            />
          </div>

          <div class="nde-add-cell">
            <p-button class="green-btn" icon="pi pi-plus" pTooltip="Add new row" tooltipPosition="right" [disabled]="formDisabled" (onClick)="addQATypeAndLabelRow(); _srvDropDown.addDummy()" />
          </div>

          <div class="nde-delete-cell">
            <p-button
              class="red-btn"
              *ngIf="this.getQATypeAndLabelRows().getRawValue().length > 1"
              icon="pi pi-trash"
              pTooltip="Delete row"
              tooltipPosition="right"
              [disabled]="formDisabled"
              (onClick)="removeQATypeAndLabelRow(i); _srvDropDown.removeDummy(i); removeHasDefectTypeRow(i)"
            />
          </div>
        </div>
        <!-- #endregion Add, Delete Buttons -->
      </div>
    </div>
  </div>
</form>
<!-- #endregion QA Completion Dates -->
