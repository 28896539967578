import { Injectable } from '@angular/core';
import moment from 'moment';
import { catchError, of } from 'rxjs';
import { WeldGroupApiService } from 'src/app/core/api';
import { WeldGroup } from 'src/app/core/models';
import { UserInfoService } from '.';

@Injectable({
  providedIn: 'root'
})
export class WeldGroupService {
  constructor(private _srvGroup: WeldGroupApiService, private _srvUserInfo: UserInfoService) {}
  public GetWeldGroupsByRefineryAsync(refineryId: number, active?: boolean): Promise<WeldGroup[]> {
    return new Promise<WeldGroup[]>((resolve, reject) => {
      try {
        // Define a common function to process the results
        const processResults = (res: WeldGroup[]) => {
          res.sort((a, b) => (a.weldGroupName < b.weldGroupName ? -1 : 1));
          res.forEach(group => {
            group['status'] = group.active ? 'Active' : 'Inactive';
            group.createdDateTime = `${moment(group.createdDateTime).format('MMMM DD, YYYY')}`;
            group.updatedDateTime = group.updatedDateTime
              ? `${moment(group.updatedDateTime).format('MMMM DD, YYYY')}`
              : null;
          });
          resolve(res);
        };

        // Use the appropriate service call based on the 'active' parameter
        const serviceCall = active
          ? this._srvGroup.GetActiveWeldGroupsByRefineryAsync(refineryId)
          : this._srvGroup.GetAllWeldGroupsByRefineryAsync(refineryId, active);

        // Subscribe to the service call and process results
        serviceCall.subscribe(processResults);
      } catch (err) {
        reject(Error(err));
      }
    });
  }

  public AddorUpdateWeldGroupAsync(group: WeldGroup, existing: boolean) {
    if (existing) {
      group.updatedBy = this._srvUserInfo.userName;
      group.updatedDateTime = new Date().toJSON();
    } else {
      group.active = true;
      group.refineryId = Number(this._srvUserInfo.refineryId);
      group.createdBy = this._srvUserInfo.userName;
      group.createdDateTime = new Date().toJSON();
    }
    return new Promise<string>((resolve, reject) => {
      try {
        // Choose the appropriate service method
        const groupServiceMethod = existing
          ? this._srvGroup.UpdateWeldGroupAsync(group)
          : this._srvGroup.AddWeldGroupAsync(group);

        // Handle the response
        groupServiceMethod
          .pipe(
            catchError(err => {
              return of(err.error.Message);
            })
          )
          .subscribe(res => {
            resolve(res.toString());
          });
      } catch (err) {
        reject(Error(err));
      }
    });
  }
}
