<!-- #region View / Edit Qualification Log Form -->
<p-dialog
  header="{{ dialogHeader }}"
  [style]="{ width: '94%', height: '96%' }"
  [(visible)]="displayLogForm"
  [modal]="true"
  [closeOnEscape]="true"
  [draggable]="false"
  [resizable]="false"
  [blockScroll]="false"
  [focusTrap]="false"
>
  <app-add-edit-view-qualification-log
    *ngIf="displayLogForm"
    [actionQualification]="actionLog"
    [dialogHeader]="dialogHeader"
    [dialogSubmit]="dialogSubmit"
    [userPermissions]="PERMISSIONS"
    [isViewEdit]="isViewEdit"
    [hideActionBtns]="hideActionBtns"
    [currentLog]="currentLog"
    (onCancel)="closeLog()"
    (onSuccess)="logSuccessOrFail($event)"
  ></app-add-edit-view-qualification-log>

  <div>
    <div class="row">
      <div class="col-4"><b>Created By: </b>{{ this.currentLog.createdBy }}</div>
      <div class="col-6"><b>Created Date and Time: </b> {{ this.currentLog.createdDateTime | date : 'yyyy-MM-dd HH:mm' }}</div>
    </div>
    <div class="row">
      <div class="col-4"><b>Last Updated By: </b>{{ this.currentLog.updatedBy }}</div>
      <div class="col-6"><b>Last Updated Date and Time: </b>{{ this.currentLog.updatedDateTime | date : 'yyyy-MM-dd HH:mm' }}</div>
    </div>
  </div>
</p-dialog>
<!-- #endregion View / Edit Qualification Log Form -->

<div class="row">
  <!-- #region Section 1 Title -->
  <div class="col-3 divCenter">
    <h5 class="mt-4">{{ this.selectedWelder }}</h5>
  </div>
  <!-- #endregion -->

  <div class="col-9"></div>
</div>

<!-- #region Message -->
<div class="col-10 align-self-center center-error" *ngIf="qualificationLogTableData.length === 0">
  <h6 style="color: #0066b2">No qualification logs found.</h6>
</div>
<!-- #endregion -->

<!-- #region Table -->
<p-contextMenu #cm [model]="contextMenuItems" appendTo="body"></p-contextMenu>
<p-table
  *ngIf="qualificationLogTableData.length > 0"
  [value]="qualificationLogTableData"
  [scrollable]="true"
  selectionMode="single"
  [(selection)]="selectedLog"
  [contextMenu]="cm"
  contextMenuSelectionMode="joint"
  dataKey="weldLogId"
>
  <!-- #region Header Row -->
  <ng-template pTemplate="header" let-columns>
    <tr>
      <!-- #region Default Headers -->
      <ng-container *ngFor="let header of Headers; index as i">
        <th id="head{{ header.width }}" class="header" [ngStyle]="{ width: header.width }" [pSortableColumn]="header.field">
          {{ header.header }}
          <p-sortIcon [field]="header.field"></p-sortIcon>
        </th>
      </ng-container>
      <!-- #endregion Default Headers-->

      <!-- #region Uploaded Files -->
      <th id="edit" class="header" [ngStyle]="{ width: '120px' }">QW 484 File</th>
      <!-- #endregion Uploaded Files -->
    </tr>
  </ng-template>
  <!-- #endregion Headers Row -->

  <!-- #region Content Data -->
  <!--Fill data if column is enabled-->
  <ng-template id="tableRows" pTemplate="body" let-log let-columns="headers">
    <tr [pSelectableRow]="log" [pContextMenuRow]="log">
      <!-- #region Table Data -->
      <ng-container *ngFor="let header of Headers">
        <td [ngStyle]="{ width: header.width }" style="padding-left: 2px">
          {{ log[header.field] }}
        </td>
      </ng-container>
      <!-- #endregion Table Data -->

      <!-- #region Uploaded Files Icons-->
      <td class="view">
        <!-- #region View Icon-->
        <span><em (click)="GetFile(log.weldLogFiles[0].weldLogFileID)" style="font-size: 20px; margin-right: 6px" class="pi pi-file" pTooltip="View Files" tooltipPosition="top"></em></span>
        <!-- #endregion View Icon-->
      </td>
      <!-- #endregion Uploaded Files Icons-->
    </tr>
  </ng-template>
  <!-- #endregion Content Data -->
</p-table>
<!-- #endregion Table -->

<!-- #region Delete Log dialog-->
<p-dialog header="Delete Weld Log" [(visible)]="displayDeleteDialog" [modal]="true" [closeOnEscape]="true" [draggable]="false" [resizable]="true" [style]="{ 'min-height': '0px', width: '20%' }">
  <div class="row">
    <div class="col-12">
      <label for="deleteReason">Reason for deletion</label>
      <textarea name="deleteReason" id="deleteReason" type="text" pInputText maxlength="500" rows="2" [(ngModel)]="reasonForDeletion" class="formInput form-control"> </textarea>
    </div>
  </div>

  <div class="row">
    <div class="col-12 alignRight">
      <p-button (onClick)="deleteLog()" class="mr-2 red-btn"><em class="pi pi-trash mr-2"></em>Delete</p-button>
      <p-button (onClick)="closeDeleteDialog()" id="cancelButton"><em class="pi pi-times-circle mr-2"></em>Cancel</p-button>
    </div>
  </div>
</p-dialog>

<!-- #endregion Delete Log dialog-->
