import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { WeldProcess } from '../models';
import { BaseApiService } from '../shared/base-api.service';

@Injectable({
  providedIn: 'root'
})
export class WeldProcessApiService extends BaseApiService<WeldProcess> {
  //#region 'Angular Life Cycle'
  constructor(protected _http: HttpClient) {
    super(_http, 'weldprocess');
  }
  //#endregion

  //#region 'Get'
  public GetWeldProcessesAsync(): Observable<WeldProcess[]> {
    return this.getAll('/GetWeldProcessesAsync');
  }

  public GetActiveWeldProcessesAsync(): Observable<WeldProcess[]> {
    return this.getAll('/GetWeldProcessesAsync');
  }

  public GetAllWeldProcessesAsync(active: boolean): Observable<WeldProcess[]> {
    const options = { active };
    return this.getAllById(options, '/GetWeldProcessesAsync');
  }

  public GetWeldProcessesAsyncById(weldprocessid: number): Observable<WeldProcess> {
    const options = { weldprocessid };
    return this.getById(options, '/GetWeldProcessesAsync');
  }
  //#endregion

  //#region 'Create'
  public UpdateWeldProcessAsync(weldProcess: WeldProcess): Observable<boolean> {
    return this.createAny(weldProcess, '/UpdateWeldProcessesAsync');
  }
  //#endregion

  //#region 'Update'
  public AddWeldProcessAsync(weldProcess: WeldProcess): Observable<boolean> {
    return this.createAny(weldProcess, '/AddWeldProcessesAsync');
  }
  //#endregion

  //#region 'Delete'
  //#endregion

  //#region 'Other'
  //#endregion
}
