import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FillerMaterial } from '../models';
import { BaseApiService } from '../shared/base-api.service';

@Injectable({
  providedIn: 'root'
})
export class FillerMaterialApiService extends BaseApiService<FillerMaterial> {
  //#region 'Angular Life Cycle'
  constructor(protected _http: HttpClient) {
    super(_http, 'fillermaterial');
  }
  //#endregion

  //#region 'Get'
  public GetActiveFillerMaterialsAsync(): Observable<FillerMaterial[]> {
    return this.getAll('/GetFillerMaterialsAsync');
  }

  public GetAllFillerMaterialsAsync(active: boolean): Observable<FillerMaterial[]> {
    const options = { active };
    return this.getAllById(options, '/GetFillerMaterialsAsync');
  }

  public GetFillerMaterialsAsyncById(fillterMaterialId: number): Observable<FillerMaterial> {
    const options = { fillterMaterialId };
    return this.getById(options, '/GetFillerMaterialsAsync');
  }
  //#endregion

  //#region 'Create'
  public UpdateFillerMaterialAsync(fillMat: FillerMaterial): Observable<boolean> {
    return this.createAny(fillMat, '/UpdateFillerMaterialAsync');
  }
  //#endregion

  //#region 'Update'
  public AddFillerMaterialAsync(fillMat: FillerMaterial): Observable<boolean> {
    return this.createAny(fillMat, '/AddFillerMaterialAsync');
  }
  //#endregion

  //#region 'Delete'
  //#endregion

  //#region 'Other'
  //#endregion
}
