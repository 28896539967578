import { Injectable } from '@angular/core';
import { WallThickness } from 'src/app/core/models';
import { WallThicknessApiService } from 'src/app/core/api/wall-thickness-api.service';

@Injectable({
  providedIn: 'root'
})
export class WallThicknessService {
  constructor(private _srvWallThickness: WallThicknessApiService) {}
  public GetWallThicknessAsync(SizeId: number, ScheduleId: number): Promise<WallThickness> {
    return new Promise((resolve, reject) => {
      try {
        this._srvWallThickness.GetWallThicknessAsync(SizeId, ScheduleId).subscribe(res => {
          resolve(res);
        });
      } catch (err) {
        reject(Error(err));
      }
    });
  }
}
