export class WelderStampIdHistory {
  public welderId: string;
  public firstName: string;
  public lastName: string;
  public welderName: string;
  public welderType: string;
  public stampNumber: string;
  public contractorStampNumber: string;
  public stampAllocationId: number;
  public status: string;
  public refineryId: number;
  public allocationStartDate: string;
  public allocationEndDate?: string;
}

export class WelderStampIdHistoryParams {
  public refineryid: number;
  public welderName: string;
  public stampNumber: string;
  public allocationIdToDate: string;
  public allocationIdFromDate: string;
}
