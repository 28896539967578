import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Unit } from '../models';
import { BaseApiService } from '../shared/base-api.service';

@Injectable({
  providedIn: 'root'
})
export class UnitApiService extends BaseApiService<Unit> {
  //#region 'Angular Life Cycle'
  constructor(protected _http: HttpClient) {
    super(_http, 'unit');
  }
  //#endregion

  //#region 'Get'
  public GetUnitsAsync(): Observable<Unit[]> {
    return this.getAll('/GetUnitsAsync');
  }

  public GetUnitsAsyncById(unitid: number): Observable<Unit> {
    const options = { unitid };
    return this.getById(options, '/GetUnitsAsync');
  }

  public GetActiveUnitsByRefineryAsync(refineryid: number): Observable<Unit[]> {
    const options = { refineryid };
    return this.getAllById(options, '/GetUnitsByRefineryAsync');
  }

  public GetAllUnitsByRefineryAsync(refineryid: number, active: boolean): Observable<Unit[]> {
    const options = { refineryid, active };
    return this.getAllById(options, '/GetUnitsByRefineryAsync');
  }

  public GetUnitsByRefineryAndDivisionAsync(refineryid: number, divisionid: number): Observable<Unit[]> {
    const options = { refineryid, divisionid };
    return this.getAllById(options, '/GetUnitsByRefineryAsync');
  }
  //#endregion

  //#region 'Create'
  public AddUnitAsync(unit: Unit): Observable<boolean> {
    return this.createAny(unit, '/AddUnitAsync');
  }
  //#endregion

  //#region 'Update'
  public UpdateUnitAsync(unit: Unit): Observable<boolean> {
    return this.createAny(unit, '/UpdateUnitAsync');
  }
  //#endregion

  //#region 'Delete'
  //#endregion

  //#region 'Other'
  //#endregion
}
