import { Base } from './Base.model';

export class Role extends Base {
  constructor() {
    super();
  }

  public roleId: number;
  public roleName: string;
  public permissionId: number;
  public refineryId: number;
  public rolePermissionId: number[];
}
