import { Base } from './Base.model';

export class Refinery extends Base {
  constructor() {
    super();
  }

  public refineryId: number;
  public siteId: string;
  public refineryCode: string;
  public refineryName: string;
  public city: string;
  public state: string;
  public certValidityDays: number;
  public reminderEmailCopyTo: string;
  public reminderEmail1: number;
  public reminderEmail2: number;
  public reminderEmail3: number;
  public stampIdrangeFrom: number;
  public stampIdrangeTo: number;
  public dataElementRequestEmail: string;
}
