import { Injectable } from '@angular/core';

@Injectable()
export class GuardService {
  //#region 'Variables'
  //#endregion

  //#region 'Public Methods'
  public validateUserPermissions(path: any): boolean {
    const PERMISSIONS = JSON.parse(localStorage.getItem('user'));

    if (PERMISSIONS) {
      if (PERMISSIONS.permissions.includes('system admin')) {
        return true;
      } else {
        if (path === 'log' && PERMISSIONS.permissions.includes('view weld log')) {
          return true;
        } else if (path === 'stats' && PERMISSIONS.permissions.includes('view statistics')) {
          return true;
        } else if (path === 'certification' && PERMISSIONS.permissions.includes('view welder certification')) {
          return true;
        } else {
          return false;
        }
      }
    } else {
      return false;
    }
  }
  //#endregion
}
