import { Injectable } from '@angular/core';
import moment from 'moment';
import { catchError, of } from 'rxjs';
import { MaterialApiService } from 'src/app/core/api';
import { Material } from 'src/app/core/models';
import { UserInfoService } from '.';

@Injectable({
  providedIn: 'root'
})
export class MaterialService {
  constructor(private _srvMaterial: MaterialApiService, private _srvUserInfo: UserInfoService) {}
  public GetMaterialAsync(active?: boolean): Promise<Material[]> {
    return new Promise<Material[]>((resolve, reject) => {
      try {
        // Define a common function to process the results
        const processResults = (res: Material[]) => {
          res.sort((a, b) => (a.materialName.toLocaleLowerCase() < b.materialName.toLocaleLowerCase() ? -1 : 1));
          res.forEach(material => {
            material['status'] = material.active ? 'Active' : 'Inactive';
            material.createdDateTime = `${moment(material.createdDateTime).format('MMMM DD, YYYY')}`;
            material.updatedDateTime = material.updatedDateTime
              ? `${moment(material.updatedDateTime).format('MMMM DD, YYYY')}`
              : null;
          });
          resolve(res);
        };

        // Use the appropriate service call based on the 'active' parameter
        const serviceCall = active
          ? this._srvMaterial.GetActiveMaterialAsync()
          : this._srvMaterial.GetAllMaterialAsync(active);

        // Subscribe to the service call and process results
        serviceCall.subscribe(processResults);
      } catch (err) {
        reject(Error(err));
      }
    });
  }

  public AddorUpdateMaterialAsync(material: Material, existing: boolean): Promise<string> {
    if (existing) {
      material.updatedBy = this._srvUserInfo.userName;
      material.updatedDateTime = new Date().toJSON();
    } else {
      material.active = true;
      material.createdBy = this._srvUserInfo.userName;
      material.createdDateTime = new Date().toJSON();
    }
    return new Promise<string>((resolve, reject) => {
      try {
        // Select the appropriate service method
        const serviceMethod = existing
          ? this._srvMaterial.UpdateMaterialAsync(material)
          : this._srvMaterial.AddMaterialAsync(material);

        serviceMethod
          .pipe(
            catchError(err => {
              return of(err.error.Message);
            })
          )
          .subscribe(res => {
            resolve(res);
          });
      } catch (err) {
        reject(Error(err));
      }
    });
  }
}
