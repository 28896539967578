<!-- #region file selection -->
<div style="margin-bottom: 20px">
  <h5>Please select a file</h5>
  <input #fileInput type="file" (click)="fileInput.value = null" (input)="onFileChange($event, fileInput)" accept=".xls,.xlsx" multiple="false" />
</div>
<!-- #endregion file selection -->

<!-- #region error list -->

<div class="errorDiv" *ngIf="highlightedCells.length > 0 || errors.length > 0">
  <div class="errorMessage">
    Validation failed on the uploaded spreadsheet. Please fix the errors & reupload the sheet. Need the template?
    <a href="/assets/docs/BulkUploadTemplate_v2.xlsx">Download from here.</a>
    <ul>
      <li *ngFor="let error of errors">
        {{ error }}
      </li>
      <li *ngIf="highlightedCells.length > 0">Please correct the highlighted cells.</li>
    </ul>
  </div>

  <div class="checkboxContainer">
    <p-checkbox [(ngModel)]="showErrorRows" [binary]="true" inputId="binary"></p-checkbox>
    <label for="binary">Show Invalid Rows Only</label>
  </div>
</div>

<!-- #endregion error list -->

<!-- #region table -->
<p-table
  [scrollable]="true"
  scrollDirection="horizontal"
  dataKey="weldLogId"
  [value]="showErrorRows ? faultyWeldLogEntries : weldLogEntries"
  [rows]="10"
  [rowsPerPageOptions]="[10, 25, 50, 100]"
  [paginator]="true"
  paginatorPosition="bottom"
  paginatorDropdownAppendTo="body"
  *ngIf="errors.length == 0"
>
  <ng-template pTemplate="header" let-columns>
    <tr *ngIf="spreadsheetCols && spreadsheetCols.length > 0">
      <th id="excelRowIndex">Excel Row Index</th>

      <!-- #region Default Headers -->
      <ng-container *ngFor="let header of spreadsheetCols; index as i">
        <th id="head{{ header.width }}" class="header" [ngStyle]="{ width: header.width }">{{ header.header }}<span *ngIf="header.required" style="color: red">*</span></th>
      </ng-container>
      <!-- #endregion Default Headers -->
    </tr>
  </ng-template>

  <ng-template id="tableRows" pTemplate="body" let-product let-log let-columns="headers" let-index="index" let-rowIndex="rowIndex">
    <tr
      [pSelectableRow]="log"
      [pSelectableRowIndex]="showErrorRows ? faultyWeldLogEntries[rowIndex][0] : weldLogEntries[rowIndex][0]"
      [pContextMenuRow]="log"
      [ngClass]="{ 'red-row': showErrorRows ? isRowHighlighted(faultyWeldLogEntries[rowIndex][0]) : isRowHighlighted(weldLogEntries[rowIndex][0]) }"
      *ngIf="weldLogEntries && weldLogEntries.length > 0"
    >
      <td>{{ showErrorRows ? faultyWeldLogEntries[rowIndex][0] : weldLogEntries[rowIndex][0] }}</td>

      <!-- #region excel cells -->
      <ng-container *ngFor="let header of spreadsheetCols; index as i">
        <td [ngClass]="{ 'orange-row': showErrorRows ? isCellHighlighted(faultyWeldLogEntries[rowIndex][0], i + 1) : isCellHighlighted(weldLogEntries[rowIndex][0], i + 1) }">
          {{ product[i + 1] }}
        </td>
      </ng-container>
      <!-- #endregion excel cells -->
    </tr>
  </ng-template>
</p-table>
<!-- #endregion table -->

<!-- #region submit -->
<div class="row">
  <div class="alignRight">
    <!-- #region Submit -->
    <p-button class="mr-2 mt-4 action-btn" type="submit" [disabled]="!weldLogEntries || weldLogEntries.length == 0 || errors.length > 0 || highlightedCells.length > 0" (onClick)="upload()"
      >Upload Logs</p-button
    >
    <!-- #region Cancel-->
    <p-button (onClick)="cancel()" class="mr-2 mt-4 red-btn action-btn"><em class="pi pi-times-circle mr-2"></em> Cancel </p-button>
    <!-- #endregion Cancel-->
  </div>
</div>

<!-- #endregion -->
