import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CalAngularService } from '@cvx/cal-angular';
import { from, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service-api';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private HD: any;
  constructor(private _srvAuth: AuthService, private _srvCal: CalAngularService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this._srvCal.getAADToken(this._srvAuth.getScopes())).pipe(
      switchMap(token => {
        const headers = req.headers
          .set('Authorization', 'Bearer ' + token)
          .append('Content-Type', 'application/json')
          .append('Accept', 'application/json');
        const requestClone = req.clone({
          headers
        });
        return next.handle(requestClone);
      })
    );
  }
}
