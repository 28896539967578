import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminComponent } from './layout/pages/admin/admin.component';
import { HomeComponent } from './layout/pages/home/home.component';
import { WeldCertificationComponent } from './layout/pages/weld-certification/weld-certification.component';
import { WeldLogComponent } from './layout/pages/weld-log/weld-log.component';
import { ReportingComponent } from './layout/pages/reporting/reporting.component';
import { GenericErrorComponent } from './layout/shared/error/generic-error/generic-error.component';
import { NotAuthorizedComponent } from './layout/shared/error/not-authorized/not-authorized.component';
import { PageNotFoundComponent } from './layout/shared/error/page-not-found/page-not-found.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'log', component: WeldLogComponent },
  {
    path: 'reporting',
    children: [
      { path: 'weld-stats', component: ReportingComponent },
      { path: 'welder-stats', component: ReportingComponent },
      { path: 'welder-stamp-id-history', component: ReportingComponent }
    ]
  },
  {
    path: 'certification',
    component: WeldCertificationComponent
  },
  {
    path: 'admin',
    children: [
      { path: 'user', component: AdminComponent },
      { path: 'welder', component: AdminComponent },
      { path: 'contract-company', component: AdminComponent },
      { path: 'contract-welder', component: AdminComponent },
      { path: 'filler-materials', component: AdminComponent },
      { path: 'materials', component: AdminComponent },
      { path: 'pipe-sizes', component: AdminComponent },
      { path: 'wps', component: AdminComponent },
      { path: 'switchsite', component: AdminComponent },
      { path: 'email', component: AdminComponent }, //email data is managed in the Refinery table
      { path: 'division', component: AdminComponent },
      { path: 'unit', component: AdminComponent },
      { path: 'group', component: AdminComponent },
      { path: 'rtpercentage', component: AdminComponent },
      { path: 'weldprocess', component: AdminComponent },
      { path: 'rolepermission', component: AdminComponent }
    ]
  },
  {
    path: 'error',
    children: [
      { path: 'generic', component: GenericErrorComponent },
      { path: 'not-authorized', component: NotAuthorizedComponent },
      { path: 'page-not-found', component: PageNotFoundComponent }
    ]
  },
  { path: '**', component: PageNotFoundComponent },
  { path: '', component: HomeComponent, pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}

//  ,canActivate: [UserPermissionsGuard]
