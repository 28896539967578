<div class="flexbox">
  <p-pickList
    #pl
    [source]="itemsLeft"
    [target]="itemsRight"
    filterBy="header"
    sourceHeader="Available Columns"
    targetHeader="Selected Columns"
    [dragdrop]="true"
    [responsive]="true"
    [sourceStyle]="{'height':'30rem'}"
    [targetStyle]="{'height':'30rem'}"
    sourceFilterPlaceholder="Search by column header"
    targetFilterPlaceholder="Search by column header"
    [showSourceControls]="false"
    (onMoveToSource)="sortAvailableColumns()"
    (onMoveAllToSource)="sortAvailableColumns()"
    >
    <ng-template let-col pTemplate="item">
      <div class="product-item">
        <div class="product-list-detail">
          {{ col.header }}
        </div>
      </div>
    </ng-template>
  </p-pickList>
</div>

<div class="col-11 alignLeft">
  <p-button (onClick)="cancel()" class="red-btn mt-3 action-btn">
    <em class="pi pi-times-circle mr-2"></em> Cancel
  </p-button>
  
  <p-button (onClick)="sendUpdates()" class="mt-3 mr-2 action-btn green-btn" [disabled]="changeApplyChangesBtnState()">
    <em class="pi pi-save mr-2"></em>Apply Changes
  </p-button>
</div>
