import { WeldLogStampProcess, WeldLogFiles, WeldLogFillerMaterial } from '.';
import { Base } from './Base.model';

export class QualificationLog extends Base {
  constructor() {
    super();
  }

  public weldLogId: number;
  public refineryId: number;
  public refineryName: string = '';
  public wpsid: number;
  public wpsName: string = '';
  public dateWelded: string = '';
  public materialFormId: number;
  public materialFormName: string = '';
  public materialFromId: number;
  public materialFrom: string = '';
  public materialToId: number;
  public materialTo: string = '';
  public comments: string = '';
  public logEntryStatus: boolean;
  public active: boolean;
  public createdDateTime: string = '';
  public createdBy: string = '';
  public updatedDateTime: string = '';
  public updatedBy: string = '';
  public weldType: number;
  public weldTypeDesc: string = '';
  public weldLogFillerMaterials: WeldLogFillerMaterial[];
  public weldLogStampProcesses: WeldLogStampProcess[];
  public weldLogFiles: WeldLogFiles[];

  //#region 'Extra Variables'
  // this variable is to keep the original data from API
  public original: any;
  //#endregion
}
