import { WeldLogFillerMaterial, WeldLogStampProcess, QATypesAndLabel } from '.';
import { Base } from './Base.model';

export class WeldLog extends Base {
  constructor() {
    super();
  }

  public weldLogId: number;
  public qmtrackingNumber: number;
  public refineryId: number;
  public refineryName: string;
  public divisionId: number;
  public divisionDescription: string;
  public unitId: number;
  public unitDescription: string;
  public weldGroupId: number;
  public weldGroupName: string;
  public ewonumber: string;
  public projectOrPo: string;
  public spec: string;
  public rtpercentageId: number;
  public rtPercentage: number;
  public drawing: string;
  public jointTypeId: number;
  public jointTypeName: string;
  public wpsid: number;
  public wpsName: string;
  public weldNo: number;
  public dateWelded: string;
  public materialFrom: string;
  public materialFromId: number;
  public materialTo: string;
  public materialToId: number;
  public sizeId: number;
  public sizeName: string;
  public scheduleId: number;
  public wallThickness: number;
  public scheduleName: string;
  public rtcompletionDate: string;
  public repcompletionDate: string;
  public pwhtcompletionDate: string;
  public bhtcompletionDate: string;
  public vtcompletionDate: string;
  public pmicompletionDate: string;
  public pmivcompletionDate: string;
  public comments: string;
  public additionalNde: string;
  public logEntryStatus: boolean;
  public entryStatus: string;
  public weldType: number;
  public weldTypeDesc: string;
  public weldModifier: string;
  public weldRepair: boolean;
  public weldId: string;
  public weldLogFillerMaterials: WeldLogFillerMaterial[];
  public weldLogStampProcesses: WeldLogStampProcess[];
  public weldLogQAs: QATypesAndLabel[];
  public weldTypeIdentifierId: number;

  //#region 'Extra Variables'
  // this variable is to keep the original data from API
  public original: any;
  public weldMod: string;
  public acceptReject: string;
  //#endregion
}

export class WeldLogParams {
  public refineryid?: number = null;
  public weldfromdate: string;
  public weldtodate: string;
  public divisionid?: number;
  public unitid?: number = null;
  public groupid?: number = null;
  public stampids?: string = '';
  public ewoNumbers?: string = '';
}

export class WeldNoParams {
  public RefineryId?: number = null;
  public DivisionId?: number = null;
  public EWONumber?: number = null;
  public WeldGroupId?: number = null;
}
