import { Base } from './Base.model';

export class WeldLogFiles extends Base {
  constructor() {
    super();
  }

  public weldLogFileID: number = 0;
  public weldLogId: number;
  public fileName: string;
  public fileType: string = 'QW-484';
  public fileContent: any;
}
