<!-- #region Title -->
<div class="row">
  <div class="col-12 d-flex justify-content-center">
    <h2>Weld Logs</h2>
  </div>
</div>
<!-- #endregion -->

<!-- #region Action Bar -->
<div class="row">
  <!-- #region Division -->
  <div class="col-1">
    <h6>Divisions</h6>
    <p-dropdown
      name="divisionFilter"
      (onChange)="getUnits(); checkForInactiveFilters()"
      [options]="dropdownService.divisions.items"
      optionLabel="name"
      optionValue="id"
      [(ngModel)]="filterParams.divisionid"
      placeholder="Select"
      [showClear]="true"
      [filter]="true"
      [ngClass]="{ 'pending-filter-drp': filterParams.divisionid != activeFilterParams.divisionid }"
    ></p-dropdown>
  </div>
  <!-- #endregion Division -->

  <!-- #region Units -->
  <div class="col-2">
    <h6>Units</h6>
    <p-dropdown
      name="unitFilter"
      (onChange)="checkForInactiveFilters()"
      [options]="dropdownService.units.items"
      optionLabel="name"
      optionValue="id"
      [(ngModel)]="filterParams.unitid"
      placeholder="Select a Unit"
      [showClear]="true"
      [filter]="true"
      [ngClass]="{ 'pending-filter-drp': filterParams.unitid != activeFilterParams.unitid }"
    ></p-dropdown>
  </div>
  <!-- #endregion Units -->

  <!-- #region Groups -->
  <div class="col-1">
    <h6>Groups</h6>
    <p-dropdown
      name="groupFilter"
      (onChange)="checkForInactiveFilters()"
      [options]="dropdownService.WeldGroups.items"
      optionLabel="name"
      optionValue="id"
      [(ngModel)]="filterParams.groupid"
      placeholder="Select"
      [showClear]="true"
      [filter]="true"
      [ngClass]="{ 'pending-filter-drp': filterParams.groupid != activeFilterParams.groupid }"
    ></p-dropdown>
  </div>
  <!-- #endregion Groups -->

  <!-- #region Date Welded From -->
  <div class="col-2">
    <h6>Date welded from</h6>
    <p-calendar
      name="weldfromdateFilter"
      inputId="basic"
      (onSelect)="checkForInactiveFilters()"
      [(ngModel)]="filterParams.weldfromdate"
      [showTime]="false"
      dataType="string"
      dateFormat="yy-mm-dd"
      [readonlyInput]="true"
      [ngClass]="{ 'pending-filter-cal': filterParams.weldfromdate != activeFilterParams.weldfromdate }"
    ></p-calendar>
  </div>
  <!-- #endregion Date Welded From -->

  <!-- #region Date Welded To -->
  <div class="col-2">
    <h6>To</h6>
    <p-calendar
      name="weldtodateFilter"
      #weldtodate
      inputId="basic"
      (onSelect)="checkForInactiveFilters()"
      [(ngModel)]="filterParams.weldtodate"
      [showTime]="false"
      dataType="string"
      dateFormat="yy-mm-dd"
      [readonlyInput]="true"
      [ngClass]="{ 'pending-filter-cal': filterParams.weldtodate != activeFilterParams.weldtodate }"
    ></p-calendar>
  </div>
  <!-- #endregion Date Welded To -->

  <!-- #region Stamp IDs -->
  <div class="col-2">
    <h6>Stamp IDs</h6>
    <span [ngClass]="{ 'pending-filter-input': filterParams.stampids != activeFilterParams.stampids }">
      <input type="text" pInputText class="w100" (input)="checkForInactiveFilters()" [(ngModel)]="filterParams.stampids" (keypress)="keyValidation.alphanumericCommaSeparated($event)" />
    </span>
  </div>
  <!-- #endregion Stamp IDs -->

  <!-- #region EWO -->
  <div class="col-2">
    <h6>EWO</h6>
    <span [ngClass]="{ 'pending-filter-input': filterParams.ewoNumbers != activeFilterParams.ewoNumbers }">
      <input
        type="text"
        pInputText
        class="w100"
        (input)="checkForInactiveFilters()"
        [(ngModel)]="filterParams.ewoNumbers"
        (keypress)="keyValidation.numericCommaSeparated($event)"
        [ngClass]="{ 'pending-filter-input': filterParams.ewoNumbers != activeFilterParams.ewoNumbers }"
      />
    </span>
  </div>
  <!-- #endregion EWO -->

  <!-- #region 'Filter, Customize, Add' -->
  <div class="col-2">
    <p-button styleClass="p-button-m mt-2 mr-3" (click)="exportToExcel()"><em class="pi pi-file-excel mr-2"></em>Export to Excel</p-button>
  </div>
  <div class="col-10 alignRight">
    <!-- #region Filter Weld Log -->
    <p-button styleClass="p-button-m mt-2 mr-3" [ngClass]="{ 'inactive-filter-btn': inactiveFilters }" (onClick)="submitFilter()"><em class="pi pi-filter mr-2"></em> Filter Weld Logs</p-button>
    <!-- #endregion Filter Weld Log -->

    <!-- #region Customize Table View -->
    <p-button styleClass="p-button-m mt-2 mr-3" (onClick)="openCustomizeTableView()"><em class="pi pi-table mr-2"></em> Customize Table</p-button>
    <!-- #endregion Customize Table View -->

    <!-- #region Add Weld Log -->
    <p-button class="green-btn" styleClass="p-button-m mt-2 mr-3" (onClick)="newLogOpen()" *ngIf="createLogPermission"><em class="pi pi-plus-circle mr-2"></em> Add Weld Log</p-button>
    <!-- #endregion Add Weld Log -->

    <!-- #region Upload Bulk Weld Log -->
    <p-button class="green-btn" styleClass="p-button-m mt-2 " (onClick)="uploadBulkLogs()" *ngIf="createLogPermission" [disabled]="bulkUploadBtnDisabled"
      ><em class="pi pi-cloud-upload mr-2"></em> Bulk Upload Weld Logs {{ bulkUploadBtnDisabled ? bulkUploadBtnTextWhenDisabled : '' }}</p-button
    >
    <!-- #endregion Upload Bulk Weld Log -->
  </div>
  <!-- #endregion 'Filter, Customize, Add' -->
</div>
<!-- #endregion Action Bar -->

<!-- #region Customize view dialog -->
<p-dialog header="Customize View" [(visible)]="displayCustomizeTableView" [modal]="true" [draggable]="false" [resizable]="false" [closable]="false" [blockScroll]="false" class="customize-view-dialog">
  <app-customize-table-view
    *ngIf="displayCustomizeTableView"
    [itemsRight]="this.optionalHeaders"
    [itemsLeft]="this.disabledHeaders"
    (updateRight)="this.optionalHeaders = $event"
    (updateLeft)="this.disabledHeaders = $event"
    (closeDialog)="updateLogView($event)"
  ></app-customize-table-view>
</p-dialog>
<!-- #endregion -->

<!-- #region Add / View Weld Log Form -->
<p-dialog
  header="{{ dialogHeader }}"
  [style]="{ width: '94%', height: '96%' }"
  [(visible)]="displayLogForm"
  [modal]="true"
  [closeOnEscape]="true"
  [draggable]="false"
  [resizable]="false"
  [blockScroll]="false"
>
  <app-add-edit-view-log
    *ngIf="displayLogForm"
    [action]="actionLog"
    [draftBtn]="draftBtn"
    [dialogHeader]="dialogHeader"
    [dialogSubmit]="dialogSubmit"
    [userPermissions]="PERMISSIONS"
    [isViewEdit]="isViewEdit"
    [hideDraftBtn]="hideDraftBtn"
    [hideActionBtns]="hideActionBtns"
    [hideForContinuity]="hideForContinuity"
    [requiredFieldsLog]="requiredFieldsLog"
    [currentLog]="currentLog"
    (onCancel)="closeLogForm()"
    (onSuccess)="logSuccessOrFail($event)"
  ></app-add-edit-view-log>
</p-dialog>
<!-- #endregion -->

<!-- #region Add / View Weld Log Form -->
<p-dialog
  header="Bulk Upload Weld Logs"
  [style]="{ width: '94%', height: '96%' }"
  [(visible)]="displayBulkUploadForm"
  [modal]="true"
  [closeOnEscape]="true"
  [draggable]="false"
  [resizable]="false"
  [blockScroll]="false"
  (onHide)="clearBulkUploadData()"
>
  <app-bulk-upload [requiredFieldsLog]="requiredFieldsLog" (onCancel)="openCloseBulkUploadDialog()" (onSuccess)="multipleLogsSuccessOrFail($event, 'add')"> </app-bulk-upload>
</p-dialog>

<!-- #endregion -->

<!-- #region Clone Log dialog-->
<p-dialog header="Clone Weld Log" [(visible)]="displayCloneDialog" [modal]="true" [closeOnEscape]="true" [draggable]="false" [resizable]="true" [style]="{ 'min-height': '0px', width: '18%' }">
  <div class="row">
    <label for="amountToClone">Number of Clones<span style="color: red">*</span></label>
    <div class="col-12">
      <input
        class="formInput"
        placeholder="Enter a number 1-20"
        pInputText
        (keypress)="keyValidation.numeric($event)"
        [(ngModel)]="amountToClone"
        name="amountToClone"
        maxlength="2"
        oninput="if (this.value.length > this.maxlength ) this.value = this.value.slice(0, this.maxlength)"
      />
    </div>
  </div>

  <div class="row">
    <div class="col-12 alignRight">
      <p-button (onClick)="cloneWeldLog()"><em class="pi pi-clone mr-2"></em>Clone</p-button>
      <p-button class="ml-2 red-btn" (onClick)="closeCloneDialog()" id="cancelButton"><em class="pi pi-times-circle mr-2"></em>Cancel</p-button>
    </div>
  </div>
</p-dialog>
<!-- #endregion -->

<!-- #region Create Repair Log dialog-->
<p-dialog header="Clone Weld Log" [(visible)]="displayRepairDialog" [modal]="true" [closeOnEscape]="true" [draggable]="false" [resizable]="true" [style]="{ 'min-height': '0px', width: '19%' }">
  <div class="row">
    <label>New Weld Modifier<span style="color: red">*</span></label>
    <div class="col-12">
      <input
        class="formInput"
        placeholder="Enter new Weld Modifier"
        pInputText
        (keypress)="keyValidation.alphaUpperCaseNoSpace($event)"
        [(ngModel)]="this.newWeldModifierText"
        (ngModelChange)="this.newWeldModifierText = $event.toUpperCase()"
      />
    </div>
  </div>

  <div class="row">
    <div class="col-12 alignRight">
      <p-button (onClick)="repairWeldLog()"><em class="pi pi-cog mr-2"></em>Create Repair</p-button>
      <p-button class="ml-2 red-btn" (onClick)="closeRepairWeldLogDialog()" id="cancelButton"><em class="pi pi-times-circle mr-2"></em>Cancel</p-button>
    </div>
  </div>
</p-dialog>
<!-- #endregion -->

<!-- #region Table -->
<p-contextMenu #cm [model]="contextMenuItems" [style]="{ 'white-space': 'nowrap', width: 'auto', 'min-width': '10em' }"></p-contextMenu>
<p-table
  #weldLogTable
  [value]="weldLogData"
  [scrollable]="true"
  [paginator]="true"
  paginatorPosition="both"
  paginatorDropdownAppendTo="body"
  [rows]="10"
  scrollDirection="both"
  [rowsPerPageOptions]="[10, 25, 50, 100]"
  selectionMode="multiple"
  [(selection)]="this.selectedLogs"
  (onContextMenuSelect)="setContextMenu($event)"
  [contextMenu]="cm"
  [metaKeySelection]="true"
  dataKey="weldLogId"
>
  <!-- #region Headers -->
  <ng-template pTemplate="header" let-columns>
    <tr>
      <!-- #region Default Headers -->
      <ng-container *ngFor="let header of defaultHeaders; index as i">
        <th id="head{{ header.width }}" class="header" [ngStyle]="{ width: header.width }" [pSortableColumn]="header.field">
          {{ header.header }}
          <p-sortIcon [field]="header.field"></p-sortIcon>
        </th>
      </ng-container>
      <!-- #endregion -->

      <!-- #region Optional Headers -->
      <ng-container *ngFor="let header of optionalHeaders; index as i">
        <th id="head{{ header.width }}" class="header" [ngStyle]="{ width: header.width }" [pSortableColumn]="header.field">
          {{ header.header }}
          <p-sortIcon [field]="header.field"></p-sortIcon>
        </th>
      </ng-container>
      <!-- #endregion -->
    </tr>
  </ng-template>
  <!-- #endregion -->

  <!-- #region Content Data -->
  <!--Fill data if column is enabled-->
  <ng-template id="tableRows" pTemplate="body" let-log let-columns="headers" let-rowIndex="rowIndex">
    <tr [pSelectableRow]="log" [pSelectableRowIndex]="rowIndex" [pContextMenuRow]="log">
      <!-- #region Table Data -->
      <ng-container *ngFor="let header of defaultHeaders">
        <td *ngIf="header.field != 'weldNo'" [ngStyle]="{ width: header.width }" [ngClass]="{ purple: verifyQADates(log) }">
          {{ log[header.field] }}
        </td>
        <td [ngStyle]="{ width: header.width }" *ngIf="header.field == 'weldNo' && log.weldModifier !== null" [ngClass]="{ purple: verifyQADates(log) }">
          {{ log[header.field] }}-{{ log.weldModifier }}
        </td>
        <td [ngStyle]="{ width: header.width }" *ngIf="header.field == 'weldNo' && log.weldModifier == null" [ngClass]="{ purple: verifyQADates(log) }">
          {{ log[header.field] }}
        </td>
      </ng-container>

      <ng-container *ngFor="let header of optionalHeaders">
        <td
          *ngIf="header.field != 'entryStatus' && header.field != 'acceptReject' && header.field != 'rtPercentage'"
          [ngStyle]="{ width: header.width }"
          [pTooltip]="log[header.qaTypeDate]"
          tooltipPosition="top"
        >
          {{ log[header.field] }}
        </td>
        <td [ngStyle]="{ width: header.width }" class="red" *ngIf="header.field == 'entryStatus' && log[header.field] === 'Draft'">
          {{ log[header.field] }}
        </td>
        <td [ngStyle]="{ width: header.width }" *ngIf="header.field == 'entryStatus' && log[header.field] !== 'Draft'">
          {{ log[header.field] }}
        </td>
        <td [ngStyle]="{ width: header.width }" class="orange" *ngIf="header.field == 'acceptReject' && log[header.field] === 'Rejected' && log.QAType_REP == undefined">
          {{ log[header.field] }}
        </td>
        <td [ngStyle]="{ width: header.width }" *ngIf="(header.field == 'acceptReject' && log[header.field] !== 'Rejected') || (log[header.field] === 'Rejected' && log.QAType_REP != undefined)">
          {{ log[header.field] }}
        </td>
        <td [ngStyle]="{ width: header.width }" *ngIf="header.field == 'rtPercentage' && log[header.field] != null">{{ log[header.field] }}%</td>
        <td [ngStyle]="{ width: header.width }" *ngIf="header.field == 'rtPercentage' && log[header.field] == null">
          {{ log[header.field] }}
        </td>
      </ng-container>
      <!-- #endregion -->
    </tr>
  </ng-template>
  <!-- #endregion -->
</p-table>
<!-- #endregion -->

<!-- #region Delete Log dialog-->
<p-dialog
  header="{{ deleteDialogHeader }}"
  [(visible)]="displayDeleteDialog"
  [modal]="true"
  [closeOnEscape]="true"
  [draggable]="false"
  [resizable]="true"
  [style]="{ 'min-height': '0px', width: '20%' }"
>
  <div class="row">
    <div class="col-12">
      <label for="deleteReason">Reason for deletion</label>
      <textarea name="deleteReason" id="deleteReason" type="text" pInputText maxlength="500" rows="2" [(ngModel)]="reasonForDeletion" class="formInput form-control"> </textarea>
    </div>
  </div>

  <div class="row">
    <div class="col-12 alignRight">
      <p-button class="mr-2 red-btn" (onClick)="deleteLog()" *ngIf="selectedLogs == undefined || selectedLogs.length <= 1"><em class="pi pi-trash mr-2"></em>Delete</p-button>
      <p-button class="mr-2 red-btn" (onClick)="deleteMultipleLogs()" *ngIf="selectedLogs != undefined && selectedLogs.length > 1"><em class="pi pi-trash mr-2"></em>Delete</p-button>
      <p-button (onClick)="closeDeleteDialog()" id="cancelButton"><em class="pi pi-times-circle mr-2"></em>Cancel</p-button>
    </div>
  </div>
</p-dialog>
<!-- #endregion -->

<!-- #region Add QA Type dialog-->
<div *ngIf="displayEditQATypeDialog">
  <p-dialog
    header="{{ qaTypeDialogHeader }}"
    [(visible)]="displayEditQATypeDialog"
    [modal]="true"
    [closeOnEscape]="true"
    [draggable]="false"
    [resizable]="true"
    [style]="{ 'min-height': '0px', width: '40%' }"
  >
    <app-edit-multiple-qa-types [action]="0"></app-edit-multiple-qa-types>
    <div class="row">
      <div class="col-12 alignRight">
        <p-button (onClick)="editMultipleQATypesAndDates()" *ngIf="selectedLogs != undefined && selectedLogs.length > 1"> <em class="pi pi-cog mr-2"></em>Add QA Type</p-button>

        <p-button class="ml-2 red-btn" (onClick)="closeQATypeDialog()" id="cancelButton"><em class="pi pi-times-circle mr-2"></em>Cancel</p-button>
      </div>
    </div>
  </p-dialog>
</div>
<!-- #endregion -->

<div *ngIf="displayEditQADateDialog">
  <p-dialog
    header="{{ qaDateDialogHeader }}"
    [(visible)]="displayEditQADateDialog"
    [modal]="true"
    [closeOnEscape]="true"
    [draggable]="false"
    [resizable]="true"
    [style]="{ 'min-height': '0px', width: '45%' }"
  >
    <app-edit-multiple-qa-types [action]="0" [showCalendar]="1"></app-edit-multiple-qa-types>

    <div class="row">
      <div class="col-12 alignRight">
        <p-button (onClick)="editMultipleQATypesAndDates()" *ngIf="selectedLogs != undefined && selectedLogs.length > 1"> <em class="pi pi-cog mr-2"></em>Add QA Dates</p-button>
        <p-button class="ml-2 red-btn" (onClick)="closeQADateDialog()" id="cancelButton"><em class="pi pi-times-circle mr-2"></em>Cancel</p-button>
      </div>
    </div>
  </p-dialog>
</div>
<!-- #endregion -->

<!-- #Region Add Stamp Processs -->
<div *ngIf="displayEditProcessDialog">
  <p-dialog
    header="{{ processDialogHeader }}"
    [(visible)]="displayEditProcessDialog"
    [modal]="true"
    [closeOnEscape]="true"
    [draggable]="false"
    [resizable]="true"
    [style]="{ 'min-height': '0px', width: '45%' }"
  >
    <app-edit-multiple-processes [action]="0" [showAcceptReject]="0"></app-edit-multiple-processes>

    <div class="row">
      <div class="col-12 alignRight">
        <p-button (onClick)="editMultipleProcesses()" *ngIf="selectedLogs != undefined && selectedLogs.length > 1"> <em class="pi pi-cog mr-2"></em>Add Welder</p-button>
        <p-button class="ml-2 red-btn" (onClick)="closeProcessDialog()" id="cancelButton"><em class="pi pi-times-circle mr-2"></em>Cancel</p-button>
      </div>
    </div>
  </p-dialog>
</div>

<div *ngIf="displayEditAcceptRejectDialog">
  <p-dialog
    header="{{ acceptRejectDialogHeader }}"
    [(visible)]="displayEditAcceptRejectDialog"
    [modal]="true"
    [closeOnEscape]="true"
    [draggable]="false"
    [resizable]="true"
    [style]="{ 'min-height': '0px', width: '45%' }"
  >
    <app-edit-multiple-processes [action]="0" [showAcceptReject]="1"></app-edit-multiple-processes>

    <div class="row">
      <div class="col-12 alignRight">
        <p-button (onClick)="editMultipleProcesses()" *ngIf="selectedLogs != undefined && selectedLogs.length > 1"> <em class="pi pi-cog mr-2"></em>Add Accept/Reject</p-button>
        <p-button class="ml-2 red-btn" (onClick)="closeAcceptRejectDialog()" id="cancelButton"><em class="pi pi-times-circle mr-2"></em>Cancel</p-button>
      </div>
    </div>
  </p-dialog>
</div>
<!-- #endregion -->

<!-- #region 'Edit Multiple Logs dialog' -->
<p-dialog
  header="Edit Multiple Logs"
  [style]="{ width: '35%', height: 'auto' }"
  [(visible)]="displayEditMultipleLogsForm"
  [modal]="true"
  [closeOnEscape]="true"
  [draggable]="false"
  [resizable]="false"
  [blockScroll]="false"
>
  <app-edit-multiple-logs
    *ngIf="displayEditMultipleLogsForm"
    [userPermissions]="PERMISSIONS"
    [hideActionBtns]="hideActionBtns"
    [selectedLogs]="selectedLogs"
    [requiredFieldsLog]="requiredFieldsLog"
    (onCancel)="closeEditMultipleLogsForm()"
    (onSuccess)="multipleLogsSuccessOrFail($event, 'update')"
  ></app-edit-multiple-logs>
</p-dialog>
<!-- #endregion 'Edit Multiple Logs dialog' -->

<p-dialog
  header="Send NDEs to QMDB"
  [style]="{ width: '35%', minHeight: '20%' }"
  [(visible)]="displaySendNDEForm"
  [modal]="true"
  [closeOnEscape]="true"
  [draggable]="false"
  [resizable]="false"
  [blockScroll]="false"
>
  <app-create-nde *ngIf="displaySendNDEForm" [userPermissions]="PERMISSIONS" [hideActionBtns]="hideActionBtns" [selectedLogs]="selectedLogs" (onCancel)="closeSendNDEForm()"></app-create-nde>
</p-dialog>

<!-- #region 'Custom Confirm Notification' -->
<p-toast [style]="{ marginTop: '40px !important' }" key="confirm" [baseZIndex]="6000" position="center">
  <ng-template let-message pTemplate="message">
    <div class="flex flex-column" style="flex: 1">
      <div class="text-center">
        <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
        <h4>{{ message.summary }}</h4>
        <p>{{ message.detail }}</p>
      </div>
      <div class="grid p-fluid">
        <div class="col-6">
          <button type="button" pButton (click)="onConfirm()" label="Yes" class="p-button-success"></button>
        </div>
        <div class="col-6">
          <button type="button" pButton (click)="onReject()" label="No" class="p-button-secondary"></button>
        </div>
      </div>
    </div>
  </ng-template>
</p-toast>
<!-- #endregion 'Custom Confirm Notification' -->
