import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IAadEmployee } from '../models/IAadEmployee';
import { BaseApiService } from '../shared/base-api.service';

@Injectable({
  providedIn: 'root'
})
export class AadEmployeeApiService extends BaseApiService<any> {
  constructor(protected _http: HttpClient) {
    super(_http, 'aademployee');
  }

  public getParticipants(freeText: string): Observable<IAadEmployee[]> {
    const options = { freeText };
    return this.getById(options, '/GetParticipantListAsync');
  }
}
