import { QualificationLog } from '../../core/models';

export class QualificationLogTable extends QualificationLog {
  constructor(log: QualificationLog) {
    super();
    this.original = log;

    this.weldLogId = log.weldLogId;
    this.refineryId = log.refineryId;
    this.wpsid = log.wpsid;
    this.wpsName = log.wpsName;
    this.dateWelded = log.dateWelded ? this.getSplitData(log.dateWelded) : null;
    this.materialFormId = log.materialFormId;
    this.materialFormName = log.materialFormName;
    this.materialFromId = log.materialFromId;
    this.materialFrom = log.materialFrom;
    this.materialToId = log.materialToId;
    this.materialTo = log.materialTo;
    this.comments = log.comments && log.comments.length > 0 ? log.comments : '';
    this.logEntryStatus = log.logEntryStatus;
    this.weldType = log.weldType;
    this.materialFromTo = `${this.materialFrom} to ${this.materialTo}`;
    this.createdBy = log.createdBy === 'dbo' ? 'System' : this.getUserName(log.createdBy);
    this.updatedBy = log.updatedBy === 'dbo' ? 'System' : this.getUserName(log.updatedBy);
    this.processDateTime(log);

    if (log.weldLogStampProcesses && log.weldLogStampProcesses.length > 0) {
      this.weldLogStampProcesses = log.weldLogStampProcesses;
    } else {
      this.weldLogStampProcesses = [];
    }

    if (log.weldLogFillerMaterials && log.weldLogFillerMaterials.length > 0) {
      this.weldLogFillerMaterials = log.weldLogFillerMaterials;
    } else {
      this.weldLogFillerMaterials = [];
    }

    if (log.weldLogFiles && log.weldLogFiles.length > 0) {
      this.weldLogFiles = log.weldLogFiles;
    } else {
      this.weldLogFiles = [];
    }

    this.weldLogFiles = log.weldLogFiles;

    this.prepareTableStrings();
  }

  private getSplitData(value: string): string {
    return value.split('T')[0];
  }

  private processDateTime(log: QualificationLog) {
    this.createdDateTime = log.createdDateTime == null ? '' : log.createdDateTime.replace('T', ' ');
    this.updatedDateTime = log.updatedDateTime == null ? '' : log.updatedDateTime.replace('T', ' ');
  }

  private prepareTableStrings() {
    //fill out our strings for the table

    let processes: string[] = [];
    let fillerMat: string[] = [];
    let metalThickness: string[] = [];
    let qualifiedThickness: string[] = [];

    // pull out unique processes and fillerMaterials so we don't have duplicates
    this.weldLogStampProcesses.forEach(el => {
      if (!processes.includes(el.weldProcessName)) {
        processes.push(el.weldProcessName);
      }
    });

    this.weldLogFillerMaterials.forEach(el => {
      if (!fillerMat.includes(el.fillerMaterialName)) {
        fillerMat.push(el.fillerMaterialName);
      }
      if (!metalThickness.includes(el.weldMetalThickness)) {
        metalThickness.push(el.weldMetalThickness);
      }

      if (!qualifiedThickness.includes(el.qualifiedThickness)) {
        qualifiedThickness.push(el.qualifiedThickness);
      }
    });

    // finally populate strings
    processes.forEach(el => {
      this.processString = `${this.processString}${el}, `;
    });
    fillerMat.forEach(el => {
      this.fillerMatString = `${this.fillerMatString}${el}, `;
    });
    metalThickness.forEach(el => {
      this.weldMetalThicknessString = `${this.weldMetalThicknessString}${el}, `;
    });

    qualifiedThickness.forEach(el => {
      this.qualifiedThicknessString = `${this.qualifiedThicknessString}${el}, `;
    });

    // slice off last couple characters
    this.fillerMatString = this.fillerMatString.slice(0, -2);
    this.processString = this.processString.slice(0, -2);
    this.weldMetalThicknessString = this.weldMetalThicknessString.slice(0, -2);
    this.qualifiedThicknessString = this.qualifiedThicknessString.slice(0, -2);
  }

  public fillerMatString = '';
  public processString = '';
  public weldMetalThicknessString = '';
  public qualifiedThicknessString = '';
  public materialFromTo = '';
  public weldMetalThickness = '';
  public welder = '';

  private getUserName(userId: string): string {
    const DTO: any[] = JSON.parse(localStorage.getItem('AllUsers'));
    const user = DTO.find(u => u.userId === userId);
    return user ? user.firstName + ' ' + user.lastName : '';
  }
}
