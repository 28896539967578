import { Component } from '@angular/core';
import { LoadingIndicatorService } from 'src/app/data/services/loading-indicator.service';

@Component({
  selector: 'app-loading-indicator',
  templateUrl: './loading-indicator.component.html',
  styleUrls: ['./loading-indicator.component.scss']
})
export class LoadingIndicatorComponent {
  //#region 'Angular Life Cycle'
  constructor(public readonly _srvLoading: LoadingIndicatorService) {}
  //#endregion 'Angular Life Cycle'
}
